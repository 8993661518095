:root {
    --ark-small-mobile-figma-below-599: 599px;
    --ark-small-mobile-figma-600: 600px;
    --ark-medium-mobile-figma-below-719: 719px;
    --ark-small-desktop-figma-below-1023: 1023px;
    --ark-small-desktop-figma-1024: 1024px;
    --ark-medium-desktop-figma-below-1359: 1359px;
    --ark-medium-desktop-figma-1360: 1360px;
    --ark-large-desktop-figma-1440: 1440px;
}

.mainStepHeading {
    color: var(--ark-primary-black-figma);
    font-size: var(--ark-30-font-size);
    line-height: 36px;
    margin-bottom: 36px;
    display: flex;
    align-items: center;
}

.mainStepHeading_number {
    font-weight: bold;
}

.padlockIcon {
    max-height: 34px;
}

@media (max-width: 1023px) {
    .mainStepHeading {
        font-size: var(--ark-20-font-size);
        line-height: 24px;
        margin-bottom: 24px;
    }

    .padlockIcon {
        max-height: 20px;
    }
}

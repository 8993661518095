:root {
    --xs-bp: 0;
    --sm-bp: 576px;
    --md-bp: 768px;
    --lg-bp: 1025px;
    --xl-bp: 1200px;
    --xxl-bp: 1370px;

    --xs-max-bp: 575px;
    --sm-max-bp: 767px;
    --md-max-bp: 1024px;
    --lg-max-bp: 1199px;
    --xl-max-bp: 1369px;
    --xxl-max-bp: 1500px;
}
.containerMain {
    width: 100%;
}
.topRow {
    background: #ffffff;
    padding: 16px 0;
    box-shadow: 0 6px 10px -4px rgba(78, 85, 94, 0.1);
    border-top: 1px solid #eeeeee;
}
.breadcrumbsBlock {
    display: flex;
    justify-content: flex-end;
}
.displayAdTop {
    display: flex;
    margin-bottom: var(--gutter);
    width: 100%;
}
.displayAdTop.isPwaShown {
    margin-left: calc(var(--gutter)*6);
}
.contentRow {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    max-width: var(--content-row-max-width);
    margin: 0 auto;
}
.contentRow.adsDisabled {
    display: block; /* this is needed to center content, because IE doesn't support justify-content: center; */
}
.contentRow.isDesktop {
}
/* For 1366x768 and 1280x720 */
@media (min-width: 1200px) and (max-height: 800px) {
    .contentRow {
        max-width: 96%;
    }
}
@media (min-width: 1200px) and (max-width: 1369px) {
    .content.adsDisabled {
        padding-top: 0;
    }
}
@media (max-width: 1024px) {
    .displayAdTop {
        justify-content: center;
    }
    .displayAdTop.isPwaShown {
        margin-left: 0;
    }
    .breadcrumbsBlock {
        order: 1;
        justify-content: flex-start;
    }

    .titleBlock {
        order: 2;
        text-align: center;
    }
}
.gameTitle {
    font-size: 24px;
    font-weight: 600;
    color: #28292a;
    margin: 3px 0 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.contentContainer {
    padding-top: 1.5em;
    display: flex;
    justify-content: center;
    isolation: isolate;
}
.contentContainerWeSell {
    padding-top: 0.5em;
    display: flex;
    justify-content: center;
    isolation: isolate;
}
.contentColWrapper {
    padding-left: var(--global-side-gutters);
    padding-right: var(--global-side-gutters);
}
.customRow {
    flex-wrap: nowrap;
    justify-content: center;
}
@media (max-width: 1025px) {
    .customRow {
        margin: 0;
        flex-wrap: wrap;
    }
    .content {
        width: 100%;
    }
}
@media (min-width: 1025px) {
    .topRowWrap.adsDisabled {
        max-width: calc(100% - var(--ad-column-size) - 80px - var(--gutter));
        margin: 0 auto;
    }
    .content {
        max-width: calc(100% - var(--ad-column-size));
        flex: 0 0 auto;
        width: 100%;
    }

    .content.isPwaShown {
        padding-left: calc(80px + var(--gutter));
        position: relative;
    }
    .content.adsDisabled {
        margin: 0 auto;
    }
    .content.hideTopADArea {
        padding-top: 0;
    }
    .displayAdTop {
        margin-left: 8px;
    }
}
@media screen and (min-width: 1500px) {
    .content {
        /* width for default gameContainer + col paddings*/
        min-width: 54.75em;
    }
    .content.isFlexible {
        min-width: 63.125em;
    }
}
/* Ads Animations */
.adEnter {
    opacity: 0;
    max-height: 0;
}
.adEnterActive {
    opacity: 1;
    max-height: calc(3em + 90px);
    transition: max-height 0.8s cubic-bezier(0.33, 1, 0.68, 1), opacity 0.5s cubic-bezier(0.33, 1, 0.68, 1) 0.3s;
}
.adExit {
    opacity: 1;
    max-height: calc(3em + 90px);
}
.adExitActive {
    opacity: 0;
    max-height: 0;
    transition: max-height 0.8s cubic-bezier(0.33, 1, 0.68, 1) 0.3s, opacity 0.5s cubic-bezier(0.33, 1, 0.68, 1);
}
/* Ads Animations */
/* Sticky Ads Animations */
.stickyAdEnter {
    opacity: 0;
    transform: translateY(100%);
}
.stickyAdEnterActive {
    opacity: 1;
    transform: translateY(0);
    transition: all 0.5s cubic-bezier(0.33, 1, 0.68, 1);
}
.stickyAdExit {
    opacity: 1;
    transform: translateY(0);
}
.stickyAdExitActive {
    opacity: 0;
    transform: translateY(100%);
    transition: all 0.5s ease-in-out;
}
/* Sticky Ads Animations */
.leftRail {
    position: absolute;
    left: 0;
    top: auto;
    max-width: var(--left-rail-width);
}
.rightRail {
    transform: translateZ(0);
}
.supportImg {
    background: var(--svg-icon-bg-color);
    width: 48px;
    height: 48px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}
.supportBtn:hover,
.supportBtn:focus {
    text-decoration: none;
}
.supportBtn:hover .supportImg {
    background: var(--svg-icon-bg-color-hover);
}
.supportTitle {
    color: black;
    text-align: center;
    margin-top: 5px;
    font-size: 16px;
    line-height: 1.2;
}
.adSidebar {
    margin-top: 18px;
}
.chatContainer,
.leaderboardContainer {
    width: 100%;
    isolation: isolate;
}
.chatShortCut {
    background: #0032d4;
    width: 48px;
    height: 48px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: auto;
    margin-bottom: 0;
    margin-left: auto;
    cursor: pointer;
}
.chatTitle {
    color: black;
    text-align: center;
    margin-top: 5px;
    font-size: 16px;
    line-height: 1.2;
    cursor: pointer;
}
.chatBtn {
    margin-top: 5px !important;
}
.chatIcon svg {
    fill: var(--svg-icon-bg-color);
}
.chatBtn:hover .chatShortCut .chatIcon svg {
    fill: var(--svg-icon-bg-color-hover);
}
.feedbackShortCut {
    background: #0032d4;
    width: 48px;
    height: 48px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: auto;
    margin-bottom: 0;
    margin-left: auto;
    cursor: pointer;
}
.feedbackTitle {
    color: black;
    text-align: center;
    margin-top: 5px;
    font-size: 16px;
    line-height: 1.2;
    cursor: pointer;
}
.feedbackBtn {
    margin-top: 5px !important;
}
.feedbackIcon svg {
    fill: #1631d4;
}
.feedbackBtn:hover .feedbackIcon svg {
    fill: #005aff;
}
.poweredByContainer {
    margin-top: 80px;
}
@media (max-width: 1200px) {
    .poweredByContainer {
        margin-top: 10px;
    }
}
@media (min-width: 1370px) {
    .poweredByContainer {
        margin-top: 100px;
    }
}
.promoContainer {
    justify-content: flex-end;
}
.promoSmallText {
    padding-left: 10px;
    font-size: 10px;
    color: #080808;
}
.promoText {
    padding-left: 10px;
}

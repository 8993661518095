:root {
    --xs-bp: 0;
    --sm-bp: 576px;
    --md-bp: 768px;
    --lg-bp: 1025px;
    --xl-bp: 1200px;
    --xxl-bp: 1370px;

    --xs-max-bp: 575px;
    --sm-max-bp: 767px;
    --md-max-bp: 1024px;
    --lg-max-bp: 1199px;
    --xl-max-bp: 1369px;
    --xxl-max-bp: 1500px;
}
.Banner {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: var(--z-index-cookie-banner);
}
.Content {
    display: flex;
    align-items: center;
    padding: 20px 15px !important;
}
.Text {
    text-align: left;
    color: #ffffff;
    font-size: 18px;
    margin-right: 16px;
    margin-bottom: 0;
}
.Text a {
    color: #ffffff;
    text-decoration: underline;
}
.Text a:hover {
    text-decoration: none;
}
.Button {
}
@media (max-width: 1024px) {
    .Text {
        font-size: 16px;
    }
}
@media (max-width: 575px) {
    .Content {
        flex-direction: column;
        padding-bottom: 20px;
    }

    .Text {
        margin-bottom: 15px;
        font-size: 12px;
    }
}

:root {
    --xs-bp: 0;
    --sm-bp: 576px;
    --md-bp: 768px;
    --lg-bp: 1025px;
    --xl-bp: 1200px;
    --xxl-bp: 1370px;

    --xs-max-bp: 575px;
    --sm-max-bp: 767px;
    --md-max-bp: 1024px;
    --lg-max-bp: 1199px;
    --xl-max-bp: 1369px;
    --xxl-max-bp: 1500px;
}
.Container {
    display: flex;
    height: 100%;
}
.CoinsBlock,
.XpBlock {
    padding: 15px 0;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.CoinsBlock {
    border-right: 1px solid #ccc;
}
.CoinsNum {
    color: #0a1874;
    font-size: 40px;
}
.CoinsText {
    font-size: 14px;
    font-weight: bold;
    text-transform: capitalize;
    margin-right: 5px;
}
.Coins {
    width: 70px;
    height: 70px;
}
.CoinsDesc {
    display: flex;
    align-items: flex-end;
}
.ProgressBar {
    max-width: 135px;
}
.ProgressTitle {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    text-transform: uppercase;
}
.ProgressNum {
    color: #0a1874;
    font-size: 30px;
    margin: 0;
    text-transform: uppercase;
}
.XpDesc {
    display: flex;
    align-items: flex-end;
    font-size: 20px;
    line-height: 1;
    font-weight: bold;
}
@media (max-width: 767px) {
    .XpDesc {
        font-size: 18px;
    }
}
.XpDescText {
    margin-right: 5px;
}

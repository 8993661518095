:root {
    --xs-bp: 0;
    --sm-bp: 576px;
    --md-bp: 768px;
    --lg-bp: 1025px;
    --xl-bp: 1200px;
    --xxl-bp: 1370px;

    --xs-max-bp: 575px;
    --sm-max-bp: 767px;
    --md-max-bp: 1024px;
    --lg-max-bp: 1199px;
    --xl-max-bp: 1369px;
    --xxl-max-bp: 1500px;
}
.container {
    max-width: 98%;
    min-height: 150px;
}
.title {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
}
.coinsIcon {
}
.infoIcon {
    color: #ffffff;
}
.titleText {
    font-size: 30px;
    margin: 0 6px;
}
.titleText span {
    color: #ffc600;
}
.descText {
    font-size: 14px;
    text-align: center;
    color: #fff;
    margin: 0;
    line-height: 1.1;
}
.descText span {
    color: #ffc600;
    font-size: 20px;
}
.error {
    color: #ffffff;
    text-align: center;
    font-size: 16px;
}
@media (max-width: 575px) {
    .titleText {
        font-size: 0.8em;
    }

    .descText {
        font-size: 12px;
        line-height: 1;
    }
}

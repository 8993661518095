.inputWrapper {
    position: relative;
    width: 100%;
}

.popupInput {
    font-size: 13px;
    width: 100%;
    border: 1px solid #8ca2ae;
    height: 45px;
    text-align: center;
    margin-top: -1px;
    padding-left: 46px;
    padding-right: 46px;
    outline: none;
}

.inputIcon {
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 16px;
    color: #8ca2ae;
}

.first {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.last {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.error .popupInput {
    border-color: var(--error-border);
}

.error .inputIcon {
    color: var(--error-border);
}

.error + div .popupInput {
    border-top-color: var(--error-border);
}

@media (max-width: 768px) {
    .onlyEagleFormInput {
        max-width: 300px;
    }
}

@media (max-width: 352px) {
    .onlyEagleFormInput {
        width: 100%;
        font-size: 14px;
    }
}

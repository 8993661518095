:root {
    --xs-bp: 0;
    --sm-bp: 576px;
    --md-bp: 768px;
    --lg-bp: 1025px;
    --xl-bp: 1200px;
    --xxl-bp: 1370px;

    --xs-max-bp: 575px;
    --sm-max-bp: 767px;
    --md-max-bp: 1024px;
    --lg-max-bp: 1199px;
    --xl-max-bp: 1369px;
    --xxl-max-bp: 1500px;
}
.promoContainer {
    width: 100%;
    position: relative;
    padding: 0 8px;
    border: var(--promoBanner-border);
    box-shadow: var(--promoBanner-shadow);
    background-color: #fff;
    border-radius: var(--home-page-br);
}
.imgBlock {
    padding: 0;
    border-radius: var(--home-page-br) 0 0 var(-br);
    overflow: hidden;
    border-top-left-radius: var(--home-page-br);
    border-bottom-left-radius: var(--home-page-br);
}
.link:hover {
    text-decoration: none;
}
.textBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 25px;
    border-top-right-radius: var(--home-page-br);
    border-bottom-right-radius: var(--home-page-br);
}
.promoTitle {
    font-size: var(--promoBanner-title-fs);
    text-decoration: none;
    color: #3b4148;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
}
.promoDesc {
    width: 100%;
    font-size: var(--promoBanner-desc-fs);
    font-weight: 700;
    color: #3b4148;
    text-decoration: none;
    margin-bottom: 20px;
    overflow: hidden;
    position: relative;
    line-height: 1.4em;
    max-height: 8.4em;
    text-align: justify;
    margin-right: -1em;
    padding-right: 1em;
    display: -webkit-box;
    -webkit-line-clamp: var(--promoBanner-desc-line-clamp);
    -webkit-box-orient: vertical;
}
.btnBlock {
    text-align: center;
}
.playBtn {
    background: var(--promoBanner-play-btn-bg-color);
    color: var(--promoBanner-play-btn-text-color);
    padding: 11px 24px 11px;
    min-width: 170px;
    margin-bottom: 2rem;
}
.promoContainer:hover .playBtn {
    background: var(--promoBanner-play-btn-hover-color);
}
.mainContentBg {
    position: relative;
}
.mainContentBg:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 300px;
    background: var(--promoBanner-bg);
    z-index: -1;
}
@media (max-width: 1199px) {
    .promoDesc {
        font-size: 14px;
        margin-bottom: 10px;
    }
}
@media (max-width: 1024px) {
    .playBtn {
        min-width: 170px;
        padding: 9px 24px 8px;
        margin: 0;
    }

    .promoDesc,
    .promoTitle {
        display: none;
    }

    .btnBlock {
        margin-top: 15px;
        margin-bottom: 15px;
    }
}
@media (max-width: 575px), (orientation: landscape) and (max-width: 1369px) {
    .imgBlock {
        border-bottom-left-radius: 0;
        border-top-right-radius: var(--home-page-br);
    }

    .textBlock.digitalTurbine {
        align-items: flex-start;
        padding: 24px 16px 12px;
    }

    .textBlock.digitalTurbine .promoTitle {
        display: block;
        color: var(--full-black);
        font-weight: 500;
        margin-bottom: 15px;
        line-height: 20px;
    }

    .textBlock.digitalTurbine .promoDesc {
        display: -webkit-box;
        -webkit-line-clamp: var(--promoBanner-desc-line-clamp-mobile);
        margin-bottom: 8px;
        font-size: 11px;
        font-weight: 500;
        line-height: 16px;
        text-align: initial;
        color: #666;
    }

    .textBlock.digitalTurbine .btnBlock {
        display: var(--play-btn-display);
    }
}

:root {
    --xs-bp: 0;
    --sm-bp: 576px;
    --md-bp: 768px;
    --lg-bp: 1025px;
    --xl-bp: 1200px;
    --xxl-bp: 1370px;

    --xs-max-bp: 575px;
    --sm-max-bp: 767px;
    --md-max-bp: 1024px;
    --lg-max-bp: 1199px;
    --xl-max-bp: 1369px;
    --xxl-max-bp: 1500px;
}

.container {
    padding: 32px 5rem;
}

.topContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--gray-startribune-color);
}

.rightContainer {
    display: flex;
    grid-column-gap: 55px;
    column-gap: 55px;
}

.logoContainer {
    display: flex;
    flex-direction: column;
}

.logoDescription {
    margin-top: 4px;
    font-family: graphik-regular, sans-serif;
    font-size: 11px;
    line-height: 1.2;
    font-weight: var(--ark-font-weight-regular);
    color: var(--gray-startribune-dark-color);
}

.logos {
    display: flex;
    grid-column-gap: 8px;
    column-gap: 8px;
    align-items: center;
}

.subscribeLink:hover, .subscribeButton:hover {
    text-decoration: none;
}

.subscribeButton {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 216px;
    min-height: 39px;
    background-color: var(--green-startribune-color);
    border-radius: 40px;
    font-family: "Graphik", "Helvetica", sans-serif;
    font-size: 14px;
    line-height: 16px;
    color: var(--inverted-color);
    font-weight: var(--ark-font-weight-regular-bolder);
}

.subscribeButton:hover {
    cursor: pointer;
    background-color: var(--green-startribune-dark-color);
}

.logo, .logo svg {
    width: 24px;
    height: 24px;
}

.footerNavigation {
    padding-top: 48px;
    display: flex;
    grid-column-gap: 24px;
    column-gap: 24px;
    overflow: hidden;
}

.footerLink {
    font-family: graphik-regular, sans-serif;
    font-size: 14px;
    line-height: 16px;
    font-weight: var(--ark-font-weight-regular);
    color: var(--black-startribune-color);
    white-space: nowrap;
}

.footerLink:hover {
    text-decoration: none;
}

@media screen and (max-width: 1370px) {
   .footerNavigation {
       flex-wrap: wrap;
       grid-row-gap: 15px;
       row-gap: 15px;
   }
}

@media screen and (max-width: 1025px) {
    .topContainer, .rightContainer {
        border-bottom: none;
        flex-direction: column;
        grid-row-gap: 24px;
        row-gap: 24px;
        align-items: center;
        padding: 0;
    }

    .logoContainer {
        align-items: center;
    }
}

@media screen and (max-width: 768px) {
    .container {
        padding: 48px 60px 52px;
    }

    .topContainer, .rightContainer {
        border-bottom: none;
        flex-direction: column;
        grid-row-gap: 24px;
        row-gap: 24px;
        align-items: center;
        padding: 0;
    }

    .logoContainer {
        align-items: center;
    }

    .logoDescription {
        font-size: 11px;
    }

    .subscribeButton {
        background: none;
        min-width: unset;
        min-height: unset;
        font-size: 14px;
        line-height: 16px;
        color: var(--black-startribune-color);
        display: flex;
        align-items: center;
    }

    .subscribeButton::after {
        content: '';
        width: 8px;
        height: 8px;
        transform: rotate(45deg);
        border-right: 2px solid var(--black-startribune-color);
        border-top: 2px solid var(--black-startribune-color);
        margin-left: 5px;
    }

    .footerNavigation {
        display: none;
    }
}

:root {
    --xs-bp: 0;
    --sm-bp: 576px;
    --md-bp: 768px;
    --lg-bp: 1025px;
    --xl-bp: 1200px;
    --xxl-bp: 1370px;

    --xs-max-bp: 575px;
    --sm-max-bp: 767px;
    --md-max-bp: 1024px;
    --lg-max-bp: 1199px;
    --xl-max-bp: 1369px;
    --xxl-max-bp: 1500px;
}
.Content {
    padding-top: 3em;
    margin-bottom: 30px;
    padding-left: var(--global-side-gutters);
    padding-right: var(--global-side-gutters);
}
.TopBlock {
    margin: 0;
    background: #fff;
    box-shadow: 0 0 1px -8px rgba(0, 0, 0, 0.6), 0 2px 4px rgba(0, 0, 0, 0.4);
}
.PersonBlock {
    border-right: 1px solid #ccc;
}
.XpBlock {
    padding: 0;
}
.BottomBlock {
    margin-top: 30px;
}
.captcha {
    display: none;
}
@media (min-width: 1025px) {
    .Content {
        max-width: 960px !important;
    }
}
@media (max-width: 767px) {
    .XpBlock {
        border-top: 1px solid #ccc;
    }

    .PersonBlock {
        border-right: none;
    }
}

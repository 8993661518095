.ChangePassBtn {
    margin-top: 10px;
    font-size: 12px;
    color: #004db3;
    box-shadow: none;
    border: 0;
    text-decoration: underline;
    text-transform: capitalize;
}

.Modal {
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: var(--z-index-overlay);
}

.ModalContent {
    display: block;
    background: #fff;
    width: 320px;
    z-index: var(--z-index-modal);
}

.Top {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #bbb;
    padding: 10px 15px;
    margin-bottom: 15px;
    position: relative;
    text-transform: capitalize;
}

.CloseBtn {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translate(0, -50%);
}

.LockIcon {
    color: #004db3;
}

.TopLockIcon {
    margin-right: 10px;
    width: 24px;
    height: 24px;
}

.ModalBody {
    padding: 0 15px;
}

.InputContainer {
    text-align: left;
    margin-bottom: 15px;
}

.InputLabel {
    margin-bottom: 8px;
    font-size: 14px;
}

.InputRow {
    position: relative;
}

.Input {
    width: 100%;
    border-radius: 2px;
    border: 1px solid #7c8693;
    height: 38px;
    padding: 0 30px 0 10px;
}

.ErrorInput {
    border-color: #f00;
}

.VisibleBtn {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
}

.VisibleBtn:focus {
    outline: none;
}

.EyeCrossed,
.Eye {
    width: 20px;
    height: 20px;
}

.Bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px 15px;
}

.CancelBtn {
    text-decoration: underline;
    font-size: 16px;
    color: #004db3;
}

.Error {
    color: #f00;
    font-size: 12px;
}

/* Define a base for modals */

.modalBase {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center; /* Center the content horizontally */
    align-items: center; /* Center the content vertically */
    z-index: 1000; /* Ensure the modal appears above other elements */
    background-color: rgba(0, 0, 0, 0.7); /* Dim the background */
}

.show {
    display: flex; /* Displayed as a flex container */
}

.hide {
    display: none;
}

#recaptchaContainer {
    /* Default styles, assuming it's hidden */
    display: none;
    width: 100%; /* Take up full width of its container */
    max-width: 400px; /* Maximum width */
    padding: 20px; /* Some spacing inside */
    background-color: #fff; /* White background for contrast */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3); /* Some shadow for depth */
    border-radius: 10px; /* Rounded corners */
}

#recaptchaContainer.show {
    /* Override when it's shown */
    display: block;
}

/* @import "../../../../variables.css"; */

.Base {
    border: none;
    color: inherit;
    text-decoration: none;
    display: inline-block;
    background-color: inherit;
    overflow: hidden;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.noPadding {
    padding: 0;
}

.fullWidth {
    width: 100%;
}

button {
    cursor: pointer;
    outline: none;
}

a,
label {
    cursor: pointer;
}

/* POPUP */

.popupHeader {
    border-bottom: 1px solid #8ca2af;
    width: 100%;
    margin-top: 30px;
}

.popupHeader.fromShopModal {
    max-width: 560px;
    background-color: #f5f5f5;
    border: 0;
    margin: 42px auto 0;
}

.m0 {
    margin: 0 !important;
}

.mt14 {
    margin-top: 14px !important;
}

.mb10 {
    margin-bottom: 10px !important;
}

.popupDescriptionFromShopModal {
    font-size: 18px !important;
    padding: 0 !important;
}

.popupTitleFromShopModal {
    color: var(--new-header-color-black) !important;
    font-size: 26px !important;
}

.defaultContentWrapper {
    padding-top: 0;
    background: #f5f5f5;
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
}

.defaultContentWrapper.fromShopModal {
    max-width: 540px;
    margin: 0 auto;
}

/* ================== */

/* INPUTS AND BUTTONS */

/* ================== */

.loginBtnRow {
    margin-top: 24px;
    display: flex;
    justify-content: center;
}

.termConditions {
    margin-top: 14px;
    display: flex;
    justify-content: center;
    cursor: pointer !important;
    text-decoration: none !important;
    color: #000;
}

/*  changing layout order for eagle (1 and 2 are email and password inputs, so we start to manipulate from 3rd row)*/

.loginBtnRow {
    order: 3;
}

.loginBtnRow.eagle {
    order: 4;
    margin-top: 0;
}

/* ===== */

/* ERROR */

/* ===== */

.popupErrorsList {
    text-align: center;
    color: var(--error-border);
    font-size: 14px;
    margin-bottom: 5px;
    margin-top: 5px;
}

.popupErrorsList a:hover {
    color: var(--eagle-blue-color-darker);
}

/* ====== */

/* TABLET */

/* ====== */

@media (max-width: 768px) {
    .defaultContentWrapper {
        padding-bottom: 0;
    }

    .defaultContentWrapper.eagle {
        padding-bottom: 0;
    }
}

@media screen and (max-width: 475px) {
    .popupHeader.fromShopModal {
        margin-top: 16px;
    }

    .popupTitleFromShopModal {
        font-size: 20px !important;
        padding: 0 16px;
    }

    .popupDescriptionFromShopModal {
        font-size: 16px !important;
        padding: 0 16px;
        margin-bottom: 0 !important;
    }
}

@media screen and (max-width: 475px) {
    .popupHeader.fromShopModal {
        margin-top: 16px;
    }

    .popupTitleFromShopModal {
        font-size: 20px !important;
        padding: 0 16px;
    }

    .popupDescriptionFromShopModal {
        font-size: 16px !important;
        padding: 0 16px;
        margin-bottom: 0 !important;
    }
}

.viewMore {
    font-size: var(--view-more-fs);
    color: var(--view-more-color);
    font-weight: 600;
    white-space: nowrap;
    -webkit-text-decoration: var(--view-more-text-decoration);
            text-decoration: var(--view-more-text-decoration);
    margin-bottom: var(--view-more-mb);
}

.viewMoreIcon {
    font-size: 20px;
    color: #1e36ae;
    font-weight: 600;
    transform: matrix(0, 1, -1, 0, 0, 0);
}

:root {
    --xs-bp: 0;
    --sm-bp: 576px;
    --md-bp: 768px;
    --lg-bp: 1025px;
    --xl-bp: 1200px;
    --xxl-bp: 1370px;

    --xs-max-bp: 575px;
    --sm-max-bp: 767px;
    --md-max-bp: 1024px;
    --lg-max-bp: 1199px;
    --xl-max-bp: 1369px;
    --xxl-max-bp: 1500px;
}
/* Fix for adaptive image */
:global(.help-page img) {
    max-width: 100%;
    height: auto;
}
.wrapper {
    width: 100%;
}
.container {
    padding-top: 3em;
}
.heading {
    height: 240px;
    background: url('https://arenacloud.cdn.arkadiumhosted.com/arena51-assets/canary/images/help_heading_bg.jpg') no-repeat center center;
    background-size: contain;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.heading:after {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.75);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}
@media (max-width: 767px) {
    .heading {
        height: 120px;
        background-size: cover;
    }
}
.headingText {
    font-size: 36px;
    color: #fff;
    z-index: 1;
    text-align: center;
}
@media (max-width: 767px) {
    .headingText {
        font-size: 24px;
    }
}
.backToGame {
    display: inline-block;
}
.backToGameLink {
    display: flex;
    color: #0b4db4;
    font-weight: 700;
    padding: 0.5em 0;
    margin: 0 0 1em 0;
}
.backToGameLink:hover,
.backToGameLink:focus {
    color: #0b4db4;
    text-decoration: none;
}
.backToGameText {
    color: black;
    padding-left: 0.5em;
}
.backToGameText:hover,
.backToGameText:focus {
    color: inherit;
}
.iframedSection {
    width: 100%;
    height: calc(90vh + 296px);
    border: none;
    overflow-x: hidden;
    overflow-y: auto;
    margin-top: -198px;
    margin-bottom: -92px;
}
.divWrapper.__iframe {
    max-height: 100%;
    overflow: hidden;
}
.iframedSection.__device.__mobile {
    margin-bottom: -98px;
}
/*todo move all common styles to the project from help htmls
   and leave only custom styles in those files*/
:global(.help-page p),
:global(.question li) {
    font-size: 16px !important;
}
:global(.help-page h3) {
    font-size: 18px !important;
}

:root {
    --xs-bp: 0;
    --sm-bp: 576px;
    --md-bp: 768px;
    --lg-bp: 1025px;
    --xl-bp: 1200px;
    --xxl-bp: 1370px;

    --xs-max-bp: 575px;
    --sm-max-bp: 767px;
    --md-max-bp: 1024px;
    --lg-max-bp: 1199px;
    --xl-max-bp: 1369px;
    --xxl-max-bp: 1500px;
}
@keyframes LoadingAnimation {
    50% {
        transform: scale(0.75, 0.75) translateZ(0);
        color: rgba(0, 0, 0, 0.05);
    }
}
.loaderContainer {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    background: #f1f1f1;
    color: rgba(0, 0, 0, 0.15);
}
.loaderContainer.fullScreen {
    position: fixed;
}
.loaderText {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translateX(-50%) translateY(-50%);
    font-size: 5em;
    text-align: center;
}
.loadingLetter {
    display: inline-block;
    animation: LoadingAnimation 4s infinite;
    will-change: transform;
}
.loadingLetter:nth-of-type(1),
.loadingLetter:nth-of-type(10) {
    animation-delay: 0.3s;
}
.loadingLetter:nth-of-type(2),
.loadingLetter:nth-of-type(9) {
    animation-delay: 0.225s;
}
.loadingLetter:nth-of-type(3),
.loadingLetter:nth-of-type(8) {
    animation-delay: 0.15s;
}
.loadingLetter:nth-of-type(4),
.loadingLetter:nth-of-type(7) {
    animation-delay: 0.075s;
}
@media (max-width: 1024px) {
    .loaderText {
        font-size: 3.5em;
    }
}
@media (max-width: 575px) {
    .loaderText {
        font-size: 3em;
    }
}
@media (max-width: 360px) {
    .loaderText {
        font-size: 2.5em;
    }
}

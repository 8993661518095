:root {
    --ark-small-mobile-figma-below-599: 599px;
    --ark-small-mobile-figma-600: 600px;
    --ark-medium-mobile-figma-below-719: 719px;
    --ark-small-desktop-figma-below-1023: 1023px;
    --ark-small-desktop-figma-1024: 1024px;
    --ark-medium-desktop-figma-below-1359: 1359px;
    --ark-medium-desktop-figma-1360: 1360px;
    --ark-large-desktop-figma-1440: 1440px;
}

.tabHeader {
    background: var(--ark-primary-white-figma);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: flex;
}

.contentItem {
    display: none;
    margin-top: 40px;
}

.activeContentItem {
    display: block;
}

@media (max-width: 599px) {
    .tabHeader {
        margin-left: -1rem;
        margin-right: -1rem;
    }
}

.hidden {
    display: none;
}

.signInUpButton {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    padding: 0 20px;
    font-size: 18px;
    font-weight: 700;
    min-height: 47px;
    color: #fff;
}

.signInUpButton.isLoading {
    justify-content: center;
}

.signInUpButton:disabled {
    background-color: var(--gray-medium-color);
    color: var(--main-color);
    cursor: not-allowed;
}

.signInUpButton.medium {
    min-width: 300px;
}

.signInUpButton.small {
    width: 196px;
}

.signInUpButton.old {
    width: 228px;
    font-size: 19px;
    font-weight: 400;
    height: 46px;
    background-color: #18a825;
    border: 0;
    border-radius: 3px;
}

.eagleGreen {
    border-radius: 50px;
    background-color: var(--green-color);
}

.eagleRed {
    border-radius: 50px;
    background-color: #fff;
    border-color: var(--red-color);
    color: #000;
}

.buttonText {
    flex-grow: 1;
    text-align: center;
}

.nomargin {
    margin-left: 0;
}

.arkButtonIcon {
    margin-right: 10px;
}

.loaderIcon {
    width: auto;
    height: auto;
}

@media (max-width: 768px) {
    .signInUpButton {
        width: 100%;
    }
}

@media (max-width: 352px) {
    .signInUpButton {
        font-weight: 400;
    }
}

.contactUsButton {
    border: 0;
    background: none;
    color: inherit;
    padding: 0;
    font-weight: inherit;
}
.flex {
    display: flex;
    align-items: center;
}
.contactUsButton:hover,
.contactUsButton:focus {
    text-decoration: underline;
    outline: none;
}
.loaderForHelpPage {
    max-width: 24px;
    transform: scale(0.5);
}
.loaderForSidebar {
    height: 48px;
}
.loaderWithoutIcon {
    width: 48px;
    height: 30px;
}
:global(#freshworks-container #freshworks-frame-wrapper) {
    bottom: 10px !important;
    right: 10px !important;
}

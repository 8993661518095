:root {
    --xs-bp: 0;
    --sm-bp: 576px;
    --md-bp: 768px;
    --lg-bp: 1025px;
    --xl-bp: 1200px;
    --xxl-bp: 1370px;

    --xs-max-bp: 575px;
    --sm-max-bp: 767px;
    --md-max-bp: 1024px;
    --lg-max-bp: 1199px;
    --xl-max-bp: 1369px;
    --xxl-max-bp: 1500px;
}
.loginBlock {
    background: #ffffff;
    min-height: 80px;
    padding: 12px 15px 12px 0;
    display: flex;
    align-items: center;
    font-size: var(--game-end-login-block-fs);
    font-weight: var(--game-end-login-block-font-weight);
    border-radius: var(--game-end-br);
}
.loginBlock:after {
    /* fixing 'align-items: center' for IE */
    content: '';
    min-height: inherit;
    font-size: 0;
}
.personIcon {
    color: #004db3;
    border-right: 2px solid #d7d7d7;
    padding: var(--game-end-login-block-icon-padding);
    width: var(--game-end-login-block-icon-width);
    margin-right: 20px;
    flex-shrink: 0;
}
.button {
    margin: 0 5px 0 0;
    padding: 0;
    color: var(--game-end-login-block-button-color);
    text-decoration: underline;
    background-color: transparent;
    border: none;
}
@media (max-width: 575px) {
    .loginBlock {
        padding: 5px 8px 5px 0;
    }
}

:root {
    --xs-bp: 0;
    --sm-bp: 576px;
    --md-bp: 768px;
    --lg-bp: 1025px;
    --xl-bp: 1200px;
    --xxl-bp: 1370px;

    --xs-max-bp: 575px;
    --sm-max-bp: 767px;
    --md-max-bp: 1024px;
    --lg-max-bp: 1199px;
    --xl-max-bp: 1369px;
    --xxl-max-bp: 1500px;
}
.container {
    padding-top: var(--home-template-container-padding-top);
    padding-left: var(--global-side-gutters);
    padding-right: var(--global-side-gutters);
}
.content {
    margin-top: var(--home-template-content-margin-top);
}
.title {
    font-size: 32px;
    margin-bottom: 20px;
    color: #3b4148;
}
@media (max-width: 1025px) {
    .title {
        font-size: 27px;
    }
}
@media (max-width: 767px) {
    .title {
        font-size: 20px;
    }
}
.afterCategoryAd {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3em auto;
}
@media (max-width: 575px) {
    .title {
        font-size: 20px;
        text-align: center;
    }
}

.old {
    color: var(--login-description-color);
    text-decoration: underline;
    font-size: 16px;
}

.eagle {
    color: var(--eagle-blue-color);
    font-size: 14px;
    word-break: break-all;
}

.defaultCursor {
    cursor: default !important;
}

:root {
    --xs-bp: 0;
    --sm-bp: 576px;
    --md-bp: 768px;
    --lg-bp: 1025px;
    --xl-bp: 1200px;
    --xxl-bp: 1370px;

    --xs-max-bp: 575px;
    --sm-max-bp: 767px;
    --md-max-bp: 1024px;
    --lg-max-bp: 1199px;
    --xl-max-bp: 1369px;
    --xxl-max-bp: 1500px;
}
/* GameContainer */
.gameContainer {
    position: relative;
    border: 1px solid var(--border-color);
    border-radius: var(--game-container-br);
    overflow: var(--game-container-overflow);
    display: flex;
    isolation: isolate;
    z-index: var(--z-index-game);
}
.gameContainer.promotedGame {
    border-bottom: 0;
}
/* GameUnit */
.unitContainer {
    display: flex;
    align-self: center;
    margin: 0 auto;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
.unitContainer.hidden {
    position: absolute;
    left: -999em;
}
.unitContainer.fullScreen.hidden {
    position: fixed;
}
.unitContainer.fullScreen {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 100;
    background-color: #fff;
}
.gameAd {
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    background-color: white;
}
.gameContainer.isIframeGame {
    line-height: 0;
}
.gameContainer.fullScreenMobTheySell,
.gameContainer.fullScreenMob,
.gameContainer.fullScreenTab {
    display: flex;
    position: fixed;
    left: 0;
    z-index: 999;
    width: 100%;
}
.gameContainer.fullScreenMobTheySell .canvasBox iframe,
.gameContainer.fullScreenMob .canvasBox iframe,
.gameContainer.fullScreenTab .canvasBox iframe {
    height: 100%;
}
.gameContainer.fullScreenTab {
    top: var(--tablet-ad-height);
    height: calc(100% - var(--tablet-ad-height)) !important;
}
.gameContainer.fullScreenTab.displayAdsDisabled {
    top: 0;
    height: 100% !important;
}
.gameContainer.fullScreenMobTheySell {
    top: 50px;
    height: calc(100% - var(--mobile-ad-height)) !important;
    background: #fff;
}
.gameContainer.fullScreenMob {
    top: var(--mobile-ad-portrait-height);
    height: calc(100% - var(--mobile-ad-portrait-height)) !important;
    background: #fff;
}
@media (orientation: landscape) {
    .gameContainer.fullScreenMob {
        top: var(--mobile-ad-height);
        height: calc(100% - var(--mobile-ad-height)) !important;
        background: #fff;
    }
}
.gameContainer.fullScreenMobTheySell.displayAdsDisabled,
.gameContainer.fullScreenMob.displayAdsDisabled {
    top: 0;
    height: 100% !important;
}
.canvasContainer {
    overflow: unset;
    width: 100%;
    height: 100%;
}
.canvasContainer.isIframeGame {
    line-height: 0;
}
.canvasContainer.fullScreenMobTheySell,
.canvasContainer.fullScreenMob,
.canvasContainer.fullScreenTab {
    display: flex;
    position: fixed;
    left: 0;
    z-index: 999;
}
.canvasContainer.fullScreenMobTheySell .canvasBox iframe,
.canvasContainer.fullScreenMob .canvasBox iframe,
.canvasContainer.fullScreenTab .canvasBox iframe {
    height: 100%;
}
.canvasContainer.fullScreenTab {
    top: var(--tablet-ad-height);
    height: calc(100% - var(--tablet-ad-height));
}
.canvasContainer.fullScreenTab.displayAdsDisabled {
    top: 0;
    height: 100%;
}
.canvasContainer.fullScreenMobTheySell {
    top: 50px;
    height: calc(100% - var(--mobile-ad-height));
    background: #fff;
}
.canvasContainer.fullScreenMob {
    top: var(--mobile-ad-portrait-height);
    height: calc(100% - var(--mobile-ad-portrait-height));
    background: #fff;
}
@media (orientation: landscape) {
    .canvasContainer.fullScreenMob {
        top: var(--mobile-ad-height);
        height: calc(100% - var(--mobile-ad-height));
        background: #fff;
    }
}
.canvasContainer.fullScreenMobTheySell.displayAdsDisabled,
.canvasContainer.fullScreenMob.displayAdsDisabled {
    top: 0;
    height: 100%;
}
/* GameCanvas */
.canvasBox {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    background-color: #ffffff;
    border: 0;
}
.canvasBox body {
    margin: 0;
}
:global(.testing__game-end-button) {
    display: none;
}
@media screen and (min-width: 1369px) {
    .gameContainer {
        width: 100%;
    }
}

:root {
    --xs-bp: 0;
    --sm-bp: 576px;
    --md-bp: 768px;
    --lg-bp: 1025px;
    --xl-bp: 1200px;
    --xxl-bp: 1370px;

    --xs-max-bp: 575px;
    --sm-max-bp: 767px;
    --md-max-bp: 1024px;
    --lg-max-bp: 1199px;
    --xl-max-bp: 1369px;
    --xxl-max-bp: 1500px;
}
:root {
    --tile-wrapper-max-width: 100%;
}
.header {
    display: flex;
    align-items: var(--home-category-header-align-items);
    justify-content: space-between;
    margin-bottom: 0.5em;
    max-width: calc(100% - var(--ad-column-size));
}
.categoryTitle {
    font-family: var(--second-font);
    font-size: 32px;
    color: #3b4148;
    font-weight: var(--category-title-font-weight);
    margin: 0;
    text-transform: capitalize;
}
.linkAndPoweredBy {
    display: flex;
    align-items: flex-end;
}
.byArkadium {
    display: flex;
    align-items: flex-end;
    margin-bottom: 0px;
    margin-left: 8px;
}
.by {
    display: block;
    font-family: var(--default-font);
    font-size: 8.5px;
    color: #666666;
    margin-bottom: 4px;
    margin-right: 2px;
}
.tileWrapper {
    flex: 0 0;
    flex-basis: 25%;
    max-width: 25%;
}
.tileWrapper.digitalTurbine {
    max-width: var(--tile-wrapper-width-mobile);
    padding: var(--tile-wrapper-padding);
    margin: var(--tile-wrapper-margin);
}
.content {
    display: flex;
}
.tiles {
    display: flex;
    flex-wrap: wrap;
    margin-right: -8px;
    width: 100%;
    /*margin-left: -8px;*/
    /*box-shadow: 0 0 0px 1px #666;*/
    max-width: calc(100% - var(--ad-column-size));
}
.ads {
    display: flex;
}
.adsDisabled {
    min-width: 100%;
}
@media (max-width: 1024px) {
    :root {
        --tile-wrapper-max-width: 224px;
    }
    .header {
        max-width: 100%;
    }
    .content {
        display: flex;
        width: 100%;
        margin-left: 0px !important;
    }
    .tiles {
        display: flex;
        flex-wrap: nowrap;
        overflow: scroll;
        /* Scroll for touch */
        -webkit-overflow-scrolling: touch;
        /* Calc Negative margin to fill the rest of horizontal space */
        margin-right: calc(-50vw - -50%);
        margin-left: 0 !important; /* because of wapo styles*/
        padding-bottom: var(--home-category-content-padding-bottom);
        min-width: 105%;
    }
    .tileWrapper {
        flex: 0 0 auto;
        max-width: calc(224px + var(--gutter));
    }

    .tileWrapper:first-of-type {
        margin-left: 0;
        padding-left: 0 !important /* because of wapo styles*/;
        max-width: calc(100% + var(--gutter)/2);
        max-width: calc(var(--tile-wrapper-max-width) + var(--gutter)/2);
    }

    .tileWrapper.digitalTurbine:first-of-type {
        max-width: var(--tile-wrapper-width-mobile);
    }
}
@media (max-width: 767px) {
    :root {
        --tile-wrapper-max-width: 164px;
    }
    .categoryTitle {
        font-size: 20px;
    }
    .tileWrapper {
        max-width: calc(164px + var(--gutter));
    }

    .tiles {
        max-width: 100%;
    }
}
@media screen and (max-width: 575px) {
    :root {
        --tile-wrapper-max-width: 128px;
    }
    .tileWrapper {
        max-width: calc(100% + var(--gutter));
        max-width: calc(var(--tile-wrapper-max-width) + var(--gutter));
    }
}
.spacingTile {
    display: block;
    width: calc(100vw - 100% - var(--gutter)/2);
    height: 1px;
    flex: 0 0 auto;
}
@media (min-width: 1025px) {
    .spacingTile {
        display: none;
    }
}
.adWrapper {
    width: var(--ad-column-size);
    padding-left: var(--gutter);
    margin-left: auto;
    margin-bottom: 1.5em;
}
/* NEW DESIGN */
:global(#root) {
    --new-category-tile-width: 292px;
}
.__newDesign {
    margin-top: 64px;
    position: relative;
    overflow: hidden;
}
.__newDesign:first-of-type {
    margin-top: 36px;
}
.__newDesign .tiles {
    margin-top: 40px;
    margin-left: 0 !important;
    margin-right: 0 !important;
    flex-wrap: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
    position: relative;
}
.__newDesign .categoryTitle {
    font-family: 'Nunito Sans';
    font-size: 32px;
    line-height: 36px;
    font-weight: 700;
    color: #231f20;
}
.__newDesign .tiles::-webkit-scrollbar {
    display: none; /* Chrome, Safari */
}
.__newDesign .tiles {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
.__newDesign .tileWrapper {
    margin-left: 24px;
}
.__newDesign .tileWrapper:first-of-type {
    margin-left: 0;
}
.__newDesign .tileWrapper,
.__newDesign .tileWrapper a,
.__newDesign .tileWrapper [class*='GameTile__tilePictureWrapper___'],
.__newDesign .tileWrapper [class*='ProgressiveImage'] {
    width: var(--new-category-tile-width);
    min-width: var(--new-category-tile-width);
    max-width: var(--new-category-tile-width);
    height: var(--new-category-tile-width);
    min-height: var(--new-category-tile-width);
    max-height: var(--new-category-tile-width);
    padding: 0 !important;
    border-radius: var(--new-header-gametile-radius) !important;
}
.__newDesign .tileWrapper,
.__newDesign .tileWrapper a {
    height: auto;
    min-height: initial;
    max-height: initial;
}
.__newDesign [class*='MoreGamesLink__viewMore___'] {
    display: none !important;
}
.__newDesign [class*='GameTile__tileText___'] {
    display: block !important;
    font-family: 'Nunito Sans';
    font-size: 24px;
    line-height: 30px;
    font-weight: 400;
    color: #231f20;
    margin-top: 16px;
    overflow: hidden;
}
.__newDesign .header {
    margin: 0;
}
.__newDesign :global(.__arrow) {
    display: none;
    height: var(--new-category-tile-width);
    width: calc(var(--new-category-tile-width)/3*2);
    position: absolute;
    top: 76px;
    left: -8px;
    background: linear-gradient(270deg, rgba(0, 11, 31, 0.8) 26.71%, rgba(0, 66, 187, 0) 100%);
    justify-content: center;
    align-items: center;
    transform: scaleX(-1);
    cursor: pointer;
}
.__newDesign[class*='__overflown'] :global(.__arrow-right) {
    display: flex;
}
.__newDesign[class*='__overflown'][class*='__scrolled_right'] :global(.__arrow-left) {
    display: flex;
}
.__newDesign[class*='__overflown'][class*='__scrolled_fully'] :global(.__arrow-right) {
    display: none;
}
.__newDesign :global(.__arrow-right) {
    left: 100%;
    transform: translate(-100%, 0);
}
.__newDesign :global(.__arrow .arrow-img) {
    width: 43.67px;
    height: 43.67px;
    background-color: var(--new-header-color-blue-primary);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 14.31px;
    margin-left: auto;
}
.__newDesign :global(.__arrow:hover .arrow-img) {
    background-color: var(--new-header-color-blue-secondary);
}
.__newDesign :global(.__arrow .arrow-img) {
    width: 64px;
    height: 64px;
    margin-right: 20px;
}
.__newDesign :global(.__arrow .arrow-svg) {
    width: 28.44px;
}
.__newDesign .tileWrapper a,
.__newDesign .tileWrapper a [class*='GameTile__tilePictureWrapper___'],
.__newDesign .tileWrapper a [class*='ProgressiveImage'] {
    max-width: 100%;
    min-width: initial;
}
.__newDesign .tileWrapper {
    overflow: hidden;
}
.__newDesign .tileWrapper a picture {
    transition: all 0.21s ease-in;
}
.__newDesign .tileWrapper a picture:hover {
    transform: scale(1.15) translate(0, 5%);
}
@media (max-width: 1366px) {
    :global(#root) {
        --new-category-tile-width: 200px;
    }
    .__newDesign .tileWrapper {
        margin-left: 24px;
    }
    .__newDesign .categoryTitle {
        font-size: 24px;
        line-height: 30px;
    }
    .__newDesign .tiles {
        margin-top: 20px;
    }
    .__newDesign {
        margin-top: 32px;
    }
    .__newDesign:first-of-type {
        margin-top: 32px;
    }
    .__newDesign [class*='GameTile__tileText___'] {
        font-size: 24px;
        line-height: 30px;
        font-weight: 400;
        margin-top: 16px;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
    }
    .__newDesign :global(.__arrow) {
        width: calc(var(--new-category-tile-width)/3*2);
        height: var(--new-category-tile-width);
        top: 50px;
    }
    .__newDesign :global(.__arrow .arrow-img) {
        width: 43.62px;
        height: 43.62px;
        margin-right: 14.31px;
    }
    .__newDesign :global(.__arrow .arrow-svg) {
        width: 19.39px;
    }
}
@media (max-width: 1024px) {
    :global(#root) {
        --new-category-tile-width: 149px;
    }
    .__newDesign :global(.__arrow) {
        display: none !important;
    }
    .__newDesign .tileWrapper {
        margin-left: 15px;
    }
    .__newDesign .categoryTitle {
        font-size: 20px;
        line-height: 28px;
    }
    .__newDesign .tiles {
        margin-top: 16px;
    }
    .__newDesign {
        margin-top: 32px;
    }
    .__newDesign:first-of-type {
        margin-top: 20px;
    }
    .__newDesign .tiles,
    .__newDesign .tileWrapper,
    .__newDesign .tileWrapper a,
    .__newDesign [class*='GameTile__tileText___'] {
        height: auto;
        max-height: initial;
    }
    .__newDesign [class*='GameTile__tileText___'] {
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
        margin-top: 8px;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
    }
}
@media (max-width: 375px) {
    :global(#root) {
        --new-category-tile-width: 136px;
    }
    .__newDesign .tiles {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        padding-left: 16px;
    }
    .__newDesign .tileWrapper,
    .__newDesign .tileWrapper a {
        height: auto;
        max-height: initial;
    }
    .__newDesign .tileWrapper {
        margin: 0 8px 20px;
    }
    .__newDesign .tileWrapper:nth-child(odd) {
        margin-left: 0;
    }
    .__newDesign .categoryTitle {
        font-size: 18px;
        line-height: 24px;
    }
    .__newDesign .tiles {
        margin-top: 16px;
    }
    .__newDesign [class*='GameTile__tileText___'] {
        font-size: 16px;
    }
}
@media (max-width: 359px) {
    .__newDesign .tiles {
        padding-left: 0px;
    }
    .__newDesign .tileWrapper {
        margin: 0;
    }
    .__newDesign .tileWrapper:nth-child(1),
    .__newDesign .tileWrapper:nth-child(2) {
        margin-bottom: 20px;
    }
    .__newDesign .tileWrapper:nth-child(odd) {
        margin-right: auto;
    }
    .__newDesign .tileWrapper:nth-child(evem) {
        margin-left: auto;
    }
}

.container {
    position: relative;
    isolation: isolate;
}

.caption {
    position: absolute;
    bottom: 100%;
    margin: 0;
    font-size: 12px;
    font-weight: bold;
    color: #4e555e;
}

/* Made for local development */

:global(display-ad-component) {
    display: inline-block;
}

:root {
    --xs-bp: 0;
    --sm-bp: 576px;
    --md-bp: 768px;
    --lg-bp: 1025px;
    --xl-bp: 1200px;
    --xxl-bp: 1370px;

    --xs-max-bp: 575px;
    --sm-max-bp: 767px;
    --md-max-bp: 1024px;
    --lg-max-bp: 1199px;
    --xl-max-bp: 1369px;
    --xxl-max-bp: 1500px;
}
.dialog {
    --big-width: 1075px;
    --small-width: 1000px;
}
.image {
    --small-height: 555px;
}
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: var(--z-index-overlay);
    display: flex;
    align-items: center;
    justify-content: center;
}
.dialog {
    position: relative;
    background-color: white;
    display: flex;
    border-radius: 2px;
    width: calc(100% - 50px);
    max-width: var(--small-width);
    z-index: var(--z-index-modal);
}
@media (min-width: 1370px) {
    .dialog {
        max-width: var(--big-width);
    }

    .underLogo {
        font-size: 26px;
    }

    .subTitle {
        font-size: 22px;
    }
}
@media (max-width: 1370px) {
    .image {
        max-height: var(--small-height);
    }
}
.exitButton {
    position: absolute;
    top: -20px;
    right: -20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    z-index: 1002;
    background-color: white;
    box-shadow: -3px 3px 3px rgba(0, 0, 0, 0.5);
    cursor: pointer;
}
.leftPart {
    padding: 30px 0px 35px 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    /*For correct IE layout*/
    width: 605px;
}
.rightPart {
    display: block;
    position: relative;
    flex: 1 0 auto;
}
.underLogo {
    font-size: 24px;
    font-weight: 200;
    margin-left: 32px;
}
.textContainer {
    margin-left: 32px;
}
.link {
    margin-right: 20px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    color: var(--blue-medium);
    white-space: nowrap;
}
.link:hover {
    text-decoration: none;
    color: var(--blue-medium);
}
.textContainer > .link {
    margin-left: 5px;
    margin-right: 0px;
    text-decoration: underline;
}
.subTitle {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
}
.text {
    font-size: 16px;
    font-weight: normal;
}
.bottomLinks {
    margin-top: 5px;
    margin-left: 32px;
    display: flex;
    max-width: 360px;
}
.image {
}
.linkWrapper {
    display: inline-flex;
    flex-direction: column;
    cursor: pointer;
    padding-top: 10px;
}
.linkWrapper:hover {
    outline: solid 1px #d50032;
}
.smallText {
    padding-left: 10px;
    font-size: 10px;
    color: #080808;
}
.poweredBy {
    text-align: left;
    display: inline-block;
}
.poweredByLink {
        margin-bottom: -15px;
    }
.poweredByContainer__right {
        text-align: right;
    }
.poweredByContainer__left {
        text-align: left;
    }

.Container {
    padding: 15px 8px;
}
.AvatarBlock {
    text-align: center;
}
.AvatarBtn {
    margin-top: 10px;
    font-size: 12px;
    color: #004db3;
    box-shadow: none;
    border: 0;
    text-decoration: underline;
}
.InfoBlock {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

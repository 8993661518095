.imageBanner {
    width: 100%;
    border-radius: 16px 16px 0 0;
}

.addBottomMargin {
    margin-bottom: 42px;
}

@media screen and (max-width: 1024px) {
    .imageBanner.mobile {
        border-radius: 0;
    }
}

.modalWrapper {
    overflow-y: auto;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
}
.popupWrapper {
    --popup-width: min(75vw, 70vh, 700px);
    width: var(--popup-width) !important;
    height: var(--popup-width);
    font-size: calc(var(--popup-width)/20);
    min-height: 0;
    max-width: none;
    padding: 1em !important;
    border-radius: 0;
}
.mobile {
    aspect-ratio: 1/1.234; /*initial size from leanplum is 1400 x 1720*/
    height: auto;
}
.sectionWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}
.sectionWrapper :not(.image),
.closeIcon {
    z-index: 1;
}
.closeIcon {
    top: 0.6em !important;
    right: 0.6em !important;
}
.closeIcon svg {
    width: 1.2em;
    height: 1.2em;
}
.image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
}
.title {
    font-weight: bold;
    font-size: 0.97em;
    text-align: center;
    margin-bottom: 0.4em;
    line-height: 1.2;
    letter-spacing: 0.3px;
}
.description {
    font-size: 0.95em;
    text-align: center;
    line-height: 1.1;
    margin-bottom: 0.7em;
    letter-spacing: -0.9px;
}
.submitButton {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
    font-size: 0.8em;
    line-height: 1.2;
    width: 8.4em;
    height: 2.2em;
    border-radius: 32px;
    color: inherit;
    transition: box-shadow 0.2s ease;
}
.submitButton:hover {
    text-decoration: none;
    box-shadow: 0px 4px 40px 10px rgba(0, 0, 0, 0.65);
}

.popupTitle {
    color: #004db5;
    text-align: center;
    font-size: 22px;
    margin: 0;
    margin-bottom: 24px;
    line-height: initial;
}
.popupTitle.eagle {
    font-size: 24px;
    margin-bottom: 14px;
    color: var(--eagle-blue-color);
}
.popupTitle.eagle p {
    margin-bottom: 0;
}
.eagle.giantMarginBottom {
    margin-bottom: 82px;
}
.createAccountRow {
    order: 5;
    display: flex;
    justify-content: center;
    height: 45px;
    align-items: center;
}
.createAccountRow.eagle {
    height: auto;
    margin-top: 20px;
    margin-bottom: 30px;
}
.defaultOrder {
    order: unset;
}
.createAccountRow.noMargin {
    margin: 0;
}
.createAccount {
    color: #545454;
    padding: 0 11px;
    font-size: 12px;
}
.createAccount.eagle {
    color: #000;
    font-size: 14px;
    padding: 0 4px;
}
.buttonFirst {
    display: flex;
    flex-direction: row-reverse;
}
.buttonFirst button {
    margin-right: 4px;
}
.popupDescription {
    color: var(--login-description-color);
    text-align: center;
    margin-bottom: 18px;
    padding: 0 20px;
    font-size: 14px;
    line-height: initial;
}
.popupDescription.eagle {
    color: var(--main-color);
    line-height: 18px;
}
.popupDescription.eagle.customMarginBottom {
    margin-bottom: 40px;
}
.popupDescription.eagle p {
    margin-bottom: 0;
}
.popupDescription:last-of-type.eagle {
    margin-bottom: 30px;
}
.passwordRequirementsRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #828282;
    margin: 8px 0 16px;
}
.uppercase {
    margin-left: 8px;
}
.error {
    color: var(--red-color);
}
.valid {
    color: var(--green-color);
}
@media (max-width: 768px) {
    .createAccount {
        font-size: 12px;
    }
    .createAccount.eagle {
        padding: 0;
    }
    .passwordRequirementsText {
        display: none;
    }
}

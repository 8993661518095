:root {
    --ark-small-mobile-figma-below-599: 599px;
    --ark-small-mobile-figma-600: 600px;
    --ark-medium-mobile-figma-below-719: 719px;
    --ark-small-desktop-figma-below-1023: 1023px;
    --ark-small-desktop-figma-1024: 1024px;
    --ark-medium-desktop-figma-below-1359: 1359px;
    --ark-medium-desktop-figma-1360: 1360px;
    --ark-large-desktop-figma-1440: 1440px;
}

.body {
    background-color: var(--ark-neutral-white);
}

@media (max-width: 1024px) {
    .isOpened {
        transform: translate(-100%);
        transition: transform 0.6s var(--ark-ease-in-out-sine);
    }
}

/*******Subscription*********/

.sub_body {
}

.sub_body h2 {
    font: inherit;
}

.sub_body h1,
.sub_body h2,
.sub_body p {
    margin-bottom: 0;
}

.sub_wrapper {
    padding-top: 40px;
    /* pressing the footer to the bottom to correctly display the loader on the /subscription page, 
    TODO: need to move the hardcoded heights to variables */
    min-height: calc(100vh - 276px);
}

.sub_header {
    height: 80px;
    background: var(--ark-primary-white-figma);
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    grid-template-columns: repeat(12, 1fr);
    grid-gap: var(--ark-base-grid-gap);
    display: grid;
    padding-left: var(--ark-base-grid-gap);
    padding-right: var(--ark-base-grid-gap);
    align-items: center;
}

.logo_block {
    grid-column: 1 / 2;
    justify-self: start;
}

.sub_footer {
    background-color: var(--ark-footer-blue-figma);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 96px;
    margin-top: 100px;
}

.sub_footer__text {
    color: var(--ark-primary-white-figma);
    font-size: var(--ark-18-font-size);
    line-height: var(--ark-18-line-height);
    font-weight: var(--ark-font-weight-light);
}

.sub_footer__text .logoutLink {
    font-weight: var(--ark-font-weight-bold);
    cursor: pointer;
    color: var(--ark-primary-white-figma);
    text-decoration: none;
}

.secure_elem {
    display: flex;
    align-items: center;
    grid-column: 5/9;
    justify-self: center;
}

.secure_elem svg {
    width: 34px;
}

.secure_elem .secure_label {
    color: var(--ark-primary-black-figma);
    font-size: var(--ark-18-font-size);
    font-weight: 700;
    margin-left: 0.5rem;
}

.avatar_block {
    grid-column: 12;
    justify-self: end;
}

@media (max-width: 1023px) {
    .avatar_block,
    .logo_block {
        display: none;
    }
    .sub_wrapper {
        padding-top: 0;
    }
    .secure_elem svg {
        width: 24px;
    }
    .secure_elem .secure_label {
        font-size: var(--ark-14-font-size);
    }
}

@media (max-width: 599px) {
    .sub_header {
        grid-template-columns: repeat(4, 1fr);
        height: 40px;
    }
    .secure_elem {
        grid-column: 1/5;
    }
    .sub_body {
        padding-bottom: 70px;
    }
    .secure_elem .secure_label {
        margin-left: 4px;
    }
    .sub_footer {
        margin-top: 40px;
    }

    .sub_wrapper {
        padding-top: 40px;
        /* pressing the footer to the bottom to correctly display the loader on the /subscription page, 
        TODO: need to move the hardcoded heights to variables */
        min-height: calc(100vh - 236px);
    }
}

:root {
    --ark-small-mobile-figma-below-599: 599px;
    --ark-small-mobile-figma-600: 600px;
    --ark-medium-mobile-figma-below-719: 719px;
    --ark-small-desktop-figma-below-1023: 1023px;
    --ark-small-desktop-figma-1024: 1024px;
    --ark-medium-desktop-figma-below-1359: 1359px;
    --ark-medium-desktop-figma-1360: 1360px;
    --ark-large-desktop-figma-1440: 1440px;
}

.avatar {
    box-sizing: border-box;
    display: block;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 0.562rem;
    cursor: pointer;
    position: relative;
    z-index: 1;
    border: none;
    background: transparent;
}

.avatar_disabled .regular,
.regularAdvantage,
.regularAdvantage2 {
    width: 10.25rem;
    height: 10.25rem;
}

.medium {
    width: 6rem;
    height: 6rem;
    padding: 0;
}

.mediumFrames {
    width: 8.188rem;
    height: 8.188rem;
    border-radius: 10px;
}

.avatar__content {
    border: 2px solid var(--ark-secondary-solid-midgrey);
}

.avatar.mediumFrames.avatar_selected,
.avatar.mediumFrames.avatar_selected:hover {
    border: 0;
    background-color: var(--ark-main-green-figma-25);
    box-shadow: inset 0 3px 7px rgba(18, 48, 102, 0.17);
}

.mediumFrames:hover {
    background-color: var(--ark-hover-blue-transparent-figma);
    box-shadow: inset 0 3px 7px rgba(18, 48, 102, 0.17);
}

.avatar.mediumFrames.avatar_selected .avatar__content {
    box-shadow: none;
}

.avatar.mediumFrames .avatar__content .padlockIcon {
    opacity: 1;
}

.large {
    width: 5rem;
    height: 5rem;
}

.small {
    width: 3.5rem;
    height: 3.5rem;
    padding: 0;
}

.small:hover .avatar__content {
    border: 2px solid var(--ark-main-blue-figma);
}

.smallLeaderboard {
    width: 3rem;
    height: 3rem;
    padding: 0;
}

.smallLeaderboard:not(.hoveredLeaderBoard):hover::after .avatar__content {
    width: 3rem;
    height: 3rem;
    content: ' ';
    padding: 3px;
    border: 2px solid var(--ark-main-cyan-figma);
    border-radius: 3rem;
    position: absolute;
}

.avatar__content {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: var(--ark-avatar-background-blue-light);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition: all 0.3s;
    border-width: 4px;
    z-index: 1;
}

.avatar__content .avatarImg {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

/* Remove comments if everything is ok after the test */

/* .avatar.regular .avatar__content .avatarImg {
    width: 6.8rem;
} */

/* .avatar.regularAdvantage .avatar__content .avatarImg,
.avatar.regularAdvantage2 .avatar__content .avatarImg {
    width: 6.5rem;
} */

/* .avatar.medium .avatar__content .avatarImg,
.avatar.mediumFrames .avatar__content .avatarImg {
    width: 5.092rem;
} */

/* .avatar.large .avatar__content .avatarImg {
    width: 2.6rem;
}

.avatar.small .avatar__content .avatarImg {
    width: 2.438rem;
}

.avatar.smallLeaderboard .avatar__content .avatarImg {
    width: 2rem;
} */

.avatar.small .avatar__content,
.avatar.smallLeaderboard .avatar__content {
    border-width: 2px;
}

.avatar_regularMousePointer {
    cursor: pointer;
}

.avatar_selected {
    border: 1px solid green;
}

.avatar_selected .avatar__content {
    box-shadow: 0 20px 20px 0 rgba(0, 163, 113, 0.5);
}

.avatarList:hover {
    box-shadow: 0 20px 20px 0 rgba(0, 163, 113, 0.5);
}

.avatar_disabled {
    pointer-events: none;
}

.avatar_disabled,
.avatar_disabled:hover,
.avatar_disabled:hover::after {
    cursor: default;
    box-shadow: transparent;
    border: none !important;
}

.border {
    border: 4px solid rgba(196, 196, 196, 1);
}

.frameWrapper {
    position: absolute;
    background-size: cover;
    z-index: 1;
}

.regular .frame {
    width: 11rem;
    height: 11rem;
}

.regularAdvantage .frame {
    width: 11rem;
    height: 11rem;
}

.medium .frame,
.mediumFrames .frame,
.medium_list_item .frame {
    width: 8.5rem;
    height: 100%;
}

.large .frame {
    width: 4.5rem;
    height: 4.5rem;
}

.small .frame {
    width: 4rem;
    height: 4rem;
}

.smallLeaderboard .frame {
    width: 3.625rem;
    height: 3.625rem;
}

/*********Avatar List Item************/

.avatar.medium_list_item {
    width: 6.875rem;
    height: 6.875rem;
    border-radius: 10px;
    position: relative;
    padding: 0.4rem;
}

.avatar.medium_list_item.avatar_selected,
.avatar.medium_list_item.avatar_selected:hover {
    border-color: transparent;
    background-color: rgba(1, 124, 85, 0.25);
}

.avatar.medium_list_item.avatar_selected .avatar__content {
    box-shadow: none;
}

.avatar.medium_list_item .avatar__content {
    /* width: 6rem;
    height: 6rem; */
    transition: all 0.3s;
    border-width: 2px;
}

/* .avatar.medium_list_item .avatar__content .avatarImg {
    width: 4.219rem;
    height: 5.625rem;
} */

.avatar.medium_list_item:hover {
    background-color: rgba(0, 90, 255, 0.07);
}

.avatar.medium_list_item .avatar__content:hover {
    box-shadow: none;
}

.padlockIcon {
    display: block;
    visibility: hidden;
    position: absolute;
    z-index: 1;
}

.framePadlock.padlockIcon {
    visibility: visible;
}

.avatar.medium_list_item:hover .padlockIcon,
.avatar.medium_list_item:focus .padlockIcon {
    visibility: visible;
}

.avatar.medium_list_item .avatar__content.premiumHover::after {
    content: '';
    display: block;
    position: absolute;
    background-color: #00000036;
    border-radius: 50%;
    width: 5.8rem;
    height: 5.8rem;
}

/********New Styles*********/

.advantageIcon {
    position: absolute;
    right: 0.5rem;
    bottom: 0.375rem;
    z-index: 1;
}

.checkmarkIcon {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    z-index: 1;
}

.srOnly {
    transform: scale(0);
    width: 0;
    height: 0;
    position: absolute;
}

@media (max-width: 1024px) {
    .avatar.regular {
        width: 5.562rem;
        height: 5.562rem;
    }

    .regular .frame {
        width: 5.8125rem;
        height: 5.8125rem;
    }

    .avatar.regularAdvantage {
        width: 6.875rem;
        height: 6.875rem;
    }

    .regularAdvantage .frame {
        width: 7rem;
        height: 7rem;
    }

    .avatar.medium {
        width: 6.25rem;
        height: 6.25rem;
        padding: 0;
    }

    /* .avatar.regular .avatar__content .avatarImg {
        width: 3.5rem;
    }

    .avatar.regularAdvantage .avatar__content .avatarImg {
        width: 4rem;
    }

    .avatar.medium .avatar__content .avatarImg {
        width: 4.8rem;
    } */
}

@media (min-width: var(--ark-small-desktop-1025)) {
    .avatarHighLight {
        box-shadow: 0 20px 20px 0 rgba(0, 163, 113, 0.5);
    }
}

@media (max-width: 599px) {
    .avatar.regularAdvantage2 {
        width: 5.2rem;
        height: 5.2rem;
    }

    .regularAdvantage2 .frame {
        width: 4.2rem;
        height: 4.2rem;
    }

    .medium_list_item .frame {
        width: 5.8125rem;
        height: 5.8125rem;
    }

    /* .avatar.regularAdvantage2 .avatar__content .avatarImg {
        width: 2.7rem;
    } */

    .avatar.medium_list_item {
        width: 6.125rem;
        height: 6.125rem;
    }
    .avatar.medium_list_item .avatar__content {
        width: 5rem;
        height: 5rem;
    }

    .avatar.medium_list_item .avatar__content.premiumHover::after {
        content: '';
        display: block;
        position: absolute;
        background-color: #00000036;
        border-radius: 50%;
        width: 4.8rem;
        height: 4.8rem;
    }
}

.mediumFrames .checkmarkIcon {
    position: absolute;
    width: 34px;
    height: 34px;
    right: 0.5rem;
    top: 0.5rem;
    z-index: 1;
}

/* NOT HOVERED AVATARS STYLES*/

.avatar.noHover {
    cursor: default;
}

.noHover:hover:after {
    border: none;
    display: none;
}

.noHover:hover .avatarList {
    box-shadow: none;
}

:root {
    --xs-bp: 0;
    --sm-bp: 576px;
    --md-bp: 768px;
    --lg-bp: 1025px;
    --xl-bp: 1200px;
    --xxl-bp: 1370px;

    --xs-max-bp: 575px;
    --sm-max-bp: 767px;
    --md-max-bp: 1024px;
    --lg-max-bp: 1199px;
    --xl-max-bp: 1369px;
    --xxl-max-bp: 1500px;
}
:root {
    --profile-page-modal-top: 26px;
}
.Container {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: var(--z-index-overlay);
    overflow: auto;
    background: rgba(0, 0, 0, 0.4);
}
.Modal {
    background: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
    border-radius: 4px;
    font-size: 12px;
    position: absolute;
    width: 668px;
    z-index: var(--z-index-modal);
    top: 26px;
    left: 50%;
    transform: translateX(-50%);
}
.Title {
    position: relative;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e3e3e3;
    padding: 0 15px;
    height: 50px;
    font-size: 18px;
}
.TitleIcon {
    margin-right: 10px;
    color: #3c63f2;
}
.CloseBtn {
    position: absolute;
    right: 15px;
}
.Content {
    display: flex;
    flex-wrap: wrap;
}
.SelectedAvatarBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 212px;
    border-right: 1px solid #e3e3e3;
}
.SelectedAvatarWrap {
    margin: 10px;
    height: 166px;
}
.SelectedAvatar {
    display: block;
    border-radius: 50%;
    max-width: 100%;
}
.CancelBtn {
    margin-top: 10px;
    font-size: 14px;
    color: #004db3;
    text-decoration: underline;
}
.AvatarsBlock {
    padding: 0;
}
.TabsList {
    display: flex;
    justify-content: space-around;
    border-bottom: 2px solid #e3e3e3;
    padding: 0;
}
.Tab {
    list-style: none;
    color: #e3e3e3;
    padding: 0 11px;
    line-height: 40px;
    border-bottom: 2px solid transparent;
    font-weight: 600;
    font-size: 12px;
    margin-bottom: -2px;
    cursor: pointer;
}
.Tab.active {
    border-bottom: 2px solid #014db2;
    color: #212529;
}
@media screen and (max-width: 767px) {
    .Modal {
        width: 320px;
    }

    .SelectedAvatarBlock {
        flex-direction: row;
        justify-content: space-around;
    }

    .SelectedAvatarWrap {
        width: 80px;
        height: 80px;
        order: 1;
    }
    .CancelBtn {
        order: 2;
        margin: 0;
    }
    .SaveBtn {
        padding: 7px 8px;
        font-size: 14px;
        order: 3;
    }
    .Tab {
        padding: 0 9px;
    }
}

:root {
    --xs-bp: 0;
    --sm-bp: 576px;
    --md-bp: 768px;
    --lg-bp: 1025px;
    --xl-bp: 1200px;
    --xxl-bp: 1370px;

    --xs-max-bp: 575px;
    --sm-max-bp: 767px;
    --md-max-bp: 1024px;
    --lg-max-bp: 1199px;
    --xl-max-bp: 1369px;
    --xxl-max-bp: 1500px;
}
.button {
    width: 100%;
    min-height: 56px;
    /* IE FIX */
    height: 100%;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: transparent;
    background-color: var(--lb-tab-bg);
    color: #ffffff;
    border-radius: var(--lb-tab-br);
}
.button:hover,
.button:focus,
.button.isActive {
    background-color: #ffffff;
    color: #3b4148;
}
.icon {
    color: #ffffff;
    margin-right: 0.5em;
}
.button:hover .icon,
.button:focus .icon {
    color: var(--lb-icon-active-color);
}
.icon.isActive {
    color: var(--lb-icon-active-color);
}
@media (max-width: 1199px) {
    .button {
        flex-direction: column;
    }

    .icon {
        margin: 0;
    }
}
@media (max-width: 767px) {
    .button {
        font-size: 16px;
    }
}

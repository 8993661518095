:root {
    --ark-small-mobile-figma-below-599: 599px;
    --ark-small-mobile-figma-600: 600px;
    --ark-medium-mobile-figma-below-719: 719px;
    --ark-small-desktop-figma-below-1023: 1023px;
    --ark-small-desktop-figma-1024: 1024px;
    --ark-medium-desktop-figma-below-1359: 1359px;
    --ark-medium-desktop-figma-1360: 1360px;
    --ark-large-desktop-figma-1440: 1440px;
}

.wrapper {
    margin-top: 24px;
    background-color: var(--ark-secondary-solid-lightgrey-figma);
    padding: 36px;
}

.heading {
    color: var(--ark-primary-black-figma);
    font-size: var(--ark-24-font-size);
    font-weight: 700;
}

.inputRow {
    margin-top: 24px;
}

.secureTypesBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    min-height: 120px;
}

.handlersRow {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
}

.handlersRow button {
    width: 100%;
}

.removeLink {
    color: var(--ark-main-blue-figma);
    font-size: var(--ark-18-font-size);
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
}

/*****Media *******/

@media (max-width: 1359px) {
    .wrapper {
        padding: 24px;
    }
}

@media (max-width: 1359px) and (min-width: 600px) {
    .invalidRow {
        margin-top: 45px;
    }

    .invalidInput {
        margin-bottom: 5px;
    }
}

@media (max-width: 1023px) {
    .wrapper {
        padding: 24px 16px;
    }

    .heading {
        text-align: center;
        font-size: var(--ark-20-font-size);
    }
}

@media (max-width: 599px) {
    .wrapper {
        margin-left: -1rem;
        margin-right: -1rem;
        padding: 24px 16px;
    }

    .heading {
        text-align: left;
    }
}

.header {
    color: #004db3;
    font-size: 16px;
    font-weight: 600;
    margin-top: 20px;
    max-width: 80%;
    text-align: center;
    line-height: initial;
}

.agreementText {
    max-width: 450px;
    padding: 30px 0;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: initial;
}

.checkboxes {
    position: relative;
    background: #fafafa;
    padding: 10px;
    color: #5f5f5ff2;
    border-radius: 3px;
    font-size: 12px;
    border: 1px solid #cdcdcd;
    margin: 20px 0;
    max-width: 96%;
}

.checkboxes.error {
    border: 1px solid #f5212d;
}

.checkboxes .errorText {
    display: none;
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
}

.checkboxes.error .errorText {
    display: block;
    color: #f5212d;
}

.checkbox input {
    margin-right: 5px;
    vertical-align: -2px;
}

.checkbox label {
    margin-bottom: 0;
    display: inline;
}

.checkbox:last-child {
    margin-top: 5px;
}

.buttons {
    display: flex;
    min-height: 66px;
    max-height: 118px;
    width: 100%;
    border-top: 1px solid #8ca2af;
    justify-content: center;
    background: #f5f5f5;
    border-radius: 0 0 8px 8px;
    padding: 8px;
}

.buttons.eagle {
    padding-top: 11px;
}

.continueButton {
    background-color: #369423;
}

.logoutButton {
    background-color: rgba(0, 0, 0, 0.6);
    margin-left: 20px;
}

.continueButton,
.logoutButton {
    color: #fff;
    width: 240px;
    height: 50px;
    font-size: 13px;
    font-weight: 200;
    border-radius: 16px;
    box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    border: 2px outset rgb(118, 118, 118);
}

.continueButton svg,
.logoutButton svg {
    margin-left: 7px;
    font-size: 16px;
}

.gdprIcon {
    width: 16px;
    height: 16px;
}

.userNote {
    position: absolute;
    bottom: 0;
    color: #fff;
    text-align: center;
    font-size: 12px;
    width: 100%;
    left: 0;
    transform: translateY(100%);
}

@media (max-width: 768px) {
    .agreementText {
        font-size: 12px;
    }

    .checkboxes {
        width: auto;
    }

    .userNote {
        padding-top: 22px;
    }
}

@media (max-width: 352px) {
    .buttons {
        flex-direction: column;
        align-items: center;
    }

    .logoutButton {
        margin-left: 0;
        margin-top: 10px;
    }
}

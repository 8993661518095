:root {
    --xs-bp: 0;
    --sm-bp: 576px;
    --md-bp: 768px;
    --lg-bp: 1025px;
    --xl-bp: 1200px;
    --xxl-bp: 1370px;

    --xs-max-bp: 575px;
    --sm-max-bp: 767px;
    --md-max-bp: 1024px;
    --lg-max-bp: 1199px;
    --xl-max-bp: 1369px;
    --xxl-max-bp: 1500px;
}
.container {
    background: #ffffff;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    color: #707070;
    z-index: 10005;
    min-height: 600px;
    height: 100%;
}
.container.fullScreen {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    min-height: 0;
}
.header {
    max-width: 100%;
    min-height: 60px;
}
.title {
    font-size: 34px;
    font-weight: bold;
    border-bottom: 1px solid #d1d1d1;
    padding: 70px 15px 20px;
    margin: 0;
    position: relative;
    color: #000000;
}
.iconWrapper {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid #d40030;
    border-radius: 50%;
    color: #d40030;
    width: 100px;
    height: 100px;
    margin: 0 auto;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.iconWrapper svg {
    width: 65px;
    height: 65px;
}
.text {
    flex-grow: 2;
    font-size: 22px;
    padding: 10px 20px;
    margin: 0;
    display: flex;
    align-items: center;
}
.instructions {
    padding: 20px 15px;
    border-top: 1px solid #d1d1d1;
    font-size: 28px;
    margin: 0;
}
.link {
    color: #d40030;
    margin-left: 5px;
    text-decoration: underline;
}
@media (max-width: 1199px) {
    .title {
        font-size: 28px;
    }
}
@media (max-width: 767px) {
    .title {
        font-size: 24px;
        padding-top: 50px;
    }
    .iconWrapper {
        height: 75px;
        width: 75px;
    }
    .iconWrapper svg {
        height: 45px;
        width: 45px;
    }
    .text {
        font-size: 18px;
    }
    .instructions {
        font-size: 18px;
    }
}

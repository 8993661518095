:root {
    --ark-small-mobile-figma-below-599: 599px;
    --ark-small-mobile-figma-600: 600px;
    --ark-medium-mobile-figma-below-719: 719px;
    --ark-small-desktop-figma-below-1023: 1023px;
    --ark-small-desktop-figma-1024: 1024px;
    --ark-medium-desktop-figma-below-1359: 1359px;
    --ark-medium-desktop-figma-1360: 1360px;
    --ark-large-desktop-figma-1440: 1440px;
}
:root {
    --ark-asset-final-animation: url('https://arenacloud.cdn.arkadiumhosted.com/arena51-assets/canary/illustrations/subscription/FinalAnimationTemplate.png');
    --ark-asset-purchase-gems: url('https://arenacloud.cdn.arkadiumhosted.com/arena51-assets/canary/images/gems/Purchase3StepGemsImage.svg');
    /** font-size and width **/
    --min-fs: 12px;
    --min-fs-n: 12;
    --gutter: 16px;

    /** Fixed column sizes **/
    --ad-column-size: calc(300px + var(--gutter));

    /** colors **/
    --main-color: #333;
    --inverted-color: #fff;
    --new-white-color: #f9f9f9;
    --accent-color: #2b779b;
    --accent-light-color: #62727b;
    --gray-light-color: #f6f7f8;
    --gray-medium-color: #e9eaeb;
    --gray-heavy-color: #d1d2d3;
    --gray-startribune-color: #AFAFAF;
    --gray-startribune-dark-color: #666666;
    --black-startribune-color: #0D0D0D;
    --border-color: #7c8693;
    --gray-blue-color: #c8cceb;
    --aarp-outline-color: #ef382a;
    --aarp-underline-color: #bd1b0e;
    --blue-medium: #1e36ae;
    --error-text: #db5757;
    --error-border: #f00;
    --login-description-color: #545454;
    --eagle-blue-color: #0a49b2;
    --eagle-blue-color-darker: #0042bb;
    --eagle-input-border: #231f20;

    --green-color: #0aa935;
    --green-medium-color: #017c55;
    --green-medium-darker-color: #106145;
    --green-startribune-color: #00854B;
    --green-startribune-dark-color: #006B3D;
    --red-color: #dd1b32;

    --hsn-blue-color: #0177C8;
    --hsn-red-color: #C80A08;
    --hsn-dark-gray-color: #333333;
    /** sizes **/
    --xxs-space: 0.25em;
    --xs-space: 0.8em;
    --sm-space: 0.9375em;
    --md-space: 1em;
    --lg-space: 1.25em;
    --xl-space: 1.875em;
    --xxl-space: 2.5em;

    /** text sizes **/
    --md-text: 1.125em;
    --lg-text: 1.1875em;
    --xl-text: 1.375em;
    --h2-text: 2.125em;

    /** other sizes **/
    --tablet-ad-height: 90px;
    --mobile-ad-height: 50px;
    --mobile-ad-portrait-height: 100px;
    --content-row-max-width: 1500px;
    --left-rail-width: calc(80px + var(--gutter));

    /*avatars sizes*/
    --profile-person-avatar-size: 98px;
    --avatars-list-avatar-size: 82px;

    /*  DO NOT REMOVE THIS OUTDATED VARIABLES! IT WILL BREAK PAYMENT PAGE  */
    /* OUTDATED VALUES */

    /** Outdated Colors **/

    --ark-black-color: rgb(0, 0, 0);
    --ark-black-15-color: rgba(0, 0, 0, 0.15);
    --ark-gray-dark-color: rgb(49, 50, 61);
    --ark-gray-medium-color: rgb(91, 94, 108);
    --ark-gray-light-color: rgba(155, 157, 163, 1);
    --ark-gray-light2-color: rgba(236, 236, 236, 1);
    --ark-gray-extra-light-color: rgba(242, 242, 242, 1);
    --ark-white-50-color: rgba(255, 255, 255, 0.5);
    --ark-arkadium-red-color: rgba(220, 30, 52, 1);
    --ark-primary-50-color: rgba(112, 86, 214, 0.5);
    --ark-primary-20-color: rgba(112, 86, 214, 0.2);
    --ark-primary-light-color: rgba(160, 137, 252, 1);
    --ark-primary-dark-color: rgba(66, 52, 145, 1);
    --ark-secondary-color: rgba(0, 124, 86, 1);
    --ark-secondary-dark-color: rgba(7, 147, 99, 1);
    --ark-tertiary-color: rgb(28, 103, 215);
    --ark-tertiary-light-color: rgba(1, 64, 188, 1);
    --ark-extra-orange-color: rgba(245, 133, 54, 1);
    --ark-extra-orange-50-color: rgba(245, 133, 54, 0.5);
    --ark-secondary-blue-light-20: rgba(0, 255, 255, 0.2);
    --ark-avatar-background-blue-light: rgb(124, 171, 241);
    --ark-primary-green-40: rgba(0, 163, 113, 0.4);
    --ark-lb-light: rgb(234, 255, 255);
    --ark-path: rgb(221, 221, 221);
    --ark-slider-button: rgb(42, 122, 211);

    /** Colors **/

    --ark-neutral-white: rgb(255, 255, 255);
    --ark-neutral-white-10: rgba(255, 255, 255, 0.1);
    --ark-neutral-white-80: rgba(255, 255, 255, 0.8);
    --ark-neutral-white-60: rgba(255, 255, 255, 0.6);
    --ark-neutral-black: rgb(8, 8, 8);
    --ark-neutral-dark: rgb(11, 16, 51);
    --ark-neutral-black-30: rgba(0, 0, 0, 0.3);
    --ark-neutral-black-10: rgba(0, 0, 0, 0.1);
    --ark-neutral-disabled: rgb(145, 145, 145);
    --ark-neutral-medium: rgb(217, 217, 217);
    --ark-neutral-light: rgb(240, 240, 240);
    --ark-primary-green-dark: rgb(0, 145, 96);
    --ark-primary-green: rgb(0, 163, 113);
    --ark-secondary-blue-light: rgb(0, 255, 255);
    --ark-highlights-green-20: rgba(0, 163, 113, 0.2);
    --ark-highlights-blue-light-10: rgba(0, 255, 255, 0.1);
    --ark-secondary-lilac: rgb(150, 0, 111);
    --ark-primary-red: rgb(213, 0, 50);
    --ark-blue-1-30: rgba(36, 132, 200, 0.3);
    --ark-orange-1-30: rgba(245, 108, 74, 0.3);
    --ark-green-1-30: rgba(7, 176, 142, 0.3);
    --ark-purple-1: rgb(75, 23, 172);
    --ark-blue-1: rgb(36, 132, 200);
    --ark-red-1: rgb(229, 53, 86);
    --ark-green-1: rgb(7, 176, 142);
    --ark-blue-2: rgb(11, 80, 208);
    --ark-purple-2: rgb(108, 13, 171);
    --ark-pink-1: rgb(182, 20, 155);
    --ark-turquoise-1: rgb(0, 111, 124);
    --ark-yellow-1: rgb(225, 170, 0);
    --ark-orange-1: rgb(245, 108, 74);
    --ark-orange-2: rgb(211, 81, 40);
    --ark-green-2: rgb(3, 134, 80);

    /* Figma Colors */

    --ark-footer-blue-figma: rgb(30, 42, 121);
    --ark-primary-black-figma: rgba(35, 31, 32, 1);
    --ark-primary-black-20-figma: rgba(35, 31, 32, 0.2);
    --ark-primary-white-figma: rgba(249, 249, 249, 1);
    --ark-primary-white-30-figma: rgba(255, 255, 255, 0.3);
    --ark-primary-asbestos-figma: rgba(108, 108, 108, 1);
    --ark-primary-royal-blue-figma: rgba(11, 16, 61, 1);
    --ark-primary-royal-blue-figma-90: rgba(46, 50, 89, 1);
    --ark-primary-royal-blue-figma-80: rgba(71, 75, 109, 1);
    --ark-primary-royal-blue-figma-70: rgba(11, 16, 61, 0.7);
    --ark-primary-royal-blue-figma-25: rgba(11, 16, 61, 0.25);
    --ark-primary-blue-figma: rgba(0, 66, 187, 1);
    --ark-primary-blue-figma-50: rgba(0, 66, 187, 0.5);
    --ark-primary-blue-figma-font: rgba(28, 67, 192, 1);
    --ark-neutral-black-50-figma: rgb(172, 171, 171);
    --ark-secondary-solid-lightgrey-figma: rgba(239, 239, 239, 1);
    --ark-secondary-solid-midgrey: rgba(196, 196, 196, 1);
    --ark-main-green-figma: rgba(1, 124, 85, 1);
    --ark-main-green-figma-25: rgba(1, 124, 85, 0.25);
    --ark-main-blue-figma: rgba(0, 66, 187, 1);
    --ark-main-nataline-blue-figma: rgba(30, 42, 121, 1);
    --ark-main-cyan-figma: rgba(47, 227, 232, 1);
    --ark-hover-light-green-figma: rgba(190, 232, 219, 1);
    --ark-advantage-yellow-figma: rgba(224, 184, 72, 1);
    --ark-advantage-blue-figma: rgba(6, 0, 100, 1);
    --ark-hover-dark-green-figma: rgba(16, 97, 69, 1);
    --ark-hover-blue-transparent-figma: rgba(0, 90, 255, 0.07);
    --ark-hover-light-blue-figma: rgba(189, 207, 238, 1);
    --ark-hover-dark-blue-figma: rgba(0, 50, 141, 1);
    --ark-hover-dark-red-figma: rgba(173, 0, 40, 1);
    --ark-orange-figma: rgba(248, 209, 92, 1);
    --ark-gold-text-figma: rgba(255, 182, 51, 1);
    --ark-hover-light-cyan-figma: rgba(187, 242, 242, 1);
    --ark-main-purple: rgba(156, 35, 122, 1);
    --ark-main-deep-purple: rgb(98, 50, 183);
    --ark-hover-light-natalie-blue-figma: rgba(200, 204, 235, 1);
    --ark-focus-figma: rgba(213, 0, 50, 1);
    --ark-link-color: rgb(28, 103, 215);
    --ark-link-hover-color: rgba(0, 66, 187, 1);
    --ark-preroll-background-color: rgba(16, 27, 76, 1);
    --ark-extended-solid-blue: rgba(0, 66, 187, 1);
    --ark-extended-semantic-success: rgba(41, 147, 114, 1);
    --ark-extended-solid-red: rgba(234, 129, 154, 1);
    --ark-neutral-black-10-figma: rgba(249, 249, 249, 1);
    --ark-neutral-black-30-figma: rgba(216, 216, 216, 1);

    /** Outdated Fonts settings **/

    --ark-h3-line-height: initial;
    --ark-label1-line-height: 1.25rem;

    /** Outdated Rem-bases spaces **/

    --ark-spacing1: 0.25rem;
    --xxl-new-space: 2.75rem;

    /** Font settings **/

    --ark-font-family: 'Nunito Sans', sans-serif;

    /* Font sizes */

    --ark-80-font-size: 5rem;
    --ark-64-font-size: 4rem;
    --ark-56-font-size: 3.5rem;
    --ark-48-font-size: 3rem;
    --ark-44-font-size: 2.75rem;
    --ark-42-font-size: 2.625rem;
    --ark-40-font-size: 2.5rem;
    --ark-36-font-size: 2.25rem;
    --ark-34-font-size: 2.125rem;
    --ark-32-font-size: 2rem;
    --ark-30-font-size: 1.875rem;
    --ark-28-font-size: 1.75rem;
    --ark-26-font-size: 1.625rem;
    --ark-24-font-size: 1.5rem;
    --ark-22-font-size: 1.375rem;
    --ark-21-font-size: 1.3125rem;
    --ark-20-font-size: 1.25rem;
    --ark-19-font-size: 1.1875rem;
    --ark-18-font-size: 1.125rem;
    --ark-16-font-size: 1rem;
    --ark-15-font-size: 0.937rem;
    --ark-14-font-size: 0.875rem;
    --ark-13-font-size: 0.8125rem;
    --ark-12-font-size: 0.75rem;
    --ark-11-font-size: 0.6875rem;
    --ark-10-font-size: 0.625rem;
    --ark-9-font-size: 0.5625rem;

    /* Line heights */

    --ark-80-line-height: 5rem;
    --ark-64-line-height: 4rem;
    --ark-56-line-height: 3.5rem;
    --ark-48-line-height: 3rem;
    --ark-42-line-height: 2.625rem;
    --ark-40-line-height: 2.5rem;
    --ark-39-line-height: 2.4375rem;
    --ark-36-line-height: 2.25rem;
    --ark-34-line-height: 2.125rem;
    --ark-32-line-height: 2rem;
    --ark-31-line-height: 1.9375rem;
    --ark-30-line-height: 1.875rem;
    --ark-29-line-height: 1.8125rem;
    --ark-28-line-height: 1.75rem;
    --ark-27-line-height: 1.6875rem;
    --ark-26-line-height: 1.625rem;
    --ark-25-line-height: 1.5625rem;
    --ark-24-line-height: 1.5rem;
    --ark-22-line-height: 1.375rem;
    --ark-20-line-height: 1.25rem;
    --ark-18-line-height: 1.125rem;
    --ark-17-line-height: 1.0625rem;
    --ark-16-line-height: 1rem;
    --ark-14-line-height: 0.875rem;

    /** Spacing **/

    --ark-spacing-xxs: 0.5rem;
    --ark-spacing-xs: 0.625rem;
    --ark-spacing-s: 1rem;
    --ark-spacing-m: 1.25rem;
    --ark-spacing-l: 1.5rem;
    --ark-spacing-xl: 1.875rem;
    --ark-spacing-xxl: 2rem;
    --ark-spacing-xxl-2: 2.5rem;
    --ark-spacing-xxl-3: 3rem;
    --ark-spacing-xxl-4: 3.5rem;
    --ark-spacing-xxl-5: 4rem;
    --ark-spacing-xxl-6: 5rem;
    --ark-spacing-xxl-7: 5.5rem;
    --ark-spacing-xxl-8: 7rem;

    /** Grid System Figma **/

    --ark-base-grid-gap: 1.5rem;
    --ark-base-mobile-grid-gap: 1rem;

    /** Ark OLD breakpoints **/

    --ark-new-med-small-mobile-360: 360px;
    --ark-new-med-small-mobile-above-361: 361px;
    --ark-med-small-mobile-480: 480px;
    /* remove after header refactor */
    --ark-small-mobile-below-575: 575px;
    --ark-small-mobile-576: 576px;
    --ark-large-mobile-below-767: 767px;
    --ark-large-mobile-768: 768px;
    --ark-small-desktop-1025: 1025px;
    --ark-medium-desktop-below-1199: 1199px;
    --ark-medium-desktop-1200: 1200px;
    --ark-large-desktop-below-1799: 1799px;
    --ark-large-desktop-1800: 1800px;
    --ark-large-desktop-above-1801: 1801px;

    /** Figma breakpoints **/

    --ark-extra-small-figma: 320px;
    --ark-small-mobile-figma-below-599: 599px;
    --ark-small-mobile-figma-600: 600px;
    --ark-medium-mobile-figma-below-719: 719px;
    --ark-medium-mobile-figma: 720px;
    --ark-medium-mobile-figma-767: 767px;
    --ark-medium-mobile-figma-768: 768px;
    --ark-small-desktop-figma-below-1023: 1023px;
    --ark-small-desktop-figma-1024: 1024px;
    --ark-medium-desktop-figma-below-1359: 1359px;
    --ark-medium-desktop-figma-1360: 1360px;
    --ark-medium-desktop-figma-1366: 1366px;
    --ark-large-desktop-figma-1440: 1440px;

    /** Easing **/

    --ark-ease-in-out-sine: cubic-bezier(0.37, 0, 0.63, 1);

    /* Misc shared */

    --category-page-sidebar-width: 276px;
    --ark-border-radius: 0.625rem;
    --co-branded-lp-border-radius: 5px;
    --side-menu-size: 5.625rem;
    --side-menu-horizontal-size: 3.75rem;
    --focus-state-outline-width: 2px;

    /* Temp gradients for homepage-banner. We should move it out to admin site */
    --ark-slide-0-gradient: linear-gradient(to right, rgba(11, 16, 61, 1) 49%, rgba(11, 16, 61, 0) 100%);
    --ark-slide-1-gradient: linear-gradient(to right, rgba(11, 16, 61, 1) 56%, rgba(11, 16, 61, 0) 100%);
    --ark-slide-2-gradient: linear-gradient(to right, rgba(21, 44, 168, 1) 56%, rgba(21, 44, 168, 0) 100%);

    /***Font Weights Figma **/
    --ark-font-weight-extraLight: 200;
    --ark-font-weight-light: 300;
    --ark-font-weight-regular: 400;
    --ark-font-weight-regular-bolder: 600;
    --ark-font-weight-bold: 700;
    --ark-font-weight-extraBold: 800;
    --ark-font-weight-heavy: 900;

    /**Avatars container size*/
    /*Medium*/
    --ark-avatar-medium-list-item: 6.875rem;
    --ark-avatar-small-list-item: 6.125rem;

    --ark-minSize-for-interactive-el: 3rem;

    /* Header styles */
    --ark-header-height: 80px;
    --ark-mobile-header-height: 64px;

    /** Collection card sizes */
    --card-size-desktop: 167px;
    --card-size-tablet: 120px;
    --card-size-mobile: 86px;
}
.subscriptionTemplate {
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 1.5rem;
    grid-gap: var(--ark-base-grid-gap);
    display: grid;
    padding-left: 1.5rem;
    padding-left: var(--ark-base-grid-gap);
    padding-right: 1.5rem;
    padding-right: var(--ark-base-grid-gap);
    min-height: 100vh;
}
.paymentInfo {
    grid-column: 1/9;
}
.summaryInfo {
    grid-column: 9/13;
}
.progressBarBlock {
    height: 120px;
    display: flex;
    align-items: center;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-bottom: 40px;
    justify-content: center;
}
.nextstepText {
    text-align: center;
    color: rgba(35, 31, 32, 1);
    color: var(--ark-primary-black-figma);
    font-size: 1.125rem;
    font-size: var(--ark-18-font-size);
    line-height: 24px;
}
.nextStepBtn {
    max-width: 288px;
    width: 100%;
    margin-bottom: 24px;
    margin-left: 36px;
    margin-right: 36px;
}
.continueBtn {
    margin-top: 2.25rem;
    padding: 10px 30px;
}
.mainStepHeading {
    color: rgba(35, 31, 32, 1);
    color: var(--ark-primary-black-figma);
    font-size: 1.875rem;
    font-size: var(--ark-30-font-size);
    line-height: 36px;
    margin-bottom: 36px;
    font-weight: 300;
    font-weight: var(--ark-font-weight-light);
}
.subTitle {
    font-size: 1.5rem;
    font-size: var(--ark-24-font-size);
    color: rgba(108, 108, 108, 1);
    color: var(--ark-primary-asbestos-figma);
    margin-bottom: 2.5rem;
}
.registerFormTitle {
    margin-bottom: 2.5rem;
}
.stepHeading p {
    line-height: 36px;
    color: rgba(35, 31, 32, 1);
    color: var(--ark-primary-black-figma);
    font-size: 1.5rem;
    font-size: var(--ark-24-font-size);
}
.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 3;
}
.summaryInfo__nextStep {
    margin-top: 1.5rem;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
}
.paymentRow {
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-column-gap: 24px;
}
.step3wrapper {
    margin-top: 24px;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.textCenter {
    text-align: center;
}
.animationBlock {
    height: 220px;
    width: 100%;
    background-image: url('https://arenacloud.cdn.arkadiumhosted.com/arena51-assets/canary/illustrations/subscription/FinalAnimationTemplate.png');
    background-image: var(--ark-asset-final-animation);
    background-position: center;
    background-repeat: no-repeat;
}
.animationBlock.__gems {
    background-image: url('https://arenacloud.cdn.arkadiumhosted.com/arena51-assets/canary/images/gems/Purchase3StepGemsImage.svg');
    background-image: var(--ark-asset-purchase-gems);
}
.noAdsBlock {
    margin-top: 36px;
}
.profileBlock {
    margin-top: 16px;
}
.noAdsBlock,
.profileBlock {
    font-size: 1.125rem;
    font-size: var(--ark-18-font-size);
    line-height: 1.25rem;
    line-height: var(--ark-20-line-height);
}
.finalBtn {
    margin-top: 36px;
    width: 248px;
}
.secureTypesBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
}
.socLogo {
    padding-left: 36px;
}
.pciLogo,
.socLogo {
    opacity: 0.5;
}
.mainBtnWrapper {
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
}
.captcha {
    display: none;
}
/*****Media *******/
@media (max-width: 1359px) {
    .finalBtn {
        margin-top: 16px;
    }

    .nextStepBtn {
        margin-left: 24px;
        margin-right: 24px;
    }
}
@media (max-width: 1023px) {
    .subscriptionTemplate {
        grid-template-columns: repeat(8, 1fr);
    }

    .paymentInfo {
        grid-column: 1/6;
    }

    .summaryInfo {
        grid-column: 6/9;
    }

    .nextStepBtn {
        margin-left: 16px;
        margin-right: 16px;
    }

    .summaryInfo__promoCode {
        padding: 24px;
    }

    .mainStepHeading {
        font-size: 1.25rem;
        font-size: var(--ark-20-font-size);
        line-height: 24px;
        margin-bottom: 1rem;
    }

    .nextstepText,
    .nextstepText p span {
        font-size: 0.875rem;
        font-size: var(--ark-14-font-size);
        line-height: 18px;
    }

    .stepHeading p {
        line-height: 28px;
        font-size: 1.25rem;
        font-size: var(--ark-20-font-size);
    }

    .paymentRow {
        grid-template-columns: repeat(5, 1fr);
        margin-top: 24px;
    }

    .socLogo {
        padding-left: 0.5rem;
    }

    .progressBarBlock {
        height: 96px;
        margin-bottom: 36px;
    }

    .noAdsBlock {
        margin-top: 0;
    }

    .profileBlock,
    .noAdsBlock {
        font-size: 1rem;
        font-size: var(--ark-16-font-size);
    }

    .animationBlock {
        margin: 16px 0 16px 0;
    }

    .subTitle {
        font-size: 1rem;
        font-size: var(--ark-16-font-size);
        margin-bottom: 1rem;
    }

    .registerFormTitle {
        margin-bottom: 1rem;
    }
}
@media (max-width: 719px) {
    .subscriptionTemplate {
        grid-gap: 1rem;
        grid-gap: var(--ark-base-mobile-grid-gap);
        padding-left: 1rem;
        padding-left: var(--ark-base-mobile-grid-gap);
        padding-right: 1rem;
        padding-right: var(--ark-base-mobile-grid-gap);
    }

    .paymentRow {
        grid-gap: 1rem;
        grid-gap: var(--ark-base-mobile-grid-gap);
    }

    .progressBarBlock {
        margin-bottom: 16px;
    }

    .pciLogo svg {
        width: 100px;
    }

    .socLogo svg {
        width: 80px;
    }

    .profileBlock,
    .noAdsBlock {
        font-size: 0.875rem;
        font-size: var(--ark-14-font-size);
    }

    .finalBtn {
        margin-top: 8px;
    }

    .animationBlock {
        margin: 8px 0 8px 0;
    }
}
@media (max-width: 599px) {
    .subscriptionTemplate {
        grid-template-columns: repeat(4, 1fr);
    }

    .paymentInfo {
        grid-column: 1/5;
    }

    .summaryInfo {
        grid-column: 1/5;
    }

    .progressBarBlock {
        margin-left: -1rem;
        margin-right: -1rem;
        margin-bottom: 0;
    }

    .pciLogo svg {
        width: 100%;
    }

    .socLogo svg {
        width: 100%;
    }

    .secureTypesBlock {
        justify-content: space-between;
    }

    .paymentRow {
        grid-template-columns: repeat(4, 1fr);
    }

    .profileBlock,
    .noAdsBlock {
        font-size: 1rem;
        font-size: var(--ark-16-font-size);
        line-height: 20px;
    }

    .mainBtnWrapper {
        position: fixed;
        background: #fff;
        bottom: 0;
        height: 70px;
        display: flex;
        z-index: 100;
    }

    .nextStepBtn {
        margin-bottom: 0;
        width: 80%;
    }

    .nextstepText,
    .nextstepText p span {
        font-size: 1rem;
        font-size: var(--ark-16-font-size);
        line-height: 24px;
    }

    .summaryTopMobileBlock {
        margin-bottom: 24px;
    }

    .stepHeading p {
        line-height: 24px;
        font-size: 1.125rem;
        font-size: var(--ark-18-font-size);
    }

    .continueBtn {
        margin-top: 0;
    }
}
.baseLink {
    font-weight: 700;
}
.captchaModalWrapper {
    max-width: 460px;
    margin: 50px auto;
    min-height: auto;
    justify-content: center;
    align-items: center;
    padding: 20px 10px;
    border-radius: 16px;
}

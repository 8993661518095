:root {
    --ark-small-mobile-figma-below-599: 599px;
    --ark-small-mobile-figma-600: 600px;
    --ark-medium-mobile-figma-below-719: 719px;
    --ark-small-desktop-figma-below-1023: 1023px;
    --ark-small-desktop-figma-1024: 1024px;
    --ark-medium-desktop-figma-below-1359: 1359px;
    --ark-medium-desktop-figma-1360: 1360px;
    --ark-large-desktop-figma-1440: 1440px;
}

/*Summary*/

.summaryInfo__mainInfo {
    background-color: var(--ark-secondary-solid-lightgrey-figma);
    padding: 36px 24px;
}

.summaryInfo__secureTypes {
    margin-top: 1.5rem;
    height: 50px;
}

.advantageRow {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 24px;
}

.taxRow {
    align-items: flex-end;
}

.divider {
    content: ' ';
    background-color: var(--ark-primary-asbestos-figma);
    height: 1px;
    width: 100%;
    margin-top: 36px;
}

.advantageBlock {
    display: flex;
    align-items: center;
}

.advantageBlock__icon {
}

.advantageBlock__text {
}

.advantageBlock__text .mainText {
    font-size: var(--ark-18-font-size);
}

.advantageBlock__text .subText {
    font-size: var(--ark-14-font-size);
    margin-top: 0.5rem;
    max-width: 75%;
    white-space: nowrap;
}

.summaryInfoLabel.subText {
    white-space: initial;
}

.totalRow {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 36px;
}

.summaryInfo__nextStep {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.summaryInfoLabel {
    color: var(--ark-primary-black-figma);
    font-size: var(--ark-24-font-size);
    font-weight: 700;
}

.appliedBlock,
.appliedBlock .summaryInfoLabel {
    color: var(--ark-main-nataline-blue-figma);
}

.summaryInfoLabel s {
    font-size: var(--ark-20-font-size);
    margin-right: 1rem;
    color: var(--ark-primary-asbestos-figma);
}

/*****Media *******/

@media (max-width: 1023px) {
    .summaryInfo__mainInfo {
        padding: 24px 16px;
    }

    .summaryInfoLabel {
        font-size: var(--ark-20-font-size);
    }
}

@media (max-width: 599px) {
    .summaryInfo__mainInfo {
        margin-left: -1rem;
        margin-right: -1rem;
    }
    .summaryInfo__mainInfo.blockHidden {
        display: none;
    }
}

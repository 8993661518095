:root {
    --ark-small-mobile-figma-below-599: 599px;
    --ark-small-mobile-figma-600: 600px;
    --ark-medium-mobile-figma-below-719: 719px;
    --ark-small-desktop-figma-below-1023: 1023px;
    --ark-small-desktop-figma-1024: 1024px;
    --ark-medium-desktop-figma-below-1359: 1359px;
    --ark-medium-desktop-figma-1360: 1360px;
    --ark-large-desktop-figma-1440: 1440px;
}

.mainStepHeading {
    color: var(--ark-primary-black-figma);
    font-size: var(--ark-30-font-size);
    line-height: 36px;
    margin-bottom: 36px;
    display: flex;
    align-items: center;
}

.mainStepHeading_number {
    font-weight: bold;
}

.padlockIcon {
    max-height: 34px;
}

.bottomInputsWrapper {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 auto;
    max-width: 868px;
}

.bottomInputsWrapper .inputBlock {
    align-items: center;
}

[dir="ltr"] .bottomInputsWrapper .inputBlock:first-child label {
    text-align: right;
}

[dir="rtl"] .bottomInputsWrapper .inputBlock:first-child label {
    text-align: left;
}

.bottomInputsWrapper .inputBlock:first-child label {
    width: 200px;
    display: flex;
    justify-content: flex-end;
    margin-right: 1.5rem;
}

.bottomInputsWrapper .inputBlock:nth-child(2) label {
    margin: 0 1.5rem;
}

.bottomInputsWrapper .inputBlock input {
    width: 160px;
}

.inputBlock {
    display: flex;
    position: relative;
}

.label {
    text-transform: uppercase;
    justify-self: end;
    font-size: var(--ark-18-font-size);
}

.stateSelect {
    width: 160px;
}

@media (max-width: 1359px) {
    .bottomInputsWrapper .stateSelect,
    .bottomInputsWrapper .inputBlock input {
        width: 105px;
    }
    [dir="ltr"] .bottomInputsWrapper .inputBlock:first-child label {
        text-align: right;
    }
    [dir="rtl"] .bottomInputsWrapper .inputBlock:first-child label {
        text-align: left;
    }
    .bottomInputsWrapper .inputBlock:first-child label {
        width: 145px;
    }
}

@media (max-width: 1360px) and (min-width: 1023px) {
    .stateSelect ul {
        width: 200px !important;
    }
}

@media (max-width: 1023px) {
    .mainStepHeading {
        font-size: var(--ark-20-font-size);
        line-height: 24px;
        margin-bottom: 24px;
    }

    .label {
        font-size: var(--ark-16-font-size);
    }

    .inputName {
        text-align: left;
        margin-bottom: 0.5rem;
        min-width: auto;
        margin-right: 0;
    }

    .padlockIcon {
        max-height: 20px;
    }

    .label {
        justify-self: start;
    }

    .bottomInputsWrapper {
        justify-content: space-between;
    }

    .bottomInputsWrapper .inputBlock {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .bottomInputsWrapper .stateSelect,
    .bottomInputsWrapper .inputBlock input {
        width: 180px;
    }

    [dir="ltr"] .bottomInputsWrapper .inputBlock label {
        text-align: left !important;
    }

    [dir="rtl"] .bottomInputsWrapper .inputBlock label {
        text-align: right !important;
    }

    .bottomInputsWrapper .inputBlock label {
        width: 100% !important;
        justify-content: flex-start !important;
        margin: 0 0 0.5rem !important;
    }
}

@media (max-width: 719px) {
    .bottomInputsWrapper .stateSelect,
    .bottomInputsWrapper .inputBlock input {
        width: 135px;
    }

    .bottomInputsWrapper {
        margin-bottom: 1.5rem;
    }
}

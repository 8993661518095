.container {
    display: flex;
    align-items: center;
    margin: 0.5em 0;
    overflow: hidden;
}

.delimiter {
    transform: rotateZ(90deg);
    color: #737F97;
}

.link {
    color: #6e6e6e;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.link.isActive {
    color: #3854AB;
}
:root {
    --xs-bp: 0;
    --sm-bp: 576px;
    --md-bp: 768px;
    --lg-bp: 1025px;
    --xl-bp: 1200px;
    --xxl-bp: 1370px;

    --xs-max-bp: 575px;
    --sm-max-bp: 767px;
    --md-max-bp: 1024px;
    --lg-max-bp: 1199px;
    --xl-max-bp: 1369px;
    --xxl-max-bp: 1500px;
}
.tileLink {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: var(--tile-link-padding);
}
.tileLink.isFtux {
    text-decoration: none;
}
.tilePictureWrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: var(--game-tile-picture-height-mobile);
    border-top-left-radius: var(--home-page-br);
    border-top-right-radius: var(--home-page-br);
}
.isFtux {
    margin-bottom: 1px;
}
.ftuxPicture {
    transition: transform 0.3s ease-in;
}
.digitalTurbine {
    width: var(--tile-picture-width);
}
.tileLink:hover .ftuxPicture {
    transform: scale(1.07) translateZ(0);
}
.tileText {
    font-weight: var(--tile-text-fw);
    color: var(--tile-text-color);
    font-size: 20px;
    margin-top: 0.2em;
}
.textBlock {
    position: relative;
    height: var(--game-tile-picture-height-mobile);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: var(--game-tile-text-block-jc);
    padding: 0 8px 8px;
    border: var(--game-tile-text-block-border);
    border-bottom-left-radius: var(--home-page-br);
    border-bottom-right-radius: var(--home-page-br);
}
@media (max-width: 1024px) {
    .tileText {
        display: var(--tile-text-display);
        font-size: var(--tile-text-fs);
        line-height: var(--tile-text-lh);
        text-align: var(--tile-text-align);
        margin-top: var(--tile-text-mt);
    }
}
@media (max-width: 575px), (orientation: landscape) {
    .tileLink {
        height: var(--tile-link-height);
    }
}
.ftuxTileText {
    font-size: 18px;
    height: 2em;
    line-height: 2em;
    text-transform: capitalize;
    background: #35b53e;
    color: #fff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
}

:global(#root) {
    --header-height: calc(var(--new-header-custom-top) + var(--new-header-height) + var(--announce-banner-height));
    --left-gap: var(--new-header-navmenu-width);
    --top-gap: var(--header-height);
    --shop-width-desktop: 824px;
}

.overlay {
    position: fixed;
    top: 0;
    background: rgba(0, 0, 0, 0.8);
    height: 100vh;
    width: 100vw;
    margin: 0;
    z-index: var(--z-index-overlay);
    overflow: hidden;
}

.modalWrapper {
    width: calc(100vw - var(--left-gap));
    height: 98vh;
    position: absolute;
    left: var(--left-gap);
    top: 0;
    overflow-y: auto;
}

.modalWrapper.oldDesign {
    --left-gap: 0px;
}

.modalWrapper.mobile {
    display: flex;
    align-items: flex-start;
    left: 0;
    width: 100%;
}

.closeIcon {
    position: absolute;
    top: 40px;
    right: 40px;
    font-size: 25px;
    color: #fff;
    cursor: pointer;
    line-height: 28px;
    z-index: var(--z-index-modal-close);
}

.closeIcon.mobile {
    top: 24px;
    right: 36px;
}

.modal {
    position: relative;
    display: flex;
    flex-direction: column;
    width: min(100%, var(--shop-width-desktop));
    max-width: var(--shop-width-desktop);
    min-height: calc(100vh - var(--top-gap));
    margin: 0 auto;
    background-color: #fff;
    border-radius: 16px 16px 0 0;
}

.modal.mobile {
    width: 100%;
    padding-bottom: 90px;
}

@media screen and (min-width: 1025px) {
    .modalWrapper.mobile {
        width: calc(100vw - var(--left-gap));
        left: var(--left-gap);
    }
}

@media screen and (max-width: 1024px) {
    .modalWrapper {
        left: 0;
        width: 100%;
    }

    .modal.mobile {
        border-radius: 0;
    }
}

@media (max-width: 375px) {
    .closeIcon {
        right: 24px;
    }

    .modal.mobile {
        height: auto;
    }
}

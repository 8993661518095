.NameBlock {
    display: flex;
    align-items: center;
}

.NameInput {
    background: none;
    border: none;
    outline: none !important;
    color: #004db3;
    font-size: 24px;
    width: calc(100% - 70px);
}

.NameInput.edit {
    border-bottom: 1px solid #004db3;
}

.NameText {
    color: #004db3;
    font-size: 24px;
    max-width: calc(100% - 70px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.EditBtn {
    margin: 0 10px;
}

.EditBtn:focus {
    outline: none;
}

.Icon {
    color: #3C63F2
}

.ErrorMessage {
    color: #f00;
    font-size: 12px;
}
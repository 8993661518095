:root {
    --ark-small-mobile-figma-below-599: 599px;
    --ark-small-mobile-figma-600: 600px;
    --ark-medium-mobile-figma-below-719: 719px;
    --ark-small-desktop-figma-below-1023: 1023px;
    --ark-small-desktop-figma-1024: 1024px;
    --ark-medium-desktop-figma-below-1359: 1359px;
    --ark-medium-desktop-figma-1360: 1360px;
    --ark-large-desktop-figma-1440: 1440px;
}

.wrapper {
    grid-column: 5/9;
}

.description p {
    font-size: 16px;
    line-height: 24px;
}

.wrapper .infoBlock {
    color: red;
}

/*****Media *******/

@media (max-width: 1023px) {
    .wrapper {
        grid-column: 3/6;
    }
}

@media (max-width: 599px) {
    .wrapper {
        grid-column: 1/5;
        margin-bottom: 24px;
    }
}

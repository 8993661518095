.contentWrapper {
    padding: 30px 0;
}

.recaptchaIframe {
    width: 320px;
    min-height: 500px;
    border: none;
    overflow: hidden;
}

.invisibleCaptcha {
    display: none;
}

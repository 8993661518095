/*profile person*/
.profilePersonAvatar {
    width: 100%;
    display: block;
}
/*profile person eagle*/
.profilePersonAvatarEagle {
    aspect-ratio: 1 / 1;
}
/*avatars modal(same for both)*/
.avatarsModalAvatar {
    display: block;
    border-radius: 50%;
    max-width: 100%;
    aspect-ratio: 1 / 1;
}
/*avatars list (same for both)*/
.avatarsListAvatar {
    border-radius: 50%;
    width: var(--avatars-list-avatar-size);
    height: var(--avatars-list-avatar-size);
    margin: 10px;
}
/*lb row*/
.lbRowAvatar {
    display: inline-block;
    border: 1px solid white;
    width: var(--lb-avatar-size);
    min-width: var(--lb-avatar-size);
    height: var(--lb-avatar-size);
    border-radius: 50%;
}
.lbRowAvatarEven {
    border: 1px solid #7c8693;
}

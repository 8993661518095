.progressiveContainer {
    position: relative;
    overflow: hidden;
}

/* 1:1 */

.progressiveContainer.square:before {
    content: '';
    float: left;
    padding-top: 100%;
}

/* 2.16:1 */

.progressiveContainer.wide:before {
    content: '';
    float: left;
    padding-top: 46%;
}

/* 2.4:1 */

.progressiveContainer.promo:before {
    content: '';
    float: left;
    padding-top: 136px; /* by design */
}

.picture {
    display: block;
}

.picture.vertical {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
}

.colorBlock {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.progressiveImageBase {
    display: block;
    height: auto;
    max-width: 100%;
}

.progressiveImageBase.vertical {
    height: 100%;
    width: auto;
    max-width: none;
}

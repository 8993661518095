:root {
    --xs-bp: 0;
    --sm-bp: 576px;
    --md-bp: 768px;
    --lg-bp: 1025px;
    --xl-bp: 1200px;
    --xxl-bp: 1370px;

    --xs-max-bp: 575px;
    --sm-max-bp: 767px;
    --md-max-bp: 1024px;
    --lg-max-bp: 1199px;
    --xl-max-bp: 1369px;
    --xxl-max-bp: 1500px;
}
/* Row */
.lbRow {
    display: flex;
    align-items: center;
    height: 64px;
    background-color: white;
}
.lbRow:nth-child(odd) {
    background-color: #eef1f6;
}
.lbRow:nth-child(even) .position {
    background-color: #f6f8fb;
}
.lbRow:nth-child(even) .country {
}
.lbRow.isUser {
    background-color: #9aefb0 !important;
}
/* Columns */
.positionColumn {
    flex: 0 0 96px;
    max-width: 96px;
    position: relative;
    display: flex;
    align-items: center;
}
.userInfoColumn {
    flex: 0 0;
    flex-basis: calc(33% - 96px);
    max-width: calc(33% - 96px);
    min-width: 72px;
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 2em;
}
.nameColumn {
    flex: 0 0 33.333%;
    max-width: 33.333%;
    font-size: 20px;
    color: #3b4148;
    padding-left: 1em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.nameColumn.digitalTurbine.unlogged {
    font-size: var(--lb-nameColumn-unlogged-fs);
    font-weight: var(--lb-nameColumn-unlogged-fw);
    align-self: center;
    text-overflow: unset;
    overflow: unset;
    white-space: unset;
    word-break: unset;
    padding-right: 6%;
}
.nameColumn.digitalTurbine:not(.unlogged) {
    align-self: flex-end;
    padding: 0 0 11px 6px;
}
.scoreColumn {
    flex: 0 0 33.333%;
    max-width: 33.333%;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: right;
    color: #1e36ae;
    padding-right: 10%;
}
.loginBtn.digitalTurbine {
    height: var(--lb-login-btn-height);
    border-radius: var(--game-end-br);
    background-color: var(--lb-login-btn-bg);
    padding: var(--lb-login-btn-padding);
    font-size: var(--lb-login-btn-fs);
}
@media (max-width: 1199px) {
    .positionColumn {
        max-width: 88px;
        flex-basis: 88px;
    }

    .userInfoColumn {
        max-width: calc(33% - 88px);
        flex-basis: calc(33% - 88px);
    }
}
@media (max-width: 767px) {
    .nameColumn {
        font-size: 16px;
    }
}
@media (max-width: 575px) {
    .positionColumn {
        max-width: var(--lb-positionColumn-width);
        flex-basis: var(--lb-positionColumn-width);
        padding-left: var(--lb-positionColumn-pl);
    }

    .userInfoColumn {
        width: var(--lb-row-avatar-size);
        min-width: calc(var(--lb-row-avatar-size) + 0.5em);
        padding-left: 0.5em;
    }
    .userInfoColumn.digitalTurbine.unlogged {
        display: var(--lb-userInfoColumn-display);
    }
    .loginBtn {
        font-size: 18px;
        padding: 7px 8px;
    }

    .nameColumn {
        font-size: 14px;
        flex: 1 0;
        padding-left: 0.8em;
    }

    .scoreColumn {
        font-size: 16px;
        padding-right: 1em;
        flex: 1 0;
        text-align: var(--lb-scoreColumn-text-align);
    }
}
/* Content of columns */
.position {
    width: 100%;
    height: 48px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 20px;
    color: #3854ab;
    background-color: #e1e7ef;
    border-radius: 0 8px 8px 0;
}
.lbRow.isUser .position {
    background-color: #ffffff;
}
.position.hasTrophy {
    color: white;
    background-color: transparent !important;
    padding-bottom: 1.5rem;
}
@media (min-width: 576px) {
    .position.hasTrophy {
        padding-bottom: 1rem;
    }

    .position.anonymous {
        width: auto;
        /* Move to start of avatar block, add avatar padding, add half of avatar width */
        left: calc(100% + 2rem + 24px);
    }
}
.trophyIcon {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
}
@media (max-width: 575px) {
    .trophyIcon {
        width: var(--lb-trophyIcon-width);
        height: auto;
    }
    .positionNumber.hasTrophy {
        padding-top: var(--lb-positionNumber-pt);
    }
}
.positionNumber {
    position: relative;
    z-index: 1;
}
.country {
    margin-bottom: auto;
    margin-left: 20%;
    display: inline-block;
    max-width: 24px;
    max-height: 16px;
}
@media (max-width: 575px) {
    .country {
        position: absolute;
        top: 0;
        left: 100%;
        margin: var(--lb-country-margin);
    }
}
.scoresTooltip {
    width: auto;
    color: #1e36ae;
    font-size: 16px;
}

.container {
    height: 72px;
    border-radius: 2px;
    background-image: linear-gradient(to bottom, #0a4aa5, #04387a 57%, #043778 95%);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.loginOrProfileButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 58px;
    border-radius: 2px;
    box-shadow: 0 4px 10px -4px #0a1874;
    background-color: #0048a5;
    font-weight: 600;
    color: white;
    margin-right: 10px;
    padding: 0 1em;
}

.loginOrProfileButton:hover {
    color: white;
    text-decoration: none;
    box-shadow: 0 4px 10px -4px #4966f8;
    background-color: #2663b2;
}

.image {
    max-width: 85px;
    align-self: flex-end;
}

:root {
    --ark-small-mobile-figma-below-599: 599px;
    --ark-small-mobile-figma-600: 600px;
    --ark-medium-mobile-figma-below-719: 719px;
    --ark-small-desktop-figma-below-1023: 1023px;
    --ark-small-desktop-figma-1024: 1024px;
    --ark-medium-desktop-figma-below-1359: 1359px;
    --ark-medium-desktop-figma-1360: 1360px;
    --ark-large-desktop-figma-1440: 1440px;
}

.subPlanWrapper {
    display: grid;
    margin-top: 1rem;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: var(--ark-base-grid-gap);
}

.typeBlock {
    background: rgba(237, 242, 250, 1);
    /* can be changed */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px;
    grid-column: 1/3;
}

.annualTypeBlock {
    background: rgba(227, 248, 241, 1);
}

.annualTypeBlock .typeName {
    color: var(--ark-main-green-figma);
}

.typeName {
    color: var(--ark-main-blue-figma);
    font-size: var(--ark-28-font-size);
    font-weight: 700;
    margin-bottom: 0.5rem;
}

.typePrice {
    color: var(--ark-primary-black-figma);
    font-size: var(--ark-24-font-size);
    font-weight: 700;
}

.typePeriod {
    color: var(--ark-primary-black-figma);
    font-size: var(--ark-18-font-size);
    font-weight: normal;
}

.typeDescription {
    display: grid;
    grid-column: 3/9;
}

.planDetailsList {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.planDetailsList li {
    display: flex;
    align-items: center;
}

.planDetailsList li p {
    color: var(--ark-primary-black-figma);
    font-size: var(--ark-18-font-size);
    line-height: var(--ark-20-line-height);
}

.planDetailsList li svg {
    min-width: 34px;
}

.highlight {
    color: var(--ark-main-nataline-blue-figma);
    font-weight: 700;
}

.switchLink.__gems {
    margin-left: 14.5rem;
}

.popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: rgba(1, 1, 1, 0.3);
    overflow: hidden;
    z-index: 99999;
}

.popupOverlay > * {
    width: auto;
    height: auto;
    max-width: 50%;
    max-height: 90%;
    background-color: var(--ark-primary-white-figma);
    overflow-y: auto;
    padding: var(--ark-spacing-l);
    border-radius: var(--ark-spacing-s);
}

/*****Media *******/

@media (max-width: 1023px) {
    .planDetailsList li p {
        font-size: var(--ark-16-font-size);
        line-height: var(--ark-18-line-height);
    }

    .planDetailsList li {
        margin-top: 4px;
    }

    .subPlanWrapper {
        grid-template-columns: repeat(5, 1fr);
    }

    .typeBlock {
        grid-column: 1/3;
    }

    .typeDescription {
        grid-column: 3/6;
    }

    .switchLink.__gems {
        margin-left: 0;
    }

    .popupOverlay > * {
        max-width: 100%;
    }
}

@media (max-width: 719px) {
    .typeName {
        font-size: var(--ark-24-font-size);
    }

    .typePrice {
        font-size: var(--ark-18-font-size);
    }

    .typePeriod {
        font-size: var(--ark-14-font-size);
    }

    .subPlanWrapper {
        grid-gap: var(--ark-base-mobile-grid-gap);
    }
}

@media (max-width: 599px) {
    .subPlanWrapper {
        grid-template-columns: repeat(4, 1fr);
    }

    .typeBlock {
        grid-column: 1/5;
        padding: 16px;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
    }

    .typeDescription {
        grid-column: 1/5;
    }

    .typeName {
        font-size: var(--ark-28-font-size);
        margin-bottom: 0;
    }

    .typePrice {
        font-size: var(--ark-24-font-size);
    }

    .typePeriod {
        font-size: var(--ark-18-font-size);
    }

    .switchLink {
        display: block;
        font-size: 14px;
        margin-top: 8px;
    }
}

.flexColumn {
    display: flex;
    flex-direction: column;
}
.buttons {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 100%;
    border-top: 1px solid #8ca2af;
    background: #f5f5f5;
    border-radius: 0 0 8px 8px;
    padding: 8px;
}
.buttons.fromShopModal {
    max-width: 540px;
    margin: 0 auto;
}
.oAuthLoginButton,
.oAuthLoginButtonIframe {
    height: 40px;
}
.oAuthLoginButtonIframe {
    --desktop-width: 210px;
    --mobile-width: 200px;
    width: var(--desktop-width);
    box-shadow: none;
    border: 0;
}
.forgotPasswordRow {
    display: flex;
    justify-content: center;
    height: 45px;
    align-items: center;
    order: 4;
}
.forgotPasswordRow.eagle {
    order: 3;
    height: auto;
    margin: 18px 0;
    justify-content: flex-end;
}
@media (max-width: 768px) {
    .buttons {
        flex-direction: column;
        align-items: stretch; /* ensures the child elements stretch to fill the container */
        justify-content: center;
        align-items: center;
    }

    .oAuthLoginButton,
    .oAuthLoginButtonIframe {
        width: var(--mobile-width);
        height: 50px;
    }
}
.show {
    display: block;
}
.hide {
    display: none;
}

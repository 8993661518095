:root {
    --ark-small-mobile-figma-below-599: 599px;
    --ark-small-mobile-figma-600: 600px;
    --ark-medium-mobile-figma-below-719: 719px;
    --ark-small-desktop-figma-below-1023: 1023px;
    --ark-small-desktop-figma-1024: 1024px;
    --ark-medium-desktop-figma-below-1359: 1359px;
    --ark-medium-desktop-figma-1360: 1360px;
    --ark-large-desktop-figma-1440: 1440px;
}

.fullWidth {
    width: 100%;
}

.input {
    border: 2px solid var(--ark-primary-black-figma);
    height: 48px;
    width: 100%;
    border-radius: var(--ark-border-radius);
    padding-left: 12px;
    transition: all 0.5s;
    text-overflow: ellipsis;
    padding-right: 34px; /* done to avoid overlapping the close icon (34px = width of close icon) */
}

.inputWrapper {
    position: relative;
}

.cardInput {
    padding-right: 0;
    width: 420px;
}

.cardInput div {
    height: 100%;
}

.creditCardIcon {
    position: absolute;
    right: 10px;
    max-width: 50px;
    top: 0;
    height: 100%;
}

.cvvElement {
    max-width: 198px;
}

.creditExpirationDateInput {
    max-width: 72px;
}

.input:focus {
    border: 2px solid var(--ark-primary-blue-figma);
}

.successful {
    border-color: var(--ark-primary-green);
}

.error {
    border-color: var(--ark-primary-red);
}

.closeIcon {
    position: absolute;
    right: 0;
    top: calc(50% - 17px);
    z-index: 1;
}

.closeIcon:hover {
    cursor: pointer;
}

.closeIcon g g path {
    stroke: var(--ark-primary-asbestos-figma);
}

.validationBlock {
    position: absolute;
    display: flex;
    align-items: center;
    min-width: 250px;
}

.validationBlock svg {
    min-width: 20px;
    min-height: 20px;
}

.validationBlock p {
    color: var(--ark-primary-red);
    font-size: var(--ark-14-font-size);
}

.input:disabled {
    border-color: inherit;
}

.label {
    color: var(--ark-primary-black-figma);
    font-size: var(--ark-18-font-size);
    line-height: var(--ark-26-line-height);
}

.slash {
    margin: 0 1.5rem;
    align-self: center;
}

@media (max-width: 1359px) {
    .cardInput {
        width: 310px;
    }
}

@media (max-width: 1023px) {
    .cardInput {
        width: 100%;
    }
    .slash {
        margin: 0 0.5rem;
    }
}

@media (min-width: 600px) and (max-width: 1023px) {
    .cvvValidation {
        min-width: 180px;
    }
}

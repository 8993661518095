:root {
    --ark-small-mobile-figma-below-599: 599px;
    --ark-small-mobile-figma-600: 600px;
    --ark-medium-mobile-figma-below-719: 719px;
    --ark-small-desktop-figma-below-1023: 1023px;
    --ark-small-desktop-figma-1024: 1024px;
    --ark-medium-desktop-figma-below-1359: 1359px;
    --ark-medium-desktop-figma-1360: 1360px;
    --ark-large-desktop-figma-1440: 1440px;
}

.inputWrapper {
    position: relative;
    display: grid;
    grid-template-columns: 200px -webkit-max-content 200px;
    grid-template-columns: 200px max-content 200px;
    grid-gap: var(--ark-spacing-l);
    align-items: center;
    max-width: 868px;
    margin: 0 auto 36px;
}

.input {
    width: 420px;
}

.select {
    width: 420px !important;
}

[dir="ltr"] .labelWrapper {
    text-align: right;
}

[dir="rtl"] .labelWrapper {
    text-align: left;
}

.labelWrapper {
    justify-self: end;
}

.label {
    text-transform: uppercase;
    font-size: var(--ark-18-font-size);
}

.description {
    color: var(--ark-primary-asbestos-figma);
    font-size: var(--ark-14-font-size);
    /* min-width: 200px; */
    line-height: 19px;
    justify-self: start;
}

.inputBlock {
    display: flex;
    position: relative;
}

.minWidth {
    width: 100%;
}

.notifyWrapper {
    display: flex;
    align-items: center;
}

.notifyWrapper svg {
    position: relative;
}

.notifyWrapper p {
    min-width: auto;
}

@media (max-width: 1359px) {
    .input {
        width: 310px;
    }

    .select {
        width: 310px !important;
    }

    .inputWrapper {
        grid-template-columns: 145px -webkit-max-content auto;
        grid-template-columns: 145px max-content auto;
    }

    [dir="ltr"] .label {
        text-align: right;
    }

    [dir="rtl"] .label {
        text-align: left;
    }
}

@media (max-width: 1023px) {
    .inputWrapper {
        grid-template-columns: auto;
        grid-gap: 0.5rem;
        margin-bottom: 1.5rem;
    }

    .input {
        width: 100%;
    }

    .select {
        width: 100% !important;
    }

    .labelWrapper {
        display: flex;
        align-items: center;
        justify-self: start;
    }

    .labelWrapper svg {
        position: relative;
        top: 1px;
    }

    .label {
        font-size: var(--ark-16-font-size);
    }
}

.wrapper {
    width: 100%;
    height: 69px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
}

.notificationBar {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 16px 24px;
    color: var(--new-white-color);
}

.error {
    background-color: var(--ark-hover-dark-red-figma);
}

.success {
    background-color: var(--ark-extended-semantic-success);
}

.icon {
    min-width: 14px;
    max-width: 14px;
    height: 24px;
    margin-right: 12px;
}

.avatarPicture {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
/* safari fix */
.avatarPicture img {
    width: 100%;
    height: 100%;
}
.borderRadius {
    border-radius: 50%;
}
.eagleAvatarWrapper {
    background-color: rgb(0, 66, 187);
}

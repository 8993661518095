:root {
    --shop-item-width: 228px;
    --shop-item-height: 252px;
}
.shopItemWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 228px;
    width: var(--shop-item-width);
    height: 252px;
    height: var(--shop-item-height);
    padding: 16px 22px;
    background: var(--new-header-color-blue-secondary);
    border-radius: 8px;
}
.shopItemWrapper.withRibbon {
    border: 3.5px solid var(--gray-blue-color);
    background: url('https://arenacloud.cdn.arkadiumhosted.com/arena51-assets/canary/images/ShopItemWithLabel.png');
}
.gemIcon {
    margin-right: 8px;
}
.gemsAmount {
    display: flex;
    align-items: center;
    font-size: 22px;
    margin: 0;
    color: var(--inverted-color);
}
.gemsAmount span {
    margin-left: 8px;
}
.imageAndRibbonWrapper.withRibbon img {
    margin-left: -48px;
}
.gemImage {
    max-height: 133px;
}
.ribbon {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(calc(-50% + 20px));
    width: 118px;
    height: 111px;
    font-size: 18px;
    line-height: 20px;
    font-weight: 800;
    padding: 0 24px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--eagle-input-border);
    --text-shadow: var(--new-white-color) 0px 0px 1px;
    text-shadow: var(--text-shadow), var(--text-shadow), var(--text-shadow), var(--text-shadow), var(--text-shadow),
        var(--text-shadow);
    -webkit-font-smoothing: antialiased;
    /* maybe will be changed by label url from recurly like a gem item*/
    background: url('https://arenacloud.cdn.arkadiumhosted.com/arena51-assets/canary/images/ShopLabel.png');
    background-size: cover;
    background-repeat: no-repeat;
}
/* @media screen and (max-width: 768px) {
    :root {
        --shop-item-width: 228px;
        --shop-item-height: 252px;
    }
} */
@media screen and (max-width: 540px) {
    :root {
        --shop-item-width: 136px;
        --shop-item-height: 158px;
    }
    .gemImage {
        height: 67px;
    }

    .gemsAmount {
        font-size: 16px;
    }

    .ribbon {
        width: 71px;
        height: 71px;
        font-size: 13px;
        line-height: 14px;
    }
}

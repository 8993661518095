:root {
    --xs-bp: 0;
    --sm-bp: 576px;
    --md-bp: 768px;
    --lg-bp: 1025px;
    --xl-bp: 1200px;
    --xxl-bp: 1370px;

    --xs-max-bp: 575px;
    --sm-max-bp: 767px;
    --md-max-bp: 1024px;
    --lg-max-bp: 1199px;
    --xl-max-bp: 1369px;
    --xxl-max-bp: 1500px;
}
.container {
    margin: 2em 0;
}
.title.__override {
    color: #3b4148;
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 0.83em;
    margin-bottom: 0.5rem;
}
.tabList {
    padding: 1em 2em 0 2em;
    background-color: #e1e7ef;
}
.loaderWrapper {
    padding-top: 24px;
}
@media (max-width: 575px) {
    .tabList {
        padding: 1em 1em 0 1em;
        margin: 0 -calc(var(--gutter)/2);
    }
}
@media (max-width: 767px) {
    .tabWrapper {
        padding: 0 4px;
        display: flex;
    }
}
.content {
    min-height: 4em;
}
@media (max-width: 575px) {
    .content {
        margin: 0 -calc(var(--gutter)/2);
        min-height: auto;
    }
}

.Col {
    margin: 30px 0;
    padding-left: var(--global-side-gutters);
    padding-right: var(--global-side-gutters);
    --padd-width: 81px;
    --icon-height: 68px;
    position: relative;
    background: #fff;
    box-shadow: 0 0 1px -8px rgba(0, 0, 0, 0.6), 0 2px 4px rgba(0, 0, 0, 0.4);
    max-width: calc(100% - 16px);
    display: flex;
    flex-direction: column;
    margin-left: 8px;
}

@media (max-width: 767px) {
    .Col {
        margin-left: 8px;
        margin-right: 8px;
        max-width: calc(100% - 16px);
        margin-bottom: 15px;
    }
}

.BadgesBlock {
    width: 100%;
    min-width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
}

.BadgesBlock_title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    height: auto;
    line-height: 54px;
    flex-wrap: wrap;
    position: relative;
    margin: 0;
    padding: 0 17px;
    border-bottom: 1px solid #ccc;
    right: var(--global-side-gutters);
    width: calc(100% + var(--global-side-gutters)*2);
    max-width: calc(100% + var(--global-side-gutters)*2);
}

.BadgesBlock_title span:before,
.Badge_title {
    content: 'NEW';
    font-family: 'Nunito Sans', Arial;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 22px;
    background: linear-gradient(314.74deg, #c28c2b 12.84%, #e6c14e 48.8%, #c28c2b 84.98%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 0.5px #e6c14e;
    text-fill-color: transparent;
    margin-bottom: 0;
}

.BadgesBlock_title:nth-child(2):before {
    content: "Congratulations, you've earned your first badge!";
    font-family: 'Nunito Sans', Arial;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #333333;
    display: block;
    width: calc(100% + 40px);
    position: relative;
    right: 25px;
    height: 30px;
    padding: 14px 0 0 25px;
}

.BadgesBlock_title svg {
    margin-right: 13px;
    width: 22px;
    height: auto;
}

.BadgesWrapper {
    width: 100%;
    padding: 0;
    margin: 15px 0 25px 0;
}

.Badge {
    padding: 8px 0;
    margin-bottom: 20px;
}

.Badge_info,
.Badge {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
}

.Badge_info {
    flex-direction: column;
    align-content: center;
}

.Badge_info img {
    height: var(--icon-height);
    width: var(--icon-height);
}

.Badge_padded {
    width: var(--padd-width);
    min-width: var(--padd-width);
    height: var(--padd-width);
    max-height: var(--padd-width);
    overflow: hidden;
    margin-right: 14px;
    border-radius: calc(var(--padd-width)/2);
    background: linear-gradient(314.74deg, #c28c2b 12.84%, #e6c14e 48.8%, #c28c2b 84.98%);
}

.Badge_icon {
    display: block;
    margin: auto;
    margin-top: 50%;
    transform: translate(0, -50%);
    width: var(--icon-height);
    height: var(--icon-height);
    border-radius: calc(var(--icon-height)/2);
    border: 1.5px solid white;
}

.Badge_title {
    -webkit-text-stroke: initial;
}

.Badge_descr {
    font-family: 'Nunito Sans', Arial;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 0;
}

.Badge_title {
    font-family: Arial;
    font-weight: bold;
    font-size: 18px;
}

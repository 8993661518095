/* LAYOUT */
:global(#root) {
    --new-header-custom-top: 0px;
    --new-header-height: 64px;
    --new-header-navmenu-width: 64px;
    --new-header-search-width: 64px;
    --new-header-login-width: 64px;
    --new-header-burger-padding: 21.33px;
    --new-header-burger-margin-left: 0;
    --new-header-burger-width: 47.99px;
    --new-header-gametile-radius: 7.5px;
    --new-header-border-width: 1px;
    --new-header-search-gametile-width: 235px;
    --new-header-color-border: transparent;
    --new-header-color-background: #f9f9f9;
    --new-header-color-border-active: #c4c4c4;
    --new-header-search-padding: 11px;
    --new-header-navmenu-padding: 31.68px;
    --new-header-navitem-padding: 37.6px;
    --new-header-burger-height: 23.99px;
    --new-header-burger-dot: 5.33px;
    --new-header-navitem-minified-width: 48px;
    --new-header-scrollbar-width: 3px;
    --new-header-med-font-size: 16px !important;
    --new-header-color-blue: #0b103d;
    --new-header-color-black: #231f20;
    --new-header-color-gray: #6c6c6c;
    --new-header-color-hover: #bbf2f2;
    --new-header-color-blue-primary: #0042bb;
    --new-header-color-blue-secondary: #00328d;
    --new-header-search-result-mt: 20px;
    --new-header-transition: all 0.3s ease-in-out;
}
.NewHeaderRow {
    /*position: fixed;*/
    position: -webkit-sticky;
    position: sticky;
    top: var(--new-header-custom-top);
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    background-color: var(--new-header-color-background);
    border-bottom: 1px solid var(--new-header-color-border-active);
    box-sizing: content-box;
    z-index: 10004;
}
.Announce {
    width: 100%;
    height: var(--new-header-height);
    background-color: var(--new-header-color-blue-primary);
}
.Navmenu,
.Search,
.Login {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    border: 1px solid var(--new-header-color-border);
    margin: 0;
    z-index: 50;
    transform: translateZ(0);
    -webkit-user-select: none;
            user-select: none;
}
.Navmenu {
    width: var(--new-header-navmenu-width);
    height: var(--new-header-height);
    z-index: 100;
}
.BurgerCatText,
.LogoLink {
    display: none;
}
.Navmenu {
    /*
    ,
.NavList */
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    z-index: 121;
}
.Navmenu:before,
.Navmenu:after {
    content: '';
    display: none;
    position: absolute;
    width: calc(100vw - var(--new-header-navmenu-width));
    height: var(--new-header-height);
    overflow: hidden;
    left: var(--new-header-navmenu-width);
    top: 0;
    background-color: var(--new-header-color-black);
    border: 0;
    opacity: 0.3;
    z-index: 121;
}
.Navmenu:after {
    left: 0;
    top: var(--new-header-height);
    width: 100vw;
    height: calc(100vh - var(--new-header-custom-top) - var(--new-header-height));
}
.Navmenu.__opened:before,
.Navmenu.__opened:after {
    display: block;
}
.NavList {
    height: calc(100vh - var(--new-header-custom-top) - var(--new-header-height));
    width: 100vw;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    padding: 41.28px var(--new-header-burger-margin-left);
    position: absolute;
    left: -1px;
    top: calc(var(--new-header-height) - 2px);
    margin: 0;
    z-index: 122;
    overflow-x: hidden;
    overflow-y: auto;
    box-shadow: 3px 14px 14px rgba(3, 15, 36, 0.15);
    background-color: var(--new-header-color-background);
    border: 1px solid var(--new-header-color-background);
    transition: var(--new-header-transition);
    transform: translate(-100%) translateZ(0);
}
.__default .NavList {
    top: calc(var(--new-header-height) - 1px);
    height: calc(100vh - var(--new-header-height));
    overflow-x: hidden;
    overflow-y: auto;
}
.__default .NavList .LogoLink {
    display: none;
}
.__BodyNewHeaderBurgerOpened {
    height: 100vh;
    overflow: hidden;
}
.__BodyNewHeaderBurgerOpened :global(.main-content) {
    margin-top: calc(var(--new-header-height) + var(--new-header-border-width)) !important;
}
.__BodyNewHeaderBurgerOpened .NewHeaderRow {
    position: fixed;
}
.__BodyNewHeaderBurgerOpened .NavList {
    transform: translate(0) translateZ(0);
}
.Login {
    width: var(--new-header-login-width);
    height: var(--new-header-height);
}
.Search {
    width: var(--new-header-search-width);
    height: var(--new-header-height);
    margin: 0 0 0 auto;
}
.NewHeaderRow.gems .Search {
    margin: 0;
}
.Search input {
    max-width: 0;
    -webkit-user-select: initial;
            user-select: initial;
    padding: 0 7px 0 14px;
}
.Search :global(search-component .search .cross-button-hidden) {
    display: none;
}
.__focused .Search :global(search-component .search .cross-button-hidden) {
    display: flex;
}
.__focused .Search {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 50;
    background-color: var(--new-header-color-background);
    padding: 0 20px 0 16px;
    width: 100%;
    max-width: 100%;
    margin-left: 0;
}
.__focused .Search :global(.search) {
    width: 100%;
}
.Search {
    transition: none !important;
    position: relative;
}
.__focused .Search {
    transition: all 0.41s ease-out !important;
}
.Search :global(.search-results) {
    width: 100vw;
    left: calc(-1px - var(--new-header-navmenu-width) - var(--new-header-search-padding));
}
.Login {
    width: var(--new-header-login-width);
    border-left-color: transparent !important;
}
.NewHeaderRow.gems {
    --new-header-login-width: 192px;
}
.NewHeaderRow.gems .Login :global(.logined-state) {
    border-left: 1px solid var(--new-header-color-border-active);
}
.__BodyNewHeaderBurgerOpened :global(#root) {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}
@media (max-width: 192px) {
    .Login {
        margin-left: auto;
    }
}
@media (min-width: 225px) {
    :global(#root) {
        --new-header-burger-margin-left: 5px;
    }
}
@media (min-width: 321px) {
    :global(#root) {
        --new-header-navmenu-width: 192px;
        --new-header-burger-margin-left: 5px;
        --new-header-navmenu-side-padding: 24px;
    }
    .NewHeaderRow.gems {
        --new-header-navmenu-width: 64px;
    }
    .LogoBurger,
    .Burger {
        width: 100%;
    }
    .__default .Burger {
        width: var(--new-header-burger-width);
    }
    .NewHeaderRow.gems .__default .LogoLink,
    .NewHeaderRow.gems .BurgerCatText {
        display: none;
    }
    .__default .LogoLink,
    .BurgerCatText {
        display: block;
        width: calc(100% - var(--new-header-burger-width));
    }
    .__default .BurgerCatText {
        display: none;
    }
    .NavList {
        padding: 41.28px var(--new-header-navmenu-side-padding);
    }
}
@media (max-width: 551px) {
    .__focused .Search {
        z-index: 211;
    }
}
@media (max-width: 768px) {
    .NewHeaderRow.gems.__focused .Search {
        z-index: 211;
    }
}
/* 64*6=384 because we have:
burger - 64px
logo - 64px
search - 64px
gems - 128px
login - 64px
*/
@media (min-width: 384px) {
    .NewHeaderRow.gems .__default .LogoLink,
    .NewHeaderRow.gems .BurgerCatText {
        display: block;
        width: calc(100% - var(--new-header-burger-width));
    }
    .NewHeaderRow.gems {
        --new-header-navmenu-width: 128px;
    }
    .NewHeaderRow.gems .Search {
        margin: 0 0 0 auto;
    }
}
@media (min-width: 551px) {
    :global(#root) {
        --new-header-navmenu-width: 224px;
        --new-header-login-width: 82px;
        --new-header-search-padding: 22px;
    }

    .NewHeaderRow.gems {
        --new-header-navmenu-width: 224px;
    }

    .NewHeaderRow:not(.gems) .Search {
        width: calc(100% - var(--new-header-navmenu-width) - var(--new-header-login-width));
        border-right-color: var(--new-header-color-border-active);
        border-left-color: var(--new-header-color-border-active);
        padding: 0 var(--new-header-search-padding);
    }
    .Search :global(.search-results) {
        width: 100vw;
        left: calc(-1px - var(--new-header-search-padding));
    }
    .NewHeaderRow:not(.gems) .NavList {
        width: var(--new-header-navmenu-width);
    }
    .NewHeaderRow:not(.gems) .Search input {
        width: 100%;
        display: flex !important;
        max-width: initial !important;
    }
    .NewHeaderRow:not(.gems).__focused .Search {
        position: relative !important;
        width: calc(100% - var(--new-header-navmenu-width) - var(--new-header-login-width));
    }
}
@media (min-width: 552px) {
    .NewHeaderRow.gems .Search input {
        padding: 0;
    }
}
@media (max-width: 767px) {
    .NavList {
        padding-bottom: 200px; /* to fix ios safari bottom navbar */
    }
}
@media (max-width: 768px) {
    .NewHeaderRow .Search :global(.search) {
        border-left: 1px solid var(--new-header-color-border-active);
        border-right: 1px solid var(--new-header-color-border-active);
    }
}
@media (min-width: 769px) {
    :global(#root) {
        /* we have to minimize it since this resolution*/
        --new-header-navmenu-width: 96px;
        --new-header-med-font-size: 20px;
        --new-header-search-padding: 22px;
    }

    .NewHeaderRow.gems {
        --new-header-navmenu-width: 224px;
        --new-header-login-width: 192px;
    }

    .NewHeaderRow.gems .Search {
        width: calc(100% - var(--new-header-navmenu-width) - var(--new-header-login-width));
        border-right-color: var(--new-header-color-border-active);
        border-left-color: var(--new-header-color-border-active);
        padding: 0 var(--new-header-search-padding);
    }
    .NewHeaderRow.gems .Search :global(.search-results) {
        width: 100vw;
        left: calc(0px - var(--new-header-search-padding) - var(--new-header-navmenu-width));
    }
    .NewHeaderRow.gems .NavList {
        width: var(--new-header-navmenu-width);
    }
    .NewHeaderRow.gems .Search input {
        width: 100%;
        display: flex !important;
        max-width: initial !important;
    }
    .NewHeaderRow.gems.__focused .Search {
        position: relative !important;
        width: calc(100% - var(--new-header-navmenu-width) - var(--new-header-login-width));
    }
}
@media (min-width: 1025px) {
    :global(#root) {
        --new-header-login-width: 224px;
        --new-header-med-font-size: 16px !important;
        --new-header-search-padding: 44px;
    }

    .NewHeaderRow.gems {
        --new-header-navmenu-width: 96px;
    }

    .Burger,
    .__default .Burger {
        display: none;
    }
    .LogoBurger {
        display: none !important;
    }
    .NavList,
    .__default .NavList {
        padding: 41.28px var(--new-header-navmenu-side-padding);
        transform: translate(0) translateZ(0);
        max-width: var(--new-header-navmenu-width);
        height: calc(100vh - var(--new-header-custom-top) + 2px);
        left: -3px;
        top: -2px;
        border-left: 1px solid var(--new-header-color-border-active);
        border-right: 1px solid var(--new-header-color-border-active);
    }
    .__default .NavList {
        height: 100vh;
    }
    .__default .NavList {
        padding-top: calc(32px - var(--new-header-navitem-padding)/2);
    }
    .__default .NavList .LogoLink {
        display: flex;
    }
    .NewHeaderRow.gems .__default .NavList .LogoLink {
        width: inherit;
    }

    .NewHeaderRow.gems .Search :global(.search-results) {
        left: calc(0px - var(--new-header-search-padding));
    }
    .NewHeaderRow.gems .Login :global(.user-info-button .user-info-username) {
        display: none;
    }
}
@media (min-width: 551px) and (max-width: 1024px) {
    .NewHeaderRow:not(.gems) .Navmenu,
    .NewHeaderRow:not(.gems) .LogoBurger {
        width: 100%;
        max-width: 224px !important;
    }
    .NewHeaderRow:not(.gems) .Navmenu:before {
        left: 224px !important;
        width: calc(100vw - 224px);
    }
    .NewHeaderRow:not(.gems) .Search {
        width: 100% !important;
        max-width: calc(100% - var(--new-header-login-width) - 224px) !important;
    }

    .NewHeaderRow:not(.gems) .Search :global(.search-results) {
        left: calc(-224px - var(--new-header-search-padding));
        width: calc(100% + var(--new-header-search-padding)*2 - 224px) !important;
    }

    /* .Login.gems,
    .Search.gems {
        --new-header-login-width: 252px;
    } */
}
@media (min-width: 1367px) {
    :global(#root) {
        --new-header-navmenu-width: 96px;
        --new-header-login-width: 249px;
        /* --new-header-navmenu-width-minified: 96px; */
    }

    .NewHeaderRow.gems.unauthorized {
        --new-header-login-width: 249px;
    }

    .NewHeaderRow.gems {
        --new-header-login-width: 377px;
    }

    .NewHeaderRow.gems .Login :global(.user-info-button .user-info-username) {
        display: block;
    }
}
@media (min-width: 1920px) {
    :global(#root) {
        --new-header-navmenu-width: 316px;
    }

    .NewHeaderRow.gems {
        --new-header-navmenu-width: 316px;
    }
}
@media (min-width: 1921px) {
    .NewHeaderRow {
        left: calc(50vw - 960px);
        max-width: 1920px;
        border-left: 1px solid var(--new-header-color-border-active);
        border-right: 1px solid var(--new-header-color-border-active);
    }
}
@media (max-height: 800px) and (orientation: landscape) {
    :global(#root) {
        --new-header-height: 28px;
    }
}
/* DECORATIONS */
.Login a:hover,
.Login button:hover,
.Search :global(.search-results) a:hover,
.Search :global(.search-results) button:hover {
    background-color: var(--new-header-color-hover) !important;
    -webkit-user-select: none;
            user-select: none;
    transition: var(--new-header-transition);
}
.Login a:hover,
.Login button:hover {
    border-radius: 5px;
}
.Login :global(.login) {
    padding: 0 !important;
}
.Login :global(.login-button) {
    margin-bottom: 0 !important;
}
/* desktop */
.loginWidthWithGems {
    --new-header-login-width: 249px;
}

.checkboxDescription {
    display: flex;
    align-items: center;
    color: #545454;
    font-size: 12px;
    margin-bottom: 0;
}

.checkboxDescription input {
    margin-right: 4px;
}

.checkboxDescription.eagle {
    width: 100%;
    position: relative;
    padding-left: 35px;
    margin: 0 8px 8px 8px;
    cursor: pointer;
    font-size: 11px;
    line-height: 18px;
    color: var(--main-color);
    -webkit-user-select: none;
    user-select: none;
}

.checkboxDescription.eagle input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    margin-right: 0;
}

.checkboxDescription.error {
    color: var(--error-border);
}

/* Create a custom checkbox */

.checkmark.eagle {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 2px;
    border: 1px solid var(--main-color);
}

.checkmark.eagle.error {
    border: 1px solid var(--error-text);
}

/* When the checkbox is checked, add a blue background */

.checkboxDescription.eagle input:checked ~ .checkmark.eagle {
    background-color: var(--eagle-blue-color-darker);
}

/* Create the checkmark.eagle/indicator (hidden when not checked) */

.checkmark.eagle:after {
    content: '';
    position: absolute;
    display: none;
}

/* Show the checkmark.eagle when checked */

.checkboxDescription.eagle input:checked ~ .checkmark.eagle:after {
    display: block;
}

/* Style the checkmark.eagle/indicator */

.checkboxDescription.eagle .checkmark.eagle:after {
    left: 5px;
    top: 1px;
    width: 8px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

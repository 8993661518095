.arkadiumLogoMini.small,
.arkadiumLogoMini.smaller {
    --defaultWidthLogo: 96;
    /* increase by 20% because logo has padding inside itself */
    --smallWidthLogo: 56.4;
    --smallerWidthLogo: 34.8;

    margin-left: -10px;
}

.arkadiumLogoMini.small {
    zoom: calc(var(--smallWidthLogo)/var(--defaultWidthLogo));
}

.arkadiumLogoMini.smaller {
    zoom: calc(var(--smallerWidthLogo)/var(--defaultWidthLogo));
}

.container {
    display: inline-block;
    position: relative;
    cursor: pointer;
}

.tooltip {
    display: none;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    width: 270px;
    padding: 9px;
    margin-bottom: 8px;
    border: 1px solid #f2f2f2;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    background: #ffffff;
    font-size: 14px;
    font-weight: normal;
    text-transform: none;
    color: #343434;
    text-align: left;
    line-height: 1.4;
    z-index: 9999;
}

.isBottom {
    top: 100%;
    bottom: unset;
    margin-top: 0px !important;
}

.tooltipIsVisible .tooltip {
    display: block;
}

.boundedLeft {
    right: auto;
    transform: translate(0, 0);
}

.boundedRight {
    left: auto;
    transform: translate(0, 0);
}

/*arrow styles*/

.tooltipIsVisible:after,
.tooltipIsVisible:before {
    top: 0;
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    margin-top: -10px;
    z-index: 9999;
}

.tooltipIsVisible.isBottom:before {
/*    transform: translateY(53%) rotate(180deg);
    margin-top: 0;*/
}

.tooltipIsVisible.isBottom:after {
/*    transform: translateY(111%) rotate(180deg);
    margin-top: 0;*/
}

.tooltipIsVisible:after {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: #fff;
    border-width: 8px;
    margin-left: -8px;
}

.tooltipIsVisible:before {
    border-color: rgba(242, 242, 242, 0);
    border-top-color: #f2f2f2;
    border-width: 11px;
    margin-left: -11px;
}

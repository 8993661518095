:root {
    --xs-bp: 0;
    --sm-bp: 576px;
    --md-bp: 768px;
    --lg-bp: 1025px;
    --xl-bp: 1200px;
    --xxl-bp: 1370px;

    --xs-max-bp: 575px;
    --sm-max-bp: 767px;
    --md-max-bp: 1024px;
    --lg-max-bp: 1199px;
    --xl-max-bp: 1369px;
    --xxl-max-bp: 1500px;
}
.relatedGames {
    margin-top: 20px;
}
.title.__override {
    font-size: 32px;
    margin: 0 10px 0 0;
    line-height: 1.3;
    font-weight: 700;
}
.topRow {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 10px;
}
@media (max-width: 767px) {
    .title.__override {
        font-size: 20px;
    }
}
@media (max-width: 575px) {
    .title.__override {
        font-size: 16px;
        line-height: 1.7;
        margin: 0 5px 0 0;
    }
}

:root {
    --xs-bp: 0;
    --sm-bp: 576px;
    --md-bp: 768px;
    --lg-bp: 1025px;
    --xl-bp: 1200px;
    --xxl-bp: 1370px;

    --xs-max-bp: 575px;
    --sm-max-bp: 767px;
    --md-max-bp: 1024px;
    --lg-max-bp: 1199px;
    --xl-max-bp: 1369px;
    --xxl-max-bp: 1500px;
}
.container {
    margin-top: 45px;
    margin-bottom: 10px;
    background: #fff;
    padding: 13px;
    position: relative;
}
.level {
    width: 170px;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -100%);
    padding: 5px 0;
    border-radius: 5px 5px 0 0;

    color: #683304;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;

    background: #f4b200; /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
        to bottom,
        #f4b200 1%,
        #ffd800 11%,
        #ffc600 39%,
        #d88e00 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4b200', endColorstr='#d88e00',GradientType=0 ); /* IE6-9 */
}
.level:after,
.level:before {
    content: '';
    background: #f4b200; /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
        to bottom,
        #f4b200 1%,
        #ffd800 11%,
        #ffc600 39%,
        #d88e00 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4b200', endColorstr='#d88e00',GradientType=0 ); /* IE6-9 */
}
.level:after {
    width: 14px;
    height: 100%;
    position: absolute;
    top: 0;
    right: -8px;
    border-radius: 0 7px 0 0;
    transform: skew(15deg);
}
.level:before {
    width: 14px;
    height: 100%;
    position: absolute;
    top: 0;
    left: -8px;
    border-radius: 7px 0 0 0;
    transform: skew(-15deg);
}
.topRow {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
}
.currentXp,
.nextLevel {
    display: flex;
    align-items: center;
    width: 50%;
}
.currentXp {
    border-right: 2px solid #d8d8d8;
    text-align: left;
}
.nextLevel {
    text-align: right;
    justify-content: flex-end;
}
.xpVal {
    color: #004db3;
    font-size: 2.5em;
}
.xpText {
    color: #717171;
    font-size: 1em;
    text-transform: uppercase;
    margin: 0 10px;
}
.xpText span {
    color: #004db3;
    white-space: nowrap;
}
@media (max-width: 575px) {
    .container {
        padding: 7px 5px;
    }

    .currentXp,
    .nextLevel {
        flex-direction: column;
        text-align: center;
    }

    .xpVal {
        order: 1;
    }
    .xpText {
        order: 2;
        font-size: 0.8em;
        margin: 0 5px;
    }

    .topRow {
        font-size: 11px;
    }
}

.afterSignContentWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    padding: 0 20px;
    margin-top: 30px;
}

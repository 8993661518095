:root {
    --ark-small-mobile-figma-below-599: 599px;
    --ark-small-mobile-figma-600: 600px;
    --ark-medium-mobile-figma-below-719: 719px;
    --ark-small-desktop-figma-below-1023: 1023px;
    --ark-small-desktop-figma-1024: 1024px;
    --ark-medium-desktop-figma-below-1359: 1359px;
    --ark-medium-desktop-figma-1360: 1360px;
    --ark-large-desktop-figma-1440: 1440px;
}

.wrapper {
    grid-column: 1/5;
}

.paymentDesc {
    padding-left: 24px;
}

.paymentDesc ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.paymentDesc strong {
    font-size: var(--ark-18-font-size);
}

.paymentDesc strong span {
    padding-right: 4px;
}

.infoBlockSingleIcon {
    justify-content: center;
    align-items: center;
    padding-left: 0;
}

/*****Media *******/

@media (max-width: 1023px) {
    .wrapper {
        grid-column: 1/3;
    }

    .paymentDesc {
        padding-left: 0;
        padding-top: 4px;
    }

    .paymentType svg {
        width: 80px;
    }
}

@media (max-width: 719px) {
    .paymentType svg {
        width: 60px;
    }
}

@media (max-width: 599px) {
    .wrapper {
        grid-column: 1/5;
        margin-bottom: 16px;
    }

    .paymentType svg {
        width: 100px;
    }
}

:root {
    --xs-bp: 0;
    --sm-bp: 576px;
    --md-bp: 768px;
    --lg-bp: 1025px;
    --xl-bp: 1200px;
    --xxl-bp: 1370px;

    --xs-max-bp: 575px;
    --sm-max-bp: 767px;
    --md-max-bp: 1024px;
    --lg-max-bp: 1199px;
    --xl-max-bp: 1369px;
    --xxl-max-bp: 1500px;
}
.Base {
    text-transform: uppercase;
    outline: none;
    border: none;
    color: #fff;
    font-weight: 600;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    background-color: transparent;
    padding: 0;
    line-height: 1;
    transition: background-color 0.2s;
    border-radius: 2px;
}
.Base:focus {
    outline: none;
}
.PrimaryButton {
    padding: 11px 24px 9px;
    font-size: 20px;
    background-color: #0032d4;
    white-space: nowrap;
}
.PrimaryButton:hover {
    background-color: #005aff;
}
.PrimaryButton:disabled {
    background-color: #7c8693;
}
.primaryBigButton {
    width: 100%;
    max-width: var(--game-end-play-again-button-width);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 52px;
    font-family: var(--game-end-play-again-button-font);
    font-size: var(--game-end-play-again-button-fs);
    font-weight: var(--game-end-play-button-font-weight);
    background: var(--game-end-play-again-button-bg);
    color: var(--game-end-play-again-button-text);
    margin-bottom: 5px;
    border-radius: var(--game-end-br);
}
.primaryBigButton:hover {
    background-color: var(--game-end-play-again-button-bg);
}
.primaryBigButton:disabled {
    background-color: var(--gray-medium-color);
    color: var(--main-color);
    cursor: not-allowed;
}
.buyButton {
    width: min(192px, 100%);
    height: 46px;
    padding: 9px 0;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    color: var(--new-white-color);
    background-color: var(--green-medium-color);
    border-radius: 24px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}
.buyButton:hover {
    background-color: var(--green-medium-darker-color);
}
@media (max-width: 767px) {
    .primaryBigButton {
        width: 65%;
        height: 35px;
        margin-bottom: 2px;
    }
}
@media screen and (max-width: 540px) {
    .buyButton {
        width: 110px;
        height: 27px;
        font-size: 13px;
        padding: 6px 0;
    }
}

.resetPasswordRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
    margin-bottom: 16px;
}
.resetPasswordRow.eagle {
    margin-top: 20px;
    margin-bottom: 30px;
}
.resetPasswordRow button {
    min-width: unset;
    flex-basis: 50%;
    text-decoration: none;
}
.captcha {
    display: flex;
    justify-content: center;
}
@media (max-width: 768px) {
    .resetPasswordRow {
        display: block;
    }
    .resetPasswordRow.eagle {
        margin-top: 0;
        margin-bottom: 10px;
    }
    .resetPasswordRow.eagle > button:first-child {
        margin-bottom: 25px;
        margin-top: 10px;
    }
}
.recaptchaContainer{
    margin-top: 20px;
    min-height: 200px;
}
.show {
    display: block;
}
.hide {
    display: none;
}
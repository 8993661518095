.shopErrorWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--new-white-color);
}

.shopErrorTitle {
    max-width: 430px;
    font-size: 26px;
    line-height: 40px;
    font-weight: 700;
    margin-bottom: 32px;
}

.shopErrorTitle.mobile {
    max-width: 360px;
    font-size: 20px;
    line-height: 28px;
}

.shopErrorImage {
    margin: 32px 0;
    height: 254px;
}

.shopErrorImage.mobile {
    height: 110px;
    margin: 26px 0;
}

.shopErrorTryAgainButton {
    margin-bottom: 26px;
}

.shopErrorBackButton {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    color: var(--new-white-color);
}

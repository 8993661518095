:root {
    --xs-bp: 0;
    --sm-bp: 576px;
    --md-bp: 768px;
    --lg-bp: 1025px;
    --xl-bp: 1200px;
    --xxl-bp: 1370px;

    --xs-max-bp: 575px;
    --sm-max-bp: 767px;
    --md-max-bp: 1024px;
    --lg-max-bp: 1199px;
    --xl-max-bp: 1369px;
    --xxl-max-bp: 1500px;
}
.starIcon,
.refreshIcon,
.backIcon,
.closeIcon,
.settingsIcon,
.contactUsIcon,
.helpIcon,
.featureIcon,
.warningIcon,
.editIcon,
.landmarkIcon,
.eyeIcon,
.historyIcon,
.loaderIcon,
.nicknameIcon,
.emailIcon,
.passwordIcon,
.playCircleIcon,
.signOutIcon {
    width: 24px;
    height: 24px;
}
.isMirrored {
    transform: rotateY(180deg);
}
.arrowIcon {
}
.personIcon {
    width: 34px;
    height: 34px;
}
.dailyIcon {
}
.weeklyIcon {
}
.monthlyIcon {
}
.trophyYouIcon {
    width: 53.323px;
    height: 47.867px;
}
.trophyIcon {
    width: 53.323px;
    height: 47.867px;
}
@media (max-width: 767px) {
    .trophyIcon {
        width: 30px;
        height: 30px;
    }
}
.trophyIcon.first {
    color: #ffc600;
}
.trophyIcon.second {
    color: #99a3b2;
}
.trophyIcon.third {
    color: #d17b3c;
}
.coinsIcon {
    width: 30px;
    height: 30px;
    color: #ffc600;
}
.eyeCrossedIcon {
    width: 75px;
    height: 75px;
}
.logoIcon {
    width: 203px;
    height: auto;
}
.lockIcon {
    width: 17px;
    height: 17px;
}
.questionIcon {
}
.infoIcon {
    width: 24px;
    height: 24px;
    color: #6a6a6a;
}
.webGameIcon {
    box-sizing: content-box;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 50%;
    border: 10px solid #fff;
    width: 36px;
}
.eyeIconEagle {
    width: 27px;
    height: 22px;
    cursor: pointer;
}
.checkmarkIcon {
    width: 12px;
    height: 8px;
    margin-right: 8px;
}
.arrowStickWhiteIcon {
    width: 12px;
    height: 12px;
}

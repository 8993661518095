:root {
    --xs-bp: 0;
    --sm-bp: 576px;
    --md-bp: 768px;
    --lg-bp: 1025px;
    --xl-bp: 1200px;
    --xxl-bp: 1370px;

    --xs-max-bp: 575px;
    --sm-max-bp: 767px;
    --md-max-bp: 1024px;
    --lg-max-bp: 1199px;
    --xl-max-bp: 1369px;
    --xxl-max-bp: 1500px;
}
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 15px 20px !important;
    text-align: center;
}
.img {
    display: block;
    max-width: 100%;
}
.title {
    font-size: 36px;
    font-weight: 200;
    margin-top: 1.5rem;
}
.description {
    font-size: 45px;
    font-weight: 200;
    margin-top: 0;
}
.rootLink {
    display: inline-block;
    line-height: 1em;
    font-weight: 200;
    color: black;
    border-bottom: 1px solid;
}
.rootLink:hover,
    .rootLink:focus {
        text-decoration: none;
        border-bottom: 2px solid;
    }
@media screen and (max-width: 767px) {
    .title {
        font-size: 28px;
    }

    .description {
        font-size: 24px;
    }
}

.LocationBlock {
}

.LocationLabel {
    font-size: 16px;
    line-height: 1;
    margin-bottom: 10px;
    display: flex;
    align-items: flex-end;
}

.Icon {
    color: #3c63f2;
    margin-right: 5px;
}

.Tooltip {
    margin-left: 5px;
}

.Select {
    border: 1px solid #ccc;
    position: relative;
    max-width: 250px;
    width: 100%;
    height: 50px;
}

.Select.isOpened {
    border-color: #1e36ae;
}

.SelectBtn {
    width: 100%;
    display: flex;
    align-items: center;
}

.SelectBtn:focus {
    outline: none;
}

.SelectBtnIcon {
    min-width: 24px;
    max-width: 24px;
    color: #3c63f2;
    transform: rotateX(180deg);
}

.Dropdown {
    display: none;
    width: 100%;
    max-height: 300px;
    overflow: auto;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 9999;
    border: 1px solid #1e36ae;
    border-top: none;
    background: #fff;
    margin-left: -1px;
    box-sizing: content-box;
}

.isOpened .Dropdown {
    display: block;
}

.DropdownItem {
    font-size: var(--profile-location-dropdown-item-fs);
    flex-grow: 1;
    display: flex;
    height: 50px;
    padding: 0 10px;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    cursor: pointer;
}

.DropdownItem:last-child,
.SelectBtn .DropdownItem {
    border: none;
}

.DropdownImg {
    width: 20px;
    height: 15px;
    margin-right: 10px;
}

.container {
    margin: 3em 0 5em;
}

.title {
    font-size: 28px;
    margin-bottom: 0.5em;
}

.titleCapitalized {
    text-transform: capitalize;
}

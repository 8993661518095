:root {
    --xs-bp: 0;
    --sm-bp: 576px;
    --md-bp: 768px;
    --lg-bp: 1025px;
    --xl-bp: 1200px;
    --xxl-bp: 1370px;

    --xs-max-bp: 575px;
    --sm-max-bp: 767px;
    --md-max-bp: 1024px;
    --lg-max-bp: 1199px;
    --xl-max-bp: 1369px;
    --xxl-max-bp: 1500px;
}
.Container {
    width: 100%;
}
.tileCell {
    margin-bottom: 16px;
}
@media (max-width: 576px) {
    .hScrollMobile {
        overflow: scroll;
        /* Calc Negative margin to fill the rest of horizontal space */
        margin-right: calc(-50vw - -50%);
    }

    .hScrollMobile .gamesRow {
        flex-wrap: nowrap;
    }

    .hScrollMobile .tileCell {
        max-width: var(--tile-wrapper-width-mobile);
        flex: 0 0 auto;
    }
}
.tileCell.digitalTurbine {
    max-width: var(--tile-wrapper-width-mobile);
    padding: var(--tile-wrapper-padding);
    margin: var(--games-list-tile-wrapper-margin);
}
@media screen and (max-width: 575px), (orientation: landscape) {
    .gamesRow {
        justify-content: var(--games-list-games-row-jc);
        align-items: var(--games-list-games-row-ai);
        margin-bottom: 12px;
    }
    .hScrollMobile .gamesRow {
        justify-content: inherit;
        align-items: inherit;
    }
}
@media screen and (orientation: landscape) {
    .gamesRow {
        justify-content: flex-start;
    }
}
/* styles for horizontal scroll on tablet */
@media (max-width: 1200px) {
    .container.hScrollTablet {
    }
}

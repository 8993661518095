.overlay {
    position: fixed;
    top: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    margin: 0;
    z-index: var(--z-index-overlay);
    overflow: hidden;
}

.popupWrapper {
    overflow-y: auto;
    display: flex;
    justify-content: center;
}

.popup {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-family: Arial, sans-serif;
    border-radius: 8px;
    margin-top: 60px;
    margin-bottom: 60px;
    max-width: 460px;
    min-height: auto;
    padding: 20px 0;
    background-color: #f5f5f5;
    z-index: var(--z-index-modal);
}

.fromShopModal {
    min-height: 760px;
    margin-top: 32px;
    margin-bottom: 32px;
    background-color: #f5f5f5;
    border-radius: 16px;
}

.gdprForm {
    min-height: auto;
}

.gdprForm .closeIcon {
    display: none;
}

.popupWrapper .closeIcon {
    position: absolute;
    right: 0;
    top: -36px;
    font-size: 25px;
    color: #fff;
    cursor: pointer;
}

.popupWrapper .closeIcon.eagle {
    right: 10px;
    line-height: 28px;
}

.userAvatar {
    position: absolute;
    height: 80px;
    width: 80px;
    left: 50%;
    transform: translateX(-50%);
    top: -40px;
    border-radius: 50%;
    background-image: url('https://arenacloud.cdn.arkadiumhosted.com/arena51-assets/canary/avatars/Human-Male.png');
    background-size: contain;
}

@media screen and (max-width: 1024px) {
    .popupWrapper .popup {
        border-radius: 8px;
    }
}

.captcha {
    display: none;
}

@media screen and (max-width: 1024px) {
    .popup {
        border-radius: 8px;
        min-height: 300px;
        max-height: none;
    }
}

@media screen and (max-width: 824px) {
    .fromShopModal {
        margin: 0;
        min-height: calc(100vh - var(--top-gap));
        border-radius: 0;
    }
}

@media screen and (max-width: 824px) {
    .fromShopModal {
        margin: 0;
        min-height: calc(100vh - var(--top-gap));
        border-radius: 0;
    }
}

@media (max-width: 768px) {
    .popup {
        max-width: 352px;
        min-height: 601px;
        max-height: none;
    }
}

@media (max-width: 352px) {
    .popup {
        width: calc(100% - 20px);
    }
}

@media (min-height: 720px) {
    .popupWrapper {
        align-items: center;
    }
}

:root {
    --xs-bp: 0;
    --sm-bp: 576px;
    --md-bp: 768px;
    --lg-bp: 1025px;
    --xl-bp: 1200px;
    --xxl-bp: 1370px;

    --xs-max-bp: 575px;
    --sm-max-bp: 767px;
    --md-max-bp: 1024px;
    --lg-max-bp: 1199px;
    --xl-max-bp: 1369px;
    --xxl-max-bp: 1500px;
}
.Container {
    background: #fff;
    box-shadow: 0 0 1px -8px rgba(0, 0, 0, 0.6), 0 2px 4px rgba(0, 0, 0, 0.4);
    height: 100%;
}
.Title {
    font-weight: 600;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ccc;
    margin: 0;
    padding: 0 20px;
    font-size: 16px;
    height: 55px;
    line-height: 54px;
}
.TitleIcon {
    color: #1e36ae;
    margin-right: 10px;
}
.Games {
    padding: 8px 8px 0;
    min-height: 120px;
}
@media (min-width: 1025px) {
    .Games a {
        height: 140px;
    }
}
@media (max-width: 767px) {
    .Container {
        height: auto;
        margin-bottom: 15px;
    }

    .Games a {
        margin-bottom: 1em;
    }
}

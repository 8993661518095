:root {
    --xs-bp: 0;
    --sm-bp: 576px;
    --md-bp: 768px;
    --lg-bp: 1025px;
    --xl-bp: 1200px;
    --xxl-bp: 1370px;

    --xs-max-bp: 575px;
    --sm-max-bp: 767px;
    --md-max-bp: 1024px;
    --lg-max-bp: 1199px;
    --xl-max-bp: 1369px;
    --xxl-max-bp: 1500px;
}

.container {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    width: 100%;
    z-index: var(--z-index-ads);
    isolation: isolate;
}

.container.fullScreen {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: var(--z-index-ads);
    background: #ebebeb;
}

.message {
    display: block;
    margin: 1em 0;
    font-size: 20px;
    font-weight: 600;
    color: #3b4148;
    text-align: center;
}

.player {
    display: block;
    flex: 1 0 auto;
    height: calc(100% - 70px); /* 1.5em line-height, 1em+1em v-margins, 20px font-size */
}

.player.fullScreen {
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    height: 100%;
    z-index: var(--z-index-ads);
}

@media screen and (orientation: portrait) {

.player.fullScreen {
        display: flex;
        max-width: 100%;
        overflow: hidden
}
    }

@keyframes rotator {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(270deg);
    }
}

@keyframes colors {
    0% {
        stroke: #08b238;
    }
    25% {
        stroke: #9aefb0;
    }
    50% {
        stroke: #08b238;
    }
    75% {
        stroke: #9aefb0;
    }
    100% {
        stroke: #08b238;
    }
}

@keyframes dash {
    0% {
        stroke-dashoffset: 187px;
    }
    50% {
        stroke-dashoffset: 46.75px;
        transform: rotate(135deg);
    }
    100% {
        stroke-dashoffset: 187px;
        transform: rotate(450deg);
    }
}

:global(.spinnerPreroll) {
    width: 30px;
    height: 30px;
    animation: rotator 1.4s linear infinite;
    will-change: transform, animation;
}

:global(.pathPreroll) {
    stroke-width: 6px;
    stroke-linecap: round;
    stroke-dasharray: 187;
    stroke-dashoffset: 0;
    transform-origin: center;
    will-change: transform, animation;
    animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
}

/* ClickToAction Screen */

/* Base */

:global #ark_pre-roll {
    display: flex;
    align-items: center;
    height: 100% !important;
    top: 0;
}

:global #ark_pre-roll #ark-video-ratio-keeper {
        width: 100vw;
    }

:global #ark_pre-roll .ctaContainer {
        width: 100%;
        padding: 2em;
        display: flex;
        align-content: flex-start;
        background: none;
        font-family: 'Nunito Sans', sans-serif;
    }

:global #ark_pre-roll .ctaColLeft {
        min-width: 66%;
        max-width: 67%;
        height: 100%;
        aspect-ratio: 1 / 1;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }

:global #ark_pre-roll .ctaColRight {
        flex-grow: 1;
        max-width: 33%;
        padding-left: 2em;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

:global #ark_pre-roll .ctaMessageBox {
        z-index: 10;
        width: 200px;
        text-align: left;
        text-transform: lowercase;
        font-size: 20px;
        color: #000000;
        line-height: 1.6em;
        margin: 0;
    }

:global #ark_pre-roll .ctaMessageBox::first-letter {
        text-transform: uppercase;
    }

:global #ark_pre-roll .ctaButton {
        background-color: var(--play-btn-bg-color);
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 190px;
        height: 40px;
        border-radius: 2px;
        margin: 1.5em 0;
        border-radius: 4px;
        text-transform: capitalize;
        transition: none;
        color: var(--play-btn-text-color);
    }

:global #ark_pre-roll .ctaButton.skipAdButton {
        padding: 16px 24px;
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        background-color: transparent;
        border: 2px solid var(--play-btn-bg-color);
        color: var(--skip-btn-text-color);
        justify-content: space-between;
    }

:global #ark_pre-roll .ctaButton.skipAdButton.loading {
        justify-content: center;
    }

:global #ark_pre-roll :global(.prerollPrice.loading) {
        display: none;
    }

:global #ark_pre-roll :global(.prerollPriceLoader) {
        display: none;
    }

:global #ark_pre-roll :global(.prerollPriceLoader.loading) {
        display: block;
    }

:global #ark_pre-roll .ctaButtonArrow {
        display: none;
    }

:global #ark_pre-roll .ctaButtonText {
        font-size: 24px;
        font-weight: 600;
        letter-spacing: 0.5px;
    }

/*  */

@media (max-width: 767px) {

:global #ark_pre-roll {

        /* FOR SEO OPTIMISED DESKTOP-LIKE VIEW ON MOBILE WITH FIXED BUTTON */
}
        :global #ark_pre-roll .ctaContainer {
            padding: 0;
            flex-flow: column nowrap;
            justify-content: center;
        }

        :global #ark_pre-roll .ctaColLeft {
            background-position: center;
            max-height: 100%;
            width: 100%;
            background-size: contain !important;
        }

        :global #ark_pre-roll .ctaColRight {
            width: 100%;
            max-width: 100%;
            padding: 0;
            align-items: center;
        }

        :global #ark_pre-roll .ctaMessageBox {
            line-height: 1.4em;
            text-align: center;
            width: 100%;
            max-width: 100%;
            margin-top: 1rem;
        }
        :global #ark_pre-roll .ctaButton {
            background-color: #f6f7f8;
            position: fixed;
            left: 0;
            z-index: 103;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0;
        }

        :global #ark_pre-roll .ctaButtonText,
        :global #ark_pre-roll .ctaButtonArrow {
            width: 190px;
            text-align: center;
            position: relative;
            top: 3px;
            z-index: 105;
        }

        :global #ark_pre-roll .ctaButtonText {
            top: 0;
        }

        :global #ark_pre-roll .ctaButton.gems {
            width: calc(50% + 1px);
            padding: 24px 8px;
            border-radius: 0;
        }

        :global #ark_pre-roll .ctaButton.skipAdButton {
            background-color: #f6f7f8;
            border-radius: 0;
            justify-content: center;
            right: 0;
            left: auto;
            font-size: 16px;
            border: 0;
            border-radius: 0;
        }

        :global #ark_pre-roll .ctaButton.skipAdButton span,
        :global #ark_pre-roll .ctaButton.skipAdButton :global(.prerollPrice) {
            z-index: 105;
        }

        :global #ark_pre-roll .ctaButton.skipAdButton :global(.prerollPrice) {
            margin-left: 12px;
        }

        :global #ark_pre-roll .ctaButton:before {
            content: '';
            display: block;
            background-color: var(--play-btn-bg-color);
            z-index: 104;
            width: 190px;
            height: 48px;
            border-radius: 3px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }

        :global #ark_pre-roll .ctaButton.gems:before {
            width: min(90%, 190px);
        }

        :global #ark_pre-roll .ctaButton.skipAdButton:before {
            background-color: transparent;
            border: 2px solid var(--play-btn-bg-color);
            border-radius: 4px;
        }

        :global #ark_pre-roll .ctaButton.absolute {
            position: absolute;
        }

        :global #ark_pre-roll .ctaMessageBox.iOS {
            margin-top: 0;
            padding-bottom: 60px;
            font-size: 15px;
        }

    }

@media (min-width: 768px) and (max-width: 1199px) {
        :global #ark_pre-roll .ctaContainer {
            padding: 0;
            flex-flow: column nowrap;
            justify-content: center;
        }

        :global #ark_pre-roll .ctaColLeft {
            background-position: center;
            max-height: 100%;
            width: 100%;
            background-size: contain !important;
        }

        :global #ark_pre-roll .ctaColRight {
            width: 100%;
            max-width: 100%;
            padding: 0;
            align-items: center;
        }

        :global #ark_pre-roll .ctaMessageBox {
            line-height: 1.4em;
            text-align: center;
            /*width: 336px;*/
            width: 100%;
            max-width: 100%;
            margin-top: 1rem;
        }

        :global #ark_pre-roll .ctaButton {
            margin: 1rem 0 0.5rem;
        }
    }

/* PC Responsive */

.player:not(.fullScreen) :global #ark_pre-roll {
        height: auto;
        min-height: 100%;
        top: 0;
    }

.player:not(.fullScreen) :global #ark_pre-roll #ark-video-ratio-keeper {
            width: auto;
        }

@media (max-width: 1199px) {
            .player:not(.fullScreen) :global #ark_pre-roll .ctaContainer {
                padding: 1rem;
            }
        }

@media (min-width: 1025px) and (max-width: 1199px) {
            .player:not(.fullScreen) :global #ark_pre-roll .ctaColLeft {
                max-width: 270px;
                background-position: center;
            }
        }

@media (max-width: 767px) {
            .player:not(.fullScreen) :global #ark_pre-roll .ctaColLeft {
                max-width: 260px;
            }
        }

/* Mobile + Responsive*/

.player.fullScreen :global #ark_pre-roll .ctaContainer {
            padding: 0;
            flex-flow: column nowrap;
            justify-content: center;
        }

.player.fullScreen :global #ark_pre-roll .ctaColLeft {
            background-position: center;
            max-height: 336px;
            width: 100%;
        }

@media (orientation: landscape) {
            .player.fullScreen :global #ark_pre-roll .ctaColLeft {
                margin-top: 0.5rem;
            }
        }

@media (max-width: 767px) {
            .player.fullScreen :global #ark_pre-roll .ctaColLeft {
                max-height: 184px;
                width: 100%;
            }
        }

.player.fullScreen :global #ark_pre-roll .ctaColRight {
            width: 100%;
            max-width: 100%;
            padding: 0;
            align-items: center;
        }

.player.fullScreen :global #ark_pre-roll .ctaMessageBox {
            line-height: 1.4em;
            text-align: center;
            width: 336px;
            max-width: 100%;
            margin-top: 1rem;
        }

.player.fullScreen :global #ark_pre-roll .ctaButton {
            margin: 1rem 0 0.5rem;
        }

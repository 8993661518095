:root {
    --ark-small-mobile-figma-below-599: 599px;
    --ark-small-mobile-figma-600: 600px;
    --ark-medium-mobile-figma-below-719: 719px;
    --ark-small-desktop-figma-below-1023: 1023px;
    --ark-small-desktop-figma-1024: 1024px;
    --ark-medium-desktop-figma-below-1359: 1359px;
    --ark-medium-desktop-figma-1360: 1360px;
    --ark-large-desktop-figma-1440: 1440px;
}

.tabItem {
    padding: var(--ark-spacing-l) 0 var(--ark-spacing-s) 0;
    font-size: var(--ark-24-font-size);
    line-height: var(--ark-32-line-height);
    position: relative;
    color: var(--ark-primary-black-figma);
    border: 0;
    background: transparent;
    line-height: normal;
    cursor: pointer;
    display: inline-block;
    flex: 1;
}

.tabItem:first-child {
    border-radius: 10px 0 0 0;
}

.tabItem:last-child {
    border-radius: 0 10px 0 0;
}

.tabItem.active {
    color: var(--ark-main-blue-figma);
}

.tabItem.active,
.tabItem:hover {
    font-weight: var(--ark-font-weight-bold);
    background: var(--ark-hover-blue-transparent-figma);
}

.tabItem.active::before {
    transform: scale(1);
}

.tabItem::before {
    content: ' ';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--ark-main-blue-figma);
    height: 0.375rem;
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
    transform-origin: center;
    transform: scale(0);
    transition: transform 0.332s cubic-bezier(0.37, 0, 0.63, 1);
}

/*****Media *******/

@media (max-width: 1023px) {
    .tabItem {
        font-size: var(--ark-14-font-size);
    }
    .tabItem svg {
        width: 80px;
        height: 20px;
    }
}

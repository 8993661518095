.popupSigninFieldDesc {
    display: flex;
    color: #545454;
    font-size: 11px;
    justify-content: flex-end;
    padding: 6px 2px;
    line-height: initial;
}

.popupCheckboxRow {
    margin: 10px 0;
}

.popupCheckboxRow.eagle {
    margin: 10px 0 22px 0;
}

.popupCheckboxRow.eagle div:last-of-type label {
    margin-bottom: 0;
}

.show {
    display: block;
}

.hide {
    display: none;
}
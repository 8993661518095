.wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 14px 16px 12px;
    margin-bottom: 20px;
    border-radius: 8px;
    background-color: var(--new-header-color-blue-secondary);
}

.wrapper article {
    width: 100%;
}

.descriptionTitle {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    color: var(--inverted-color);
    margin-bottom: 16px;
}

.descriptionText {
    font-size: 18px; /* 14 for mobile */
    line-height: 25px; /*20 for mobile */
    color: var(--new-white-color);
}

.gemsImage {
    height: 79px;
    margin-right: 16px;
}

.arrowIcon {
    transition: transform 0.2s ease;
    margin-left: auto;
}

.opened {
    transform: rotate(180deg);
}

@media screen and (max-width: 1024px) {
    .descriptionText {
        font-size: 16px;
        line-height: 22px;
    }
}

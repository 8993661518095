:root {
    --ark-small-mobile-figma-below-599: 599px;
    --ark-small-mobile-figma-600: 600px;
    --ark-medium-mobile-figma-below-719: 719px;
    --ark-small-desktop-figma-below-1023: 1023px;
    --ark-small-desktop-figma-1024: 1024px;
    --ark-medium-desktop-figma-below-1359: 1359px;
    --ark-medium-desktop-figma-1360: 1360px;
    --ark-large-desktop-figma-1440: 1440px;
}

.heading {
    font-size: var(--ark-30-font-size);
    color: var(--ark-primary-black-figma);
    line-height: 32px;
}

.link {
    color: var(--ark-main-blue-figma);
    font-size: var(--ark-18-font-size);
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    font-weight: bold;
    cursor: pointer;
}

.paymentHeading {
    color: var(--ark-primary-black-figma);
    font-size: var(--ark-24-font-size);
    font-weight: 700;
    padding-bottom: 1rem;
    width: 100%;
}

.paymentTextStyle {
    color: var(--ark-primary-black-figma);
    font-size: var(--ark-18-font-size);
}

.infoBlock {
    margin-top: 1rem;
    background-color: rgba(246, 246, 246, 1);
    padding: 36px 0 36px 36px;
    display: flex;
    height: 168px;
    flex-wrap: wrap;
}

/*****Media *******/

@media (max-width: 1023px) {
    .heading {
        font-size: var(--ark-20-font-size);
        line-height: 28px;
    }

    .link {
        font-size: var(--ark-16-font-size);
    }

    .infoBlock {
        padding: 16px;
        height: 154px;
        flex-direction: column;
        justify-content: center;
    }

    .paymentHeading {
        font-size: var(--ark-20-font-size);
        padding-bottom: 8px;
    }
}

@media (max-width: 719px) {
    .infoBlock {
        height: 180px;
    }
}

@media (max-width: 599px) {
    .infoBlock {
        height: 120px;
        justify-content: start;
    }

    .link {
        font-size: var(--ark-18-font-size);
    }
}

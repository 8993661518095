.Container {
    display: flex;
    align-items: center;
}

.isVertical.Container {
    flex-wrap: wrap;
    justify-content: center;
}

/*.Btn {
    margin-right: 20px;
}*/

.Btn:focus, .Btn:hover {
    outline: none;
}

.isVertical .Btn {
    margin-bottom: 15px;
    margin-right: 0;
}

.isHorizontal .Btn {
    display: flex;
    align-items: center;
    margin-right: 20px;
    width: 150px;
    text-align: left;
}

.BtnImg {
    display: inline-block;
    width: 48px;
    height: 48px;
    transition: all .2s;
    position: relative;
}

.BtnImg:after {
    content: '';
    display: none;
    width: 44px;
    height: 44px;
    border-radius: 5px;
    background: #005aff;
    top: 0;
    left: 0;
    position: absolute;
    z-index: -1;
}

.animationPulse .BtnImg:after {
    display: block;
}

.animationPulse .BtnImg:after {
    animation: pulse 1s;
    animation-iteration-count: 3;
}

.animationPulse .BookmarkBtn:after {
    animation: pulse 1s;
    animation-iteration-count: 3;
    animation-delay: 3s;
}

.isHorizontal .BtnImg {
    flex-shrink: 0;
}

.Btn:hover .BtnImg path:first-child {
    fill: var(--svg-icon-bg-color-hover);
}

.BtnImg svg {
    width: 100%;
    height: 100%;
}

.BtnText {
    display: block;
    font-size: 16px;
    line-height: 1.2;
}

.isVertical .BtnText {
    margin-top: 5px;
}

.isHorizontal .BtnText {
    margin-left: 10px;
}

.animationClick {
    animation: click .4s;
    animation-iteration-count: 1;
    transform-style: preserve-3d;
}

@keyframes pulse {
    100% {
        transform: scale(2);
        background: rgba(0, 190, 7, 0);
    }
}

@keyframes click {
    0% {
        transform: scale(1);
    }
    75% {
        transform: scale(.83);
    }
    100% {
        transform: scale(1);
    }
}

:root {
    --row-gap: 24px;
}

.shopModalWrapper {
    margin-top: 80px;
    height: 80vh;
    max-height: 1000px;
}

.shopModal {
    background-color: var(--new-header-color-blue);
}

.shopModalInnerWrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    z-index: var(--z-index-modal);
}

.shopListWrapper {
    overflow-x: hidden;
}

.contentWrapper {
    padding: 24px 24px 8px 24px;
}

.contentWrapper.forLoader {
    margin: auto 0;
}

.shopList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    grid-row-gap: 24px;
    row-gap: 24px;
    grid-row-gap: var(--row-gap);
    row-gap: var(--row-gap);
}

@media screen and (max-width: 1024px) {
    .shopModalWrapper {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .shopModalWrapper {
        margin-top: 40px;
        height: 85vh;
        max-height: 1000px;
    }
}

@media screen and (max-width: 475px) {
    :root {
        --row-gap: 16px;
    }
    .shopListWrapper {
        max-height: calc(var(--shop-item-height)*3 + 48px);
        max-height: calc(var(--shop-item-height)*3 + var(--row-gap)*2);
    }
    /* .shopList {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
    } */
}

:root {
    --ark-small-mobile-figma-below-599: 599px;
    --ark-small-mobile-figma-600: 600px;
    --ark-medium-mobile-figma-below-719: 719px;
    --ark-small-desktop-figma-below-1023: 1023px;
    --ark-small-desktop-figma-1024: 1024px;
    --ark-medium-desktop-figma-below-1359: 1359px;
    --ark-medium-desktop-figma-1360: 1360px;
    --ark-large-desktop-figma-1440: 1440px;
}

.container {
    border: none;
    text-decoration: none;
    display: flex;
    height: 48px;
    align-items: center;
    justify-content: center;
    background-color: var(--ark-main-green-figma);
    border-radius: 24px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    color: var(--ark-primary-white-figma);
    font-size: var(--ark-20-font-size);
    font-weight: 700;
}

.container:hover {
    background-color: var(--ark-hover-dark-green-figma);
}

.container:disabled {
    color: var(--ark-primary-white-figma);
    background-color: var(--ark-primary-asbestos-figma);
}

.container.pseudoDisabled {
    color: var(--ark-primary-white-figma);
    background-color: var(--ark-primary-asbestos-figma);
}

.noPadding {
    padding: 0;
}

.fullWidth {
    width: 100%;
}

.withArrow {
    justify-content: left;
    padding-left: 20px;
}

/****Outlined Btn*********/

.container.outlined {
    background-color: transparent;
    border: 2px solid var(--ark-main-blue-figma);
    color: var(--ark-main-blue-figma);
}

.container.outlined:hover {
    background-color: var(--ark-primary-white-figma);
    border-color: transparent;
}

.container.outlined.white {
    border-color: var(--ark-primary-white-figma);
    color: var(--ark-primary-white-figma);
}

.container.outlined.white:hover {
    color: var(--ark-primary-black-figma);
}

.container.outlined.black {
    background-color: var(--ark-primary-black-figma);
    border-color: var(--ark-primary-black-figma);
    color: var(--ark-primary-white-figma);
}

.container.outlined.black:hover {
    background-color: var(--ark-primary-white-figma);
    color: var(--ark-primary-black-figma);
}

/*****Secondary Style********/

.container.secondaryStyle {
    background-color: var(--ark-main-blue-figma);
}

.container.secondaryStyle:hover {
    background-color: var(--ark-hover-dark-blue-figma);
}

.container.secondaryStyle:disabled {
    background-color: var(--ark-primary-asbestos-figma);
}

.container.secondaryStyle.pseudoDisabled {
    background-color: var(--ark-primary-asbestos-figma);
}

.isLink {
    display: inline-block;
    background-color: transparent;
    border: none;
    box-shadow: none;
    cursor: pointer;
    padding: 0;
    color: var(--ark-link-color);
    font-size: var(--ark-18-font-size);
    line-height: var(--ark-24-line-height);
    font-weight: var(--ark-font-weight-regular);
}

.isLink:hover {
    color: var(--ark-link-hover-color);
    background-color: transparent;
}

.btnLoader,
.btnLoader svg {
    width: 2.125rem;
    height: 2.125rem;
}

@media (max-width: 599px) {
    .container {
        min-width: var(--ark-minSize-for-interactive-el);
        min-height: var(--ark-minSize-for-interactive-el);
    }
    .container.linkOnMobile {
        border: none;
        text-decoration: underline;
        font-size: var(--ark-16-font-size);
        padding: 0;
    }
}

:root {
    --xs-bp: 0;
    --sm-bp: 576px;
    --md-bp: 768px;
    --lg-bp: 1025px;
    --xl-bp: 1200px;
    --xxl-bp: 1370px;

    --xs-max-bp: 575px;
    --sm-max-bp: 767px;
    --md-max-bp: 1024px;
    --lg-max-bp: 1199px;
    --xl-max-bp: 1369px;
    --xxl-max-bp: 1500px;
}
.container {
    width: 100%;
    padding: var(--game-end-container-padding);
    background: var(--game-end-container-bg);
    background-repeat: no-repeat;
    background-color: var(--game-end-container-bg-color);
    min-height: inherit; /* inheriting min-height from a parent component */
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: space-around;
}
.title.__override {
    font-size: 28px;
    color: #fff;
    text-align: center;
    margin-bottom: 20px;
    font-weight: 700;
}
.row {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    align-items: center;
}
.row:last-of-type {
    margin-bottom: 0;
}
.rowXp {
    min-height: 259px;
}
.rowButtons {
    flex-direction: column;
    grid-gap: 1em;
    gap: 1em;
}
.playAgainIcon {
    margin-right: 5px;
}
@media (max-width: 767px) {
    .title.__override {
        font-size: 20px;
        margin-top: 24px;
    }

    .rowXp {
        min-height: 230px;
    }
}
@media (max-width: 575px) {
    .title.__override {
        font-size: 15px;
        margin-top: 3px;
        margin-bottom: 3px;
    }
    .row {
        margin-bottom: 10px;
    }
}

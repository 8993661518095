.adWidgetContainer {
    max-width: 100%;
    height: auto;
    background-color: var(--inverted-color);
    box-shadow: 1px 1px 4px 1px #ccc;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 16px;
    margin-top: 10px;
  }

  .headerContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .redButton {
    border-radius: 5px 0 0 0;
    width: 50%;
    padding: 8px;
    height: 100%;
    background-color: var(--hsn-red-color);
    color: var(--inverted-color);
    text-align: center;
    cursor: pointer;
  }

  .blueButton {
    border-radius: 0 5px 0 0;
    width: 50%;
    padding: 8px;
    height: 100%;
    background-color: var(--hsn-blue-color);
    color: var(--inverted-color);
    text-align: center;
    cursor: pointer;
  }

  .activeButton {
    position: relative;
  }

  .activeButton::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid;
  }

  .redButton.activeButton::after {
    border-top-color: var(--hsn-red-color);
  }

  .blueButton.activeButton::after {
    border-top-color: var(--hsn-blue-color);
  }

  .productContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .productImage {
    width: 200px;
    height: 200px;
    background-color: #ccc;
  }

  .shopLink {
    margin-top: 16px;
    text-align: center;
    max-width: 75% !important;
  }

  .footerContainer {
    margin-top: auto;
    align-items: center;
  }

  .blueFooterButton {
    padding: 8px;
    background-color: var(--hsn-blue-color);
    color: var(--inverted-color);
    text-align: center;
    cursor: pointer;
  }

.resetPasswordContentWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}

.resetPasswordButton {
    margin-top: 40px;
}

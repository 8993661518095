:root {
    --xs-bp: 0;
    --sm-bp: 576px;
    --md-bp: 768px;
    --lg-bp: 1025px;
    --xl-bp: 1200px;
    --xxl-bp: 1370px;

    --xs-max-bp: 575px;
    --sm-max-bp: 767px;
    --md-max-bp: 1024px;
    --lg-max-bp: 1199px;
    --xl-max-bp: 1369px;
    --xxl-max-bp: 1500px;
}

:root {
    --announce-banner-height: 64px;
}

.wrapper {
    padding-top: 1em;
    padding-bottom: 1em;
    margin-top: -1em;
    position: relative;
    z-index: 100;
}

.wrapper.isGamePage {
    padding: 0;
    margin: 0;
    width: 100%;
}

.wrapper.isMobileOnGame,
.wrapper.isTabletOnGame {
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    z-index: 1000;
}

.wrapper.isMobileOnGame {
    top: 50px;
}

.wrapper.isTabletOnGame {
    top: 90px;
}

.messageBox {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0.7em 1em;
    min-height: 73px;
    border-radius: 2px;
    background-color: #495ec5;
    z-index: var(--z-index-banner);
}

.messageBox.isMobileGame {
    border-radius: 0;
}

.infoIcon {
    color: rgba(255, 255, 255, 0.6);
    margin-right: 1em;
}

.text {
    flex: 1;
    font-size: 18px;
    line-height: 1.2;
    letter-spacing: normal;
    color: #ffffff;
}

@media (max-width: 767px) {
    .text {
        font-size: 16px;
        line-height: 1.2;
    }
}

.closeButton {
    color: #ffffff;
    margin-left: 1em;
    align-self: flex-start;
}

.RedesignWrapper {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    --new-color: #f9f9f9;
}

.RedesignWrapper .wrapper,
.RedesignWrapper .wrapper > div,
.RedesignWrapper .wrapper .messageBox {
    display: block;
    position: static;
    width: 100%;
    min-height: 64px;
    min-height: var(--announce-banner-height);
    padding: 0;
    margin: 0;
}

.RedesignWrapper .wrapper .messageBox {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    background-color: var(--new-header-color-blue-primary);
    padding: 20px 0;
}

.RedesignWrapper .wrapper .messageBox .infoIcon,
.RedesignWrapper .wrapper .messageBox .closeButton svg {
    fill: var(--new-color);
    --inner-size: 20px;
    --target-size: 24px;
    --new-size-ratio: 1.2;
    --new-size-offset: -2px;
    width: var(--target-size);
    height: var(--target-size);
    min-width: var(--target-size);
    min-height: var(--target-size);
    transform: scale(var(--new-size-ratio)) translate(var(--new-size-offset), var(--new-size-offset));
}

.RedesignWrapper .wrapper .messageBox .infoIcon {
    margin-left: 29px;
    margin-right: 25px;
}

.RedesignWrapper .wrapper .messageBox .text {
    color: var(--new-color);
    font-family: 'Nunito Sans';
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
}

.RedesignWrapper .wrapper .messageBox .closeButton {
    margin: 0 33px;
    align-self: center;
}

.BannerUrl {
    color: #ffb03b !important;
}

:root {
    --xs-bp: 0;
    --sm-bp: 576px;
    --md-bp: 768px;
    --lg-bp: 1025px;
    --xl-bp: 1200px;
    --xxl-bp: 1370px;

    --xs-max-bp: 575px;
    --sm-max-bp: 767px;
    --md-max-bp: 1024px;
    --lg-max-bp: 1199px;
    --xl-max-bp: 1369px;
    --xxl-max-bp: 1500px;
}
.sidebar {
    position: relative;
}
@media (max-width: 767px) {
    .sidebar {
        margin-bottom: 1em;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.list {
    /* Force block because of arena-headers mess */
    display: block;
    width: 230px;
    padding: 0;
    list-style: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    overflow: hidden;
    background: #ffffff;
}
@media (max-width: 767px) {
    .list {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
    }
}
.item {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    color: #3c3d41;
    font-size: 14px;
    text-decoration: none;
    background: #fff;
    border-bottom: 1px solid #eeeeee;
    cursor: pointer;
    text-align: left;
    box-sizing: border-box;
    width: 100%;
}
.item:not(:last-child),
.item:not(.isActive) {
    border: none;
}
.item:hover,
.item.isActive {
    background: #0b4db4;
    color: #ffffff;
}
.item.isHidden {
    display: none;
}
.itemIcon,
.itemIcon svg {
    font-weight: bold;
    color: #0b4db4;
}
.item:hover .itemIcon,
.item:hover svg,
.item.isActive .itemIcon,
.item.isActive svg {
    color: #ffffff;
}
.itemText {
    flex: 1;
    margin-left: 10px;
}
.arrowRotated {
    transform: rotateZ(90deg);
}

.selectWrapper {
    position: relative;
}

.itemsWrapper {
    max-height: 260px;
    padding: 0;
    margin: 0;
}

.searchInput {
    height: 48px;
    border: 2px solid var(--ark-primary-black-figma);
    border-radius: var(--ark-border-radius);
    cursor: pointer;
    background: none;
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 12px;
    text-align: left;
    z-index: 1;
    padding-right: 68px; /* done to avoid overlapping the close icon + arrow icon */
}

.selected {
    border-color: var(--ark-primary-green);
}

.selectButton {
    height: 48px;
    border: 2px solid var(--ark-primary-black-figma);
    border-radius: var(--ark-border-radius);
    cursor: pointer;
    background: none;
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 12px;
    text-align: left;
    padding-right: 34px; /* done to avoid overlapping the close icon (34px = width of close icon) */
}

.selectedItem {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 12px;
    padding-right: 34px; /* done to avoid overlapping the close icon (34px = width of close icon) */
}

.selectedItem span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 1;
}

.fullWidth {
    width: 100%;
}

.selectedItem.selected span {
    color: var(--ark-primary-black-figma);
    font-size: var(--ark-18-font-size);
}

.selectArrow {
    position: absolute;
    right: 0.75rem;
    top: calc(50% - 17px);
    color: var(--ark-neutral-black);
    transform: rotate(180deg);
}

.searchInput:hover,
.opened .searchInput {
    border-color: var(--ark-primary-blue-figma);
}

.searchInput:hover .selectArrow {
    color: var(--ark-primary-blue-figma);
}

.closeIcon {
    position: absolute;
    right: 0;
    top: calc(50% - 17px);
    z-index: 3;
    cursor: pointer;
}

.opened .selectArrow {
    color: var(--ark-primary-blue-figma);
    transform: rotate(0);
}

.selectWrapper ul {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    color: var(--ark-primary-black-figma);
    font-size: var(--ark-18-font-size);
    overflow-y: scroll;
    background: #fff;
    position: absolute;
    z-index: 4;
    width: 100%;
}

.selectWrapper ul li:first-child {
    padding-top: 16px;
}

.selectWrapper ul li {
    padding: 8px 0 8px 12px;
    cursor: pointer;
    display: flex;
}

.selectWrapper ul li:hover {
    background: var(--ark-hover-blue-transparent-figma);
}

.countryImg {
    width: 1.75rem;
    height: 1.25rem;
    border-radius: 2px;
    margin-right: 1rem;
    z-index: 1;
}

.searchCloseIcon {
    right: 40px;
    z-index: 2;
}

.noResultsText {
    font-size: var(--ark-14-font-size);
    color: var(--ark-neutral-black);
    font-weight: var(--ark-font-weight-bold);
    padding: 8px 0 8px 12px;
}

.selectWrapper ul li.noResultsText:hover {
    background: inherit;
    cursor: inherit;
}

:root {
    --xs-bp: 0;
    --sm-bp: 576px;
    --md-bp: 768px;
    --lg-bp: 1025px;
    --xl-bp: 1200px;
    --xxl-bp: 1370px;

    --xs-max-bp: 575px;
    --sm-max-bp: 767px;
    --md-max-bp: 1024px;
    --lg-max-bp: 1199px;
    --xl-max-bp: 1369px;
    --xxl-max-bp: 1500px;
}
.Container {
    background: #fff;
    box-shadow: 0 0 1px -8px rgba(0, 0, 0, 0.6), 0 2px 4px rgba(0, 0, 0, 0.4);
    height: 100%;
}
.Title {
    font-weight: 600;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ccc;
    margin: 0;
    padding: 0 20px;
    height: 55px;
    line-height: 54px;
    font-size: 16px;
}
.Body {
    overflow-y: auto;
    max-height: 310px;
    min-height: 120px;
    padding: 0 8px;
}
.AchievementEventRow {
    padding: 15px 0;
}
.IconBlock {
    display: flex;
    align-items: center;
    justify-content: center;
}
.DescText {
    line-height: 1.5;
    margin: 0;
}
.StarIconTitle,
.StarIconContent {
    color: #ffc600;
}
.StarIconTitle {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}
.StarIconContent {
    width: 46px;
    height: 46px;
}
@media (max-width: 1024px) {
    .Body {
        margin-bottom: 0;
        max-height: 228px;
    }
    .AchievementEventRow {
        padding: 7px 0;
    }
}

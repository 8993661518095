.progressBar {
    margin-top: 5px;
    height: 19px;
    border-top: 1px solid #c8c8c8;
    border-bottom: 1px solid #c8c8c8;
    background: #dcdcdc;
    display: flex;
    z-index: 1;
    width: 100%;
}

.progressCurrent {
    width: 0;
    height: 100%;
    background: #fbd500;
    transition: width 1s ease;
}

.progressGained {
    width: 0;
    height: 100%;
    position: relative;
    font-size: 14px;
    line-height: 19px;
    color: #9b4200;
    font-weight: 600;
    background: #f8a400;
    transition: width 1.5s ease;
}

.progressTooltip {
    position: absolute;
    top: -100%;
    right: 0;
    transform: translate(60%, 0);
    padding: 0 6px;
    border-radius: 4px;
    margin-top: -5px;
    background: #0448a2;
    color: #ffc600;
    z-index: 9;
}

.progressTooltip:after {
    top: 100%;
    left: 0;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(0, 56, 131, 0);
    border-top-color: #003883;
    border-width: 5px;
    margin-left: 3px;
}

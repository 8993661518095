:root {
    --xs-bp: 0;
    --sm-bp: 576px;
    --md-bp: 768px;
    --lg-bp: 1025px;
    --xl-bp: 1200px;
    --xxl-bp: 1370px;

    --xs-max-bp: 575px;
    --sm-max-bp: 767px;
    --md-max-bp: 1024px;
    --lg-max-bp: 1199px;
    --xl-max-bp: 1369px;
    --xxl-max-bp: 1500px;
}
.container {
    padding-top: 3em;
    padding-left: var(--global-side-gutters);
    padding-right: var(--global-side-gutters);
}
.content {
    display: flex;
}
.gamesTiles {
    flex: 1;
    width: 75%;
    min-width: 75%;
}
.adSidebar {
    margin-left: 24px;
    width: 25%;
}
.displayAd {
    width: 300px;
}
.title {
    font-size: 32px;
    margin-bottom: 0;
    text-transform: capitalize;
}
.adRowTop {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}
.adRowBottom {
    display: flex;
    justify-content: center;
    margin: 70px 0;
}
.topRow {
    margin-bottom: 29px;
    line-height: 1;
    display: flex;
    align-items: center;
}
@media (max-width: 1024px) {
    .displayAd {
        width: 160px;
    }
}

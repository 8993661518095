:root {
    --xs-bp: 0;
    --sm-bp: 576px;
    --md-bp: 768px;
    --lg-bp: 1025px;
    --xl-bp: 1200px;
    --xxl-bp: 1370px;

    --xs-max-bp: 575px;
    --sm-max-bp: 767px;
    --md-max-bp: 1024px;
    --lg-max-bp: 1199px;
    --xl-max-bp: 1369px;
    --xxl-max-bp: 1500px;
}
.AvatarsList {
    height: 420px;
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    padding: 0 10px;
}
.AvatarsItem {
    list-style: none;
    position: relative;
    cursor: pointer;
}
.AvatarsImg {
    border-radius: 50%;
    width: 82px;
    height: 82px;
    margin: 10px;
}
.AvatarsInfo {
    text-align: center;
    font-size: 11px;
}
.BuyBtn {
    color: #014db2;
    text-decoration: underline;
    font-size: 11px;
}
.CoinsIcon {
    width: 15px;
    height: 15px;
    margin-right: 4px;
}
.AvatarLock {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 10px;
    top: 10px;
    border-radius: 50%;
    background: #f90606;
    color: #fff;
    font-size: 10px;
    white-space: nowrap;
    line-height: 20px;
}
.AvatarsItem:hover .AvatarLock {
    width: auto;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 20px;
    padding: 0 4px;
}
.LockDesc {
    margin: 0;
    display: none;
}
.AvatarsItem:hover .LockDesc {
    display: block;
}
.LockIcon {
    width: 16px;
    height: 17px;
    color: #fff;
}
.AvatarsItem:hover .LockIcon {
    display: none;
}
@media screen and (max-width: 767px) {
    .AvatarsList {
        padding: 0 7px;
    }
}

.registerButton {
    font-size: 13px;
    background: #004eb3;
    border: 0;
    color: #fff;
    height: 26px;
    min-width: 81px;
    border-radius: 13px;
    margin-left: 8px;
}

:root {
    --xs-bp: 0;
    --sm-bp: 576px;
    --md-bp: 768px;
    --lg-bp: 1025px;
    --xl-bp: 1200px;
    --xxl-bp: 1370px;

    --xs-max-bp: 575px;
    --sm-max-bp: 767px;
    --md-max-bp: 1024px;
    --lg-max-bp: 1199px;
    --xl-max-bp: 1369px;
    --xxl-max-bp: 1500px;
}
.container {
    letter-spacing: 0;
    background: #fff;
    overflow: hidden;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.35);
    text-align: center;
    border-radius: var(--game-end-br);
}
.scoresTitle {
    font-size: 1.6em;
    font-weight: 600;
    border-bottom: 2px solid #ebebeb;
    padding: 8px 15px;
    line-height: 1.1;
}
.isFirst {
    border-color: #ffc600;
    background-color: #ffc600;
    color: #004db3;
}
.isSecond {
    border-color: #99a3b2;
    background-color: #99a3b2;
    color: #fff;
}
.isThird {
    border-color: #d17b3c;
    background-color: #d17b3c;
    color: #fff;
}
.scoresValue {
    font-family: var(--score-font);
    font-size: var(--score-value-fs);
    font-weight: var(--score-value-font-weight);
    color: var(--score-value-color);
    display: flex;
    line-height: 1;
    justify-content: center;
    align-items: center;
    height: 2em;
}
.highScores {
    text-transform: uppercase;
    border-top: 2px solid #ebebeb;
    font-size: 1.2em;
    font-weight: 600;
    padding: 8px 15px;
    line-height: 1;
}
.isNew {
    background-color: #ffc600;
    border-top: 2px solid #ffc600;
    color: #004db3;
}
.highScoreValue {
    font-family: var(--score-font);
    font-size: var(--score-value-fs);
    font-weight: var(--score-value-font-weight);
    color: var(--score-value-color);
}
@media (max-width: 575px) {
    .highScoreValue {
        display: block;
    }

    .highScores {
        font-size: 0.7em;
        padding: 3px 15px;
    }

    .scoresValue {
        height: 1.2em;
    }

    .scoresTitle {
        padding: 5px 15px;
    }
}
.trophyIcon {
    margin-right: 10px;
}
.starIcon {
    position: relative;
    top: -3px;
    color: #004db3;
}

.levelUp {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(7, 39, 82, 0.9);
    z-index: 1;
}

.content {
    margin-top: -50px;
    padding: 10px;
    width: 280px;
    border-radius: 5px;
    text-align: center;
    transform: scale(0);
    transition: transform .8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.isAnimationStarted {
    transform: scale(1);
}

.title {
    margin-bottom: 10px;
    line-height: 1;
    margin-top: 0;
    font-size: 40px;
    font-weight: bold;
    text-transform: uppercase;
    color: #fff;
}

.textWrap {
    height: 200px;
    position: relative;
}

.text {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -14px 0 0 -3px;
    font-size: 60px;
    font-weight: bold;
    text-shadow: -2px -2px 0 #fff, 2px -2px 0 #fff, -2px 2px 0 #fff, 2px 2px 0 #fff;
    transform: translate(-50%, -50%);
    color: #004db3;
}

.image {
    max-width: 100%;
}

@media (max-width: 767px) {
    .levelup {
        position: fixed;
        top: 50px;
    }
}

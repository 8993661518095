:root {
    --ark-small-mobile-figma-below-599: 599px;
    --ark-small-mobile-figma-600: 600px;
    --ark-medium-mobile-figma-below-719: 719px;
    --ark-small-desktop-figma-below-1023: 1023px;
    --ark-small-desktop-figma-1024: 1024px;
    --ark-medium-desktop-figma-below-1359: 1359px;
    --ark-medium-desktop-figma-1360: 1360px;
    --ark-large-desktop-figma-1440: 1440px;
}

.wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    position: relative;
}

.step {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.step__circle {
    justify-content: center;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    text-align: center;
    border-radius: 50%;
    font-size: var(--ark-26-font-size);
    border: 2px solid var(--ark-primary-blue-figma);
    color: var(--ark-primary-blue-figma);
    background: #fff;
    position: relative;
}

.step:not(:last-child) .step__circle::after {
    content: '';
    position: absolute;
    top: 1.25rem;
    left: 1rem;
    width: 22vw;
    height: 2px;
    background: var(--ark-primary-blue-figma);
    z-index: -1;
}

.step__label {
    font-size: var(--ark-20-font-size);
    margin-top: 8px;
    color: var(--ark-primary-black-figma);
}

.activeStep .step__circle {
    background: var(--ark-primary-blue-figma);
    color: var(--ark-primary-white-figma);
}

/***** Media *******/

@media (max-width: 1023px) {
    .step:not(:last-child) .step__circle::after {
        width: 18vw;
        top: 1rem;
    }

    .step__circle {
        width: 36px;
        height: 36px;
        font-size: var(--ark-20-font-size);
    }

    .step__label {
        font-size: var(--ark-14-font-size);
        margin-top: 4px;
    }
}

@media (max-width: 719px) {
    .wrapper {
        width: 90%;
    }
}

@media (max-width: 599px) {
    .step:not(:last-child) .step__circle::after {
        width: 36vw;
    }

    .step__label {
        margin-top: 8px;
    }
}

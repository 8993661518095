.videoAdWidget {
    position: relative;
    padding: 5px;
    background-color: var(--hsn-dark-gray-color);
    border-radius: 4px;
  }
  
  .videoContainer {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
  }
  
  .videoElement {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .grayBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    background-color: var(--hsn-dark-gray-color);
    z-index: 1;
  }
  
  .muteBtn {
    padding: 8px;
    color: var(--inverted-color);
    cursor: pointer;
    z-index: 1;
  }
  
  .shopNowBtn {
    padding: 8px;
    color: var(--inverted-color);
    cursor: pointer;
    z-index: 1;
  }
  
  .shopNowLink {
    display: flex;
    align-items: center;
    color: var(--inverted-color);
    cursor: pointer;
    z-index: 1;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
  }
  
  .shopNowArrow {
    rotate: 90deg;
  }
  
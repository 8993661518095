:root {
    --xs-bp: 0;
    --sm-bp: 576px;
    --md-bp: 768px;
    --lg-bp: 1025px;
    --xl-bp: 1200px;
    --xxl-bp: 1370px;

    --xs-max-bp: 575px;
    --sm-max-bp: 767px;
    --md-max-bp: 1024px;
    --lg-max-bp: 1199px;
    --xl-max-bp: 1369px;
    --xxl-max-bp: 1500px;
}
.footerInner {
    /* IE, Edge fix*/
    flex-shrink: 0;
    padding: 2.5rem 0;
    background-color: #0a1874;
    color: white;
    font-size: 20px;
    font-weight: 600;
    z-index: var(--z-index-footer);
}
.footer_seo-text.__override {
    font-family: 'Nunito Sans', sans-serif;
    width: 100%;
    text-align: center;
    font-style: oblique;
    font-size: 18px;
    margin: 1em 0 0 0;
}
/*Stands instead of bootstrap-container styles, fixes layout on tablets*/
.customContainer {
    width: 100%;
    padding-right: 8px;
    padding-left: 8px;
    margin-right: auto;
    margin-left: auto;
}
.labelRow {
}
@media (min-width: 576px) {
    .customContainer {
        max-width: 540px;
    }
}
@media (min-width: 768px) {
    .customContainer {
        max-width: none;
    }
    .labelRow {
        text-align: center;
    }
}
@media (min-width: 1025px) {
    .customContainer {
        max-width: 960px;
    }
    .labelRow {
        text-align: center;
    }
}
@media (min-width: 1200px) {
    .customContainer {
        max-width: 1164px;
    }
    .labelRow {
        text-align: center;
    }
}
@media (min-width: 1370px) {
    .customContainer {
        max-width: 1328px;
    }
    .labelRow {
        text-align: center;
    }
}
.logoLink {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 276px;
    height: 67px;
    border-radius: 2px;
    margin: 0 auto;
}
.logoLink:hover,
.logoLink:focus {
}
@media (max-width: 767px) {
    .logo {
        width: 100%;
        height: 80px;
    }

    .logoLink {
        width: 100%;
    }
}
.footerInner .itemColumn {
    min-height: 2.3em;
    padding-left: var(--global-side-gutters);
}
.footerInner .itemColumn button {
    text-align: left;
    white-space: nowrap;
}
@media (max-width: 1025px) {
    .footerInner .itemColumn button {
        white-space: normal;
    }
}
@media (max-width: 767px) {
    .itemColumn {
        width: 100%;
        display: block;
        line-height: 2.5em;
        text-align: left;
    }

    .itemColumn:last-child {
        margin-bottom: 1em;
    }

    .itemColumn:hover,
    .itemColumn:focus {
        background-color: rgba(255, 255, 255, 0.16);
    }
}
a.link,
a.link:link,
a.link:visited {
    color: white;
    white-space: nowrap;
    text-decoration: unset;
}
a.link:hover,
a.link:focus {
    color: white;
    text-decoration: underline;
}
:global(#root #ot-sdk-btn.ot-sdk-show-settings) {
    border: none;
    font-size: 20px;
    padding: 0;
    font-weight: 600;
    color: #fff;
    text-align: left;
}
:global(#root #ot-sdk-btn.ot-sdk-show-settings:hover) {
    background: none;
    text-decoration: underline;
}
